import React, { PureComponent } from 'react';
import { Query } from '@apollo/client/react/components';
import { GET_CURRENT_USER } from 'src/queries/users';
import Content from './Content';

class Statistics extends PureComponent {
  isContentLoaded = false;

  render() {
    return (
      <Query query={GET_CURRENT_USER} variables={{ withTeacherStatistics: true }}>
        {({ loading, error, data }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const user = data.me;

          return <Content user={user} />;
        }}
      </Query>
    );
  }
}

export default Statistics;
