import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box, Text } from 'rebass';
import styled from 'styled-components';

import { svgStyle } from 'src/styles/helpers';
import { Container, Content } from 'src/components/utilities/Containers';
import { Grid, Col } from 'src/components/utilities/Grids';
import CollectionList from 'src/components/blocks/CollectionList';
import FolderList from 'src/components/blocks/FolderList';
import Pagination from 'src/components/blocks/Pagination';
import Select from 'src/components/elements/Select';
import Tabs from 'src/components/blocks/Tabs';
import { css } from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import Title from '../../components/typography/Title';
import RecommandationsSvg from 'assets/svg/recommandations.svg';

const RecommandationsIcon = styled(RecommandationsSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

const tabStyle = css`
  flex: 1;
  @media (min-width: ${breakpoints.md}) {
    min-width: 22.4rem;
  }
`;

class OfficiaLResourcesContent extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleOrder: PropTypes.func,
    onCollectionsPageChange: PropTypes.func,
    onFoldersPageChange: PropTypes.func,
    onSubjectChange: PropTypes.func,
    onDegreeChange: PropTypes.func,
    collections: PropTypes.object,
    degrees: PropTypes.array,
    folders: PropTypes.object,
    isLoading: PropTypes.bool,
    searchTerm: PropTypes.string,
    subjects: PropTypes.array,
    orderBy: PropTypes.string,
    collectionsPage: PropTypes.number,
    foldersPage: PropTypes.number,
    filterBy: PropTypes.object,
    tab: PropTypes.number.isRequired,
    onTabChange: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      handleOrder,
      onCollectionsPageChange,
      onFoldersPageChange,
      onSubjectChange,
      onDegreeChange,
      collections,
      degrees,
      folders,
      isLoading,
      searchTerm,
      subjects,
      orderBy,
      collectionsPage,
      foldersPage,
      filterBy,
      tab,
      onTabChange,
    } = this.props;

    return (
      <Container css="padding-bottom: 3rem;">
        <Content mt={3} css="display: flex; flex-direction: column;">
          <Grid
            justifyContent="space-end"
            css={`
              @media (max-width: ${breakpoints.smPlus}) {
                order: 2;
              }
            `}
          >
            <Col justifyContent="flex-end">
              <Grid justifyContent="flex-end">
                <Col flex={['100%', '50%', 'auto']} pb={[2, 2, 3]}>
                  <Box width={1 / 1}>
                    <Text as="label" id="sort" pb=".4rem" color="selectLabel" fontSize="1.2rem" css="display: block;">
                      {t('sortBy')}
                    </Text>
                    <Select
                      name="sort"
                      onChange={handleOrder}
                      value={orderBy}
                      options={[
                        { id: 'newest', name: t('filters.newest') },
                        { id: 'oldest', name: t('filters.oldest') },
                        { id: 'alphabetical', name: t('filters.alphabetical') },
                        {
                          id: 'reverseAlphabetical',
                          name: t('filters.reverseAlphabetical'),
                        },
                      ]}
                      css="width: 100%;"
                    />
                  </Box>
                </Col>
                <Col flex={['100%', '50%', 'auto']} pb={[2, 2, 3]}>
                  <Box width={1 / 1}>
                    <Text
                      as="label"
                      id="subject"
                      pb=".4rem"
                      color="selectLabel"
                      fontSize="1.2rem"
                      css="display: block;"
                    >
                      {t('schoolSubject')}
                    </Text>
                    <Select
                      name="subject"
                      onChange={onSubjectChange}
                      defaultValue={t('allSchoolSubjects')}
                      options={subjects}
                      value={filterBy.subject}
                      css="width: 100%;"
                    />
                  </Box>
                </Col>
                <Col flex={['100%', '100%', 'auto']} pb={[0, 0, 3]}>
                  <Box width={1 / 1}>
                    <Text as="label" id="degree" pb=".4rem" color="selectLabel" fontSize="1.2rem" css="display: block;">
                      {t('schoolDegree')}
                    </Text>
                    <Select
                      name="degree"
                      onChange={onDegreeChange}
                      defaultValue={t('allSchoolDegrees')}
                      options={degrees}
                      value={filterBy.degree}
                      css="width: 100%;"
                    />
                  </Box>
                </Col>
              </Grid>
            </Col>
          </Grid>
          <Title sm icon={<RecommandationsIcon />}>
            {isLoading ? 'Recherche en cours...' : searchTerm || t('officialResource_plural')}
          </Title>
          <Box
            as="section"
            pt={1}
            css={`
              @media (max-width: ${breakpoints.smPlus}) {
                order: 3;
              }
            `}
          >
            <Tabs onSelect={onTabChange} defaultIndex={tab}>
              <Box pt={2}>
                <Tabs.TabList>
                  <Tabs.Tab css={tabStyle}>Séries</Tabs.Tab>
                  <Tabs.Tab css={tabStyle}>Chaînes</Tabs.Tab>
                </Tabs.TabList>
              </Box>
              <Tabs.TabPanel>
                <Box as="section" pt={1}>
                  {collections && (
                    <React.Fragment>
                      <CollectionList data={collections.results} />
                      {collections.count > Pagination.limit && (
                        <Pagination
                          total={collections.count}
                          page={collectionsPage}
                          onPageChange={onCollectionsPageChange}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Box>
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                <Box as="section" pt={1}>
                  {folders && (
                    <React.Fragment>
                      <FolderList data={folders.results} />
                      {folders.count > Pagination.limit && (
                        <Pagination total={folders.count} page={foldersPage} onPageChange={onFoldersPageChange} />
                      )}
                    </React.Fragment>
                  )}
                </Box>
              </Tabs.TabPanel>
            </Tabs>
          </Box>
        </Content>
      </Container>
    );
  }
}

export default withNamespaces()(OfficiaLResourcesContent);
