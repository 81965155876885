import ArrowSvg from 'assets/svg/arrow-swiper.svg';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';
import setFieldData from 'final-form-set-field-data';
import setFieldTouched from 'final-form-set-field-touched';
import property from 'lodash.property';
import uniqBy from 'lodash.uniqby';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { DragDropContext, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import BuildButton from 'src/components/elements/BuildButton';
import { Button } from 'src/components/elements/Button';
import { GameCheckbox, TextCheckbox } from 'src/components/elements/Checkbox';
import CustomSelect from 'src/components/elements/CustomSelect';
import FormRow from 'src/components/elements/FormRow';
import Modal from 'src/components/elements/Modal';
import Recorder from 'src/components/elements/Recorder';
import Select from 'src/components/elements/Select';
import Toggle from 'src/components/elements/Toggle';
import Title from 'src/components/typography/Title';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { LoadingContext } from 'src/context';
import CreateFolder from 'src/pages/CreateFolder';
import { canDeleteCollection } from 'src/utils/acl';
import { cardTarget } from 'src/utils/dnd';
import { FieldError } from 'src/utils/form';
import styled from 'styled-components';
import CardEdition from './CardEdition';

import AddSvg from 'assets/svg/add-circle.svg';
import CollectionSvg from 'assets/svg/collection.svg';
import TriangleSvg from 'assets/svg/dropup.svg';
import SearchSvg from 'assets/svg/search.svg';
import SwitchSvg from 'assets/svg/swap.svg';
import Layout from 'src/Layout';
import Input, { LabelledInput } from 'src/components/elements/Input';
import InputSearchbar from 'src/components/elements/InputSearchbar';
import Warning from 'src/components/elements/Warning';
import { svgStyle } from 'src/styles/helpers';
import { theme } from 'src/styles/theme';
import { filteredDegreesWithSubject } from 'src/utils/degrees';
import { GAME_ASSO, GAME_BRIO, GAME_MEMO, GAME_TYPO, GAME_QUIZAKO } from 'src/utils/games';
import ImportCsv from './ImportCsv';
import ModalContainer from './ModalContainer';
import Navigation, { Step } from './Navigation';
import Swiper from './Swiper';
import { countErrors, handleFocusOnFirstError, debouncedValidate, validateSerie  } from './validator';

export const emptyFace = {
  text: '',
  description: '',
  isCorrect: true,
};

export const emptyCard = {
  type: 'SINGLE_RESPONSE',
  frontFace: {
    ...emptyFace,
  },
  backFace: {
    ...emptyFace,
  },
  backFaces: [],
};

const TriangleIcon = styled(TriangleSvg)`
cursor: pointer;
height: 3rem;
width: 3rem;
`;

const focusOnErrors = createDecorator();

const ArrowSwiper = styled(ArrowSvg)`
  position: sticky;
  top: 48%;
  min-width: 30px;
  max-width: 30px !important;
  min-height: 30px;
  max-height: 40px !important;
  overflow-y: auto;
  cursor: pointer;
  z-index: 100;
  ${props => props.rotate ? `
    transform: rotate(180deg);
    left: calc(5% + 80px);
    @media (max-width: 1700px) {
      left: calc(1% + 80px);
    }
    @media (max-width: 1480px) {
      left: 1%;
    }
  ` : `
    right: 5%
    @media (max-width: 1700px) {
      right: 1%;
    }
  `}
  ${props => props.disabled && `
    opacity: 0;
    cursor: default;
  `}
`;

const AddIcon = styled(AddSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
`;

const SwitchIcon = styled(SwitchSvg)`
  ${svgStyle};
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
`;

const CollectionIcon = styled(CollectionSvg)`
  ${svgStyle};
  width: 2.4rem;
  height: 2.4rem;
`;

const SearchIcon = styled(SearchSvg)`
  ${svgStyle};
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  fill: #D1D1D1;
`;


const VanishingCol = styled(Col)`
  &.fadeout {
    animation: fadeOutAnim 0.3s ease-in-out forwards;
  }

  &.fadein {
    animation: fadeInAnim 0.3s ease-in-out forwards;
  }

  @keyframes fadeOutAnim {
    0% { opacity: 1; }
    100% {
      opacity: 0;
      display: none;
      visibility: collapse;
    }
  }

  @keyframes fadeInAnim {
    0% { opacity: 0; }
    100% {
      opacity: 1;
      display: flex;
      visibility: visible;
    }
  }
`;

@DropTarget('CARD', cardTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))
class CardFields extends PureComponent {
  static propTypes = {
    connectDragSource: PropTypes.func,
    connectDropTarget: PropTypes.func,
  };

  render() {
    const { connectDropTarget, ...others } = this.props;
    return (
      connectDropTarget &&
      connectDropTarget(
        <div style={{ width: '100%' }}>
          <CardEdition {...others} />
        </div>
      )
    );
  }
}

@DragDropContext(HTML5Backend)
class CreateCollection extends PureComponent {
  static contextType = LoadingContext;
  state = {
    hasAlreadySubmit: false,
    currentStep: 0,
    search: '',
    modalImportCSVIsOpen: false,
  };


  changeStep = step => {
    window.scrollTo(0, 0);
    this.setState({ currentStep: step });
  };

  redirectTab = (step) => {
    this.changeStep(step);
  };

  handleSubmit = (data, addCollection) => {
    const { editMode, onSubmit } = this.props;
    if (!editMode) {
      this.setState({ hasAlreadySubmit: true });
    }
    const errors = validateSerie(data);
    if (errors) {
      handleFocusOnFirstError(errors, this.redirectTab);
    } else {
      return onSubmit(data, addCollection);
    }
  };

  handlePrevClick = () => {
    if (this.state.currentStep > 0) {
      this.changeStep(this.state.currentStep - 1);
    }
  };

  handleNextClick = () => {
    if (this.state.currentStep < (Object.keys(Step).length / 2 - 1)) {
      this.changeStep(this.state.currentStep + 1);
    }
  };

  handleKeyPress = e => {
    if (e.target.tagName !== 'INPUT') {
      if (e.keyCode === 37) {
        this.handlePrevClick();
      } else if (e.keyCode === 39) {
        this.handleNextClick();
      }
    }
  };

  closeImportCsvModal = () => {
    this.setState({ modalImportCSVIsOpen: false });
  };

  componentDidMount() {
    this.context.removeLoading();
    const { editMode, history } = this.props;
    this.setState({ hasAlreadySubmit: editMode || false });
    const step = history.location.state?.tab;
    if (step in Step) {
      this.changeStep(step);
    }
    const focus = history.location.state?.focus;
    if (focus) {
      setTimeout(() => {
        const element = document.querySelector(`[name="${focus}"]`);
        if (element) {
          element.focus();
        }
      }, 500);
    }
    window.addEventListener('keydown', this.handleKeyPress);
  }


  setFieldValue = ([name, value], state, utils) => {
    utils.changeValue(state, name, () => value);
  };

  render() {
    const {
      t,
      closeRecording,
      normalizeCollection,
      openModalCreateFolder,
      closeModalCreateFolder,
      setRecord,
      setRecordFieldName,
      toggleModal,
      toggleRecording,
      addCollection,
      collection,
      degrees,
      editMode,
      folders,
      modalCreateFolderIsOpen,
      modalRecordIsOpen,
      mutate,
      record,
      recording,
      recordFieldName,
      subjects,
      removeCollection,
      collectionId,
      history,
      user,
    } = this.props;

    const { modalImportCSVIsOpen } = this.state;

    const isFirst = history.location.state?.isFirstSplit;
    const isSecond = history.location.state?.isSecondSplit;

    return (
      <>
        <ArrowSwiper rotate={'true'} disabled={this.state.currentStep === 0} onClick={this.handlePrevClick} />
        <Container css="position: relative;">
          <Content mt={3}>
            <Grid justifyContent="space-between">
              <Col>
                <Title as="h1" icon={<CollectionIcon />} medium>
                  {isFirst && "Modification de la série n°1"}
                  {isSecond && "Modification de la série n°2"}
                  {!isFirst && !isSecond && (editMode ? t('editTheSerie') : t('createTheSerie'))}
                </Title>
              </Col>
              {!!collectionId &&
                collection &&
                collection.data.collection &&
                canDeleteCollection(collection.data.collection, user) && (
                  <Col flex="0 0 auto">
                    <Button
                      dark
                      onClick={() => {
                        toggleModal(Layout.MODAL_DELETE_COLLECTION, () => {
                          removeCollection({
                            variables: { collection: collectionId },
                          }).then(() => {
                            history.push(`/`);
                          });
                        });
                      }}
                      css="width: 100%; max-width: 20rem;"
                    >
                      {t('deleteCollection')}
                    </Button>
                  </Col>
                )}
            </Grid>
            <Form
              onSubmit={data => this.handleSubmit(data, addCollection)}
              decorators={[focusOnErrors]}
              mutators={{ ...arrayMutators, setFieldData, setFieldTouched, setFieldValue: this.setFieldValue }}
              subscription={{ submitting: true, pristine: true, errors: true }}
              keepDirtyOnReinitialize={true}
              validate={this.state.hasAlreadySubmit && validateSerie}
              initialValues={
                (collection && collection.data.collection && normalizeCollection(collection.data.collection)) || {
                  title: '',
                  description: '',
                  isPublished: false,
                  // FIXME Not working due too https://github.com/final-form/react-final-form/issues/370
                  cards: [{ ...emptyCard }],
                  schoolDegrees: [],
                  assoMode: false,
                  memoMode: false,
                  typoMode: false,
                  brioMode: false,
                  quizakoMode: false,
                  ignoreCase: false,
                  ignoreOrder: false,
                  ignoreSpaces: false,
                  ignoreCharacters: '',
                }
              }
              render={({
                handleSubmit,
                submitting,
                errors,
                form: {
                  mutators: { setFieldData, setFieldTouched, setFieldValue },
                }
              }) => (
                <form onSubmit={handleSubmit}>
                  <FieldError name={FORM_ERROR} />
                  <Flex pt={2} css={`gap: 2rem;`} flexDirection='column'>
                    <Navigation currentStep={this.state.currentStep} changeStep={this.changeStep} />
                    <Swiper>
                      <Flex flexWrap={'wrap'} pb={3} css={`display: ${this.state.currentStep === Step.Infos ? 'flex' : 'none !important'};`}>
                        <Col pb={3}>
                          <Field name="schoolSubject" required={true}>
                            {({ input }) => (
                              <FormRow
                                id="schoolSubject"
                                label={t('schoolSubject')}
                                description={t('schoolSubjectSelectDescription')}
                                field={
                                  <Select
                                    id="schoolSubject"
                                    {...input}
                                    placeholder={t('searchInTheList')}
                                    options={
                                      collection
                                        ? uniqBy(subjects.concat(collection.data.collection.schoolSubject), property('id'))
                                        : subjects
                                    }
                                    css="width: 100%; max-width: 31.6rem;"
                                  />
                                }
                                error={<FieldError name="schoolSubject" />}
                                css="max-width: 42.8rem;"
                              />
                            )}
                          </Field>
                        </Col>
                        <Col width={[1 / 1, 1 / 1, 5 / 10]} pb={3}>
                          <Field name="schoolSubject" subscription={{ value: true }}>
                            {schoolSubjectProps => {
                              const selectedSchoolSubject = schoolSubjectProps.input.value;
                              const filteredDegrees = filteredDegreesWithSubject(
                                degrees.data.degrees,
                                selectedSchoolSubject
                              ).map(degree => ({
                                label: degree.name,
                                value: degree.id,
                              }));

                              return (
                                <Field name="schoolDegrees" type="select">
                                  {({ input }) => {
                                    return (
                                      <FormRow
                                        id="schoolDegrees"
                                        label="Degré(s)"
                                        description={t('schoolDegreeSelectDescription')}
                                        inputCss="width: 100%; max-width: 31.6rem;"
                                        field={
                                          <CustomSelect
                                            {...input}
                                            isDisabled={!selectedSchoolSubject}
                                            isMulti={true}
                                            isClearable={false}
                                            isLoading={degrees.loading}
                                            options={filteredDegrees}
                                            placeholder={t('searchInTheList')}
                                            truncateLabel={t('schoolDegreeLowercase', {
                                              count: input.value ? input.value.length : 0,
                                            })}
                                          />
                                        }
                                        error={<FieldError name="schoolDegrees" />}
                                        css="max-width: 42.8rem;"
                                      />
                                    );
                                  }}
                                </Field>
                              );
                            }}
                          </Field>
                        </Col>
                        <Col width={[1 / 1, 1 / 1, 5 / 10]} pb={3}>
                          <FormRow
                            id="title"
                            label="Nom de la série"
                            field={
                              <Field name="title">
                                {({ input }) => <Input {...input} css="width: 100%;" placeholder={t('addACollectionName')} />}
                              </Field>
                            }
                            error={<FieldError name="title" />}
                          />
                        </Col>
                        <Col width={[1 / 1, 1 / 1, 5 / 10]} pb={3}>
                          <Box pt="3.3rem">
                            <Field name="isPublished" type="checkbox">
                              {({ input }) => {
                                return (
                                  <Toggle
                                    id="isPublished"
                                    {...input}
                                    name="isPublished"
                                    onChange={e => {
                                      const checked = e.target.checked;
                                      if (!checked) {
                                        toggleModal(Layout.MODAL_UNPUBLISH_COLLECTION, () => input.onChange(checked));
                                      } else {
                                        input.onChange(checked);
                                      }
                                    }}
                                    defaultLabel="Brouillon"
                                    checkedLabel="Public"
                                  />
                                );
                              }}
                            </Field>
                          </Box>
                        </Col>
                        <Col width={[1 / 1, 1 / 1, 8 / 10]} pb={3}>
                          <FormRow
                            id="description"
                            label={t('description')}
                            field={<Field parse={(value) => (value)} name="description" >
                              {({ input }) => <Input {...input} css="width: 100%;" placeholder={t("addACollectionDescription")} />}
                            </Field>}
                            error={<FieldError name="description" />}
                          />
                        </Col>
                      </Flex>
                      <Flex flexWrap={'wrap'} justifyContent={'center'} pb={3} css={`display: ${this.state.currentStep === Step.Game ? 'flex' : 'none !important'};`}>
                        <Col width={[1 / 1, 1 / 1, 3 / 4]} pb={3}>
                          <Flex css={`flex-direction: column; gap: 3rem;`}>
                            <FieldError name="mode" />
                            <Box pb="1.4rem" pt={1}>
                              <FormRow
                                id="assoMode"
                                field={<Field
                                  name="assoMode"
                                  type="checkbox"
                                  label={t('game.assoModeCheckbox')}
                                  component={GameCheckbox}
                                  game={GAME_ASSO}
                                />}
                                error={<FieldError name="assoMode" />}
                              />
                            </Box>
                            <Box pb="1.4rem">
                              <FormRow
                                id="typoMode"
                                field={<Field
                                  name="typoMode"
                                  type="checkbox"
                                  component={GameCheckbox}
                                  label={t('game.typoModeCheckbox')}
                                  game={GAME_TYPO}
                                />}
                                error={<FieldError name="typoMode" />}
                              />
                            </Box>
                            <Box pb="1.4rem">
                              <FormRow
                                id="memoMode"
                                field={<Field
                                  name="memoMode"
                                  type="checkbox"
                                  label={t('game.memoModeCheckbox')}
                                  component={GameCheckbox}
                                  game={GAME_MEMO}
                                />}
                                error={<FieldError name="memoMode" />}
                              />
                            </Box>
                            <Box>
                              <FormRow
                                id="brioMode"
                                field={<Field
                                  name="brioMode"
                                  type="checkbox"
                                  component={GameCheckbox}
                                  label={t('game.brioModeCheckbox')}
                                  game={GAME_BRIO}
                                />}
                                error={<FieldError name="brioMode" />}
                              />
                            </Box>
                            <Box>
                              <FormRow
                                id="quizakoMode"
                                field={<Field
                                  name="quizakoMode"
                                  type="checkbox"
                                  component={GameCheckbox}
                                  label={t('game.quizakoModeCheckbox')}
                                  game={GAME_QUIZAKO}
                                />}
                                error={<FieldError name="quizakoMode" />}
                              />
                            </Box>
                          </Flex>
                        </Col>
                      </Flex>
                      <Flex flexWrap={'wrap'} pb={3} css={`display: ${this.state.currentStep === Step.Cards ? 'flex' : 'none !important'};width:100%;`}>
                        <FieldArray name="cards" isEqual={(a, b) => a === b}>
                          {({ fields }) => (
                            <Col width={1 / 1}>
                              <Box width={1 / 1}>
                                <Grid>
                                  <Flex width={1 / 1} pl={2} alignItems="center" flexDirection="column">
                                    <Box pb={2} css='position: relative;'>
                                      <InputSearchbar
                                        sm
                                        css="flex: 1 1 100%; margin-right: 1rem; ::-webkit-search-cancel-button{display: none;};"
                                        placeholder={"Rechercher une carte"}
                                        type="search"
                                        onChange={e => {
                                          const value = e.target.value;
                                          clearTimeout(this.timeout);
                                          this.timeout = setTimeout(() => {
                                            this.setState({ search: value });
                                          }, 300);
                                        }}
                                        style={{ width: "30rem" }}
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                      />
                                      <SearchIcon />
                                    </Box>
                                    <Field name="cards">
                                      {({ input }) => (
                                        <Flex
                                          justifyContent='center'
                                          width={['fit-content', 'fit-content', '100%']}
                                          flexDirection={["column", "column", "row"]}
                                          alignItems={["start", "start", "center"]}
                                          css={`gap: 1rem;`}
                                        >
                                          <Col width={[0, 0, 1 / 3]} justifyContent='center' pb={3}>
                                            <Text fontSize="1.5rem" color={theme.colors.secondary} fontWeight="600">
                                              <Flex as="label" onClick={() => {
                                                this.setState({ modalImportCSVIsOpen: true });
                                              }
                                              } alignItems="center" css="cursor: pointer;">
                                                <Box pr={1}>
                                                  <AddIcon />
                                                </Box>
                                                {t('importAListInCsvFormat')}
                                              </Flex>
                                            </Text>
                                          </Col>
                                          <Col width={[0, 0, 1 / 3]} justifyContent='center' pb={3}
                                            onClick={() => {
                                              setFieldValue('cards', [emptyCard, ...fields.value]);
                                              setTimeout(() => {
                                                const newCard = document.getElementById(`cards[0].frontFace.text`);
                                                newCard && newCard.focus();
                                              }
                                                , 0);
                                            }}
                                          >
                                            <Text fontSize="1.5rem" color={theme.colors.secondary} fontWeight="600">
                                              <Flex as="label" alignItems="center" css="cursor: pointer;">
                                                <Box pr={1}>
                                                  <AddIcon />
                                                </Box>
                                                {t('addACard')}
                                              </Flex>
                                            </Text>
                                          </Col>
                                          <Col width={[0, 0, 1 / 3]} justifyContent='center' pb={3}
                                            onClick={() => {
                                              const allSingleResponse = input.value.every(card =>
                                                card.type === 'SINGLE_RESPONSE'
                                              );

                                              if (allSingleResponse) {
                                                const newInput = [...input.value];
                                                fields.forEach((_field, index) => {
                                                  const {
                                                    frontFace,
                                                    backFace,
                                                  } = input.value[index];
                                                  newInput[index] = {
                                                    ...newInput[index],
                                                    frontFace: backFace,
                                                    backFace: frontFace,
                                                  };
                                                });
                                                input.onChange(newInput);
                                              }
                                            }}
                                          >
                                            <Text fontSize="1.5rem" color={theme.colors.secondary} fontWeight="600">
                                              <Flex as="label" css={`
                                              cursor: pointer;
                                              display: flex;
                                              align-items: center;
                                              ${input.value.some(card => card.type !== 'SINGLE_RESPONSE') && `
                                                opacity: 0.5;
                                                cursor: not-allowed;
                                              `}
                                            `}>
                                                <Box pr={1}>
                                                  <SwitchIcon />
                                                </Box>
                                                {t('switch')}
                                              </Flex>
                                            </Text>
                                          </Col>
                                        </Flex>
                                      )}
                                    </Field>
                                  </Flex>
                                  {fields.map((name, index) => {
                                    const search = this.state.search;
                                    const isVisible = search === '' ||
                                      fields.value[index].frontFace?.text?.toLowerCase().includes(search.toLowerCase()) ||
                                      fields.value[index].backFace?.text?.toLowerCase().includes(search.toLowerCase()) ||
                                      fields.value[index].backFaces?.some(face => face.text?.toLowerCase().includes(search.toLowerCase()));
                                    const uniqId = `card-${index}-${fields.value[index].id}`;

                                    return (
                                      <VanishingCol key={uniqId} width={1 / 1} pb={2} className={search && (!isVisible ? 'fadeout' : 'fadein')} id={uniqId}>
                                        <CardFields
                                          key={index}
                                          index={index}
                                          name={name}
                                          setFieldData={setFieldData}
                                          setFieldTouched={setFieldTouched}
                                          setFieldValue={setFieldValue}
                                          moveCard={fields.move}
                                          remove={(i) => { if (fields.length > 1) fields.remove(i); }}
                                          record={setRecordFieldName}
                                          onNextCard={() => {
                                            setFieldValue("cards", [...fields.value.slice(0, index + 1), { ...emptyCard }, ...fields.value.slice(index + 1)]);
                                            setTimeout(() => {
                                              const newCard = document.getElementById(`cards[${index + 1}].frontFace.text`);
                                              newCard && newCard.focus();
                                            }, 0);
                                          }}
                                        />
                                      </VanishingCol>
                                    );
                                  })}
                                </Grid>
                              </Box>
                              <Modal
                                style={{
                                  maxWidth: '70rem',
                                }}
                                isOpen={modalImportCSVIsOpen}
                                showCloseButton={true}
                                onRequestClose={this.closeImportCsvModal}
                              >
                                <ModalContainer title={"Importer la liste au format CSV"} css='max-width: 90%'>
                                  <ImportCsv fields={fields} closeModal={this.closeImportCsvModal} />
                                </ModalContainer>
                              </Modal>
                            </Col>
                          )}
                        </FieldArray>
                      </Flex>
                      <Flex flexWrap={'wrap'} pb={3} css={`width: 100%; display: ${this.state.currentStep === Step.Advanced ? 'flex' : 'none !important'};`}>
                        <Grid width={1 / 1} alignItems="center" justifyContent='center'>
                          <Col width={[1 / 1, 3 / 4, 2 / 3]} justifyContent='start' flexDirection='column'>
                            <Box pb="1.4rem">
                              <FormRow
                                id="ignoreCase"
                                field={<Field
                                  name="ignoreCase"
                                  type="checkbox"
                                  label={"Ignorer la casse"}
                                  component={TextCheckbox}
                                />}
                                error={<FieldError name="ignoreCase" />}
                              />
                            </Box>
                            <Box pb="1.4rem">
                              <FormRow
                                id="ignoreOrder"
                                field={<Field
                                  name="ignoreOrder"
                                  type="checkbox"
                                  label={"Ignorer l'ordre des caractères"}
                                  detail="3 x 9 et 9 x 3 sont des réponses valides"
                                  component={TextCheckbox}
                                />}
                                error={<FieldError name="ignoreOrder" />}
                              />
                            </Box>
                            <Box pb="1.4rem">
                              <FormRow
                                id="ingoreSpaces"
                                field={<Field
                                  name="ignoreSpaces"
                                  type="checkbox"
                                  label={"Ignorer les espaces"}
                                  component={TextCheckbox}
                                />}
                                error={<FieldError name="ignoreSpaces" />}
                              />
                            </Box>
                            <Box pb="1.4rem">
                              <FormRow
                                id="ignoreCharacters"
                                field={<Field
                                  parse={(value) => (value)}
                                  name="ignoreCharacters"
                                  label={"Ignorer les caractères"}
                                  detail="Entrez les caractères que vous souhaitez voir ignorés - pour l'ensemble de la série - à la restitution, sans espace ou autre séparateur entre eux."
                                  placeholder={"ex : ?!,."}
                                  component={LabelledInput}
                                />}
                                error={<FieldError name="ignoreCharacters" />}
                              />
                            </Box>
                          </Col>
                        </Grid>
                      </Flex>
                      <Flex flexWrap={'wrap'} pb={3} css={`width: 100%; display: ${this.state.currentStep === Step.Chain ? 'flex' : 'none !important'};`}>
                        <Grid width={1 / 1} alignItems="center" justifyContent='center'>
                          <Col flex={['1 1 100%', '1 1 100%', '1 1 100%', '0 0 31.5rem']} pb={2}>
                            <Field name="folders">
                              {({ input }) => {
                                const { data, loading } = folders;
                                const options =
                                  (data.folders &&
                                    data.folders.results.length > 0 &&
                                    data.folders.results.map(folder => ({
                                      label: folder.title,
                                      value: folder.id,
                                    }))) ||
                                  [];

                                return (
                                  <FormRow
                                    id="folders"
                                    label={t('addToAChain')}
                                    field={
                                      <CustomSelect
                                        {...input}
                                        isMulti={true}
                                        isClearable={false}
                                        isLoading={loading}
                                        options={options}
                                        placeholder={t('searchInTheList')}
                                        truncateLabel={t('chainLowercase', { count: input.value ? input.value.length : 0 })}
                                      />
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </Col>
                          <Col
                            flex={['1 1 100%', '1 1 100%', '1 1 100%', '0 0 9.4rem']}
                            justifyContent="center"
                            pb={[2, 2, 2, 0]}
                          >
                            <Text fontFamily="heading" fontWeight="semibold">
                              {t('or')}
                            </Text>
                          </Col>
                          <Col flex={['1 1 100%', '1 1 100%', '1 1 100%', '0 0 31.5rem']}>
                            <BuildButton
                              route={'/chaines/creer'}
                              onClick={e => {
                                e.preventDefault();
                                openModalCreateFolder();
                              }}
                              label={t('createAChain')}
                            />
                          </Col>
                        </Grid>
                      </Flex>
                    </Swiper>
                    <Col width={1 / 1} pt={2} pb="8rem" justifyContent="center">
                      <Grid alignItems="center" justifyContent="center" position="relative">
                        {editMode && (
                          <Col pb={2}>
                            <Button
                              type="button"
                              disabled={submitting}
                              onClick={() => history.goBack()}
                              css="min-width: 20.4rem;"
                            >
                              {t('cancel')}
                            </Button>
                          </Col>
                        )}
                        <Col pb={2}>
                          <Field name="isPublished" subscription={{ value: true }}>
                            {({ input }) => (
                              <Button
                                type="submit"
                                disabled={submitting}
                                onClick={() => handleFocusOnFirstError(errors, this.redirectTab)}
                                css="min-width: 20.4rem;">
                                {input.value === true && (editMode ? t('editTheSerie') : t('createTheSerie'))}
                                {input.value === false &&
                                  (editMode ? t('editTheUnpublishedSerie') : t('createTheUnpublishedSerie'))}
                              </Button>
                            )}
                          </Field>
                        </Col>
                      </Grid>
                    </Col>
                  </Flex>
                  <Modal
                    isOpen={modalCreateFolderIsOpen}
                    showCloseButton={true}
                    style={{ maxWidth: '65rem' }}
                    onRequestClose={() => {
                      closeModalCreateFolder();
                    }}
                  >
                    <ModalContainer title={t('createAChain')}>
                      <Field name="folders" subscription={{ value: true }}>
                        {({ input }) => (
                          <CreateFolder.Form
                            partial={true}
                            onSuccess={folder => {
                              // Close modal, update user folders and add the new value
                              closeModalCreateFolder();
                              folders.refetch().then(() => {
                                input.onChange(input.value.concat({ value: folder.id, label: folder.title }));
                              });
                            }}
                          />
                        )}
                      </Field>
                    </ModalContainer>
                  </Modal>
                  <Modal
                    isOpen={modalRecordIsOpen}
                    showCloseButton={true}
                    style={{ maxWidth: '65rem' }}
                    onRequestClose={closeRecording}
                  >
                    <Box pt={3} pb={2} px={[1, 2, 2]}>
                      <Text as="h4" fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
                        {t('recording')}
                      </Text>
                      <Grid pt={['1.2rem', '1.2rem', '2.2rem']} css="flex: 1 1 auto;">
                        {!record && (
                          <Col width={1 / 1} pb={2} justifyContent="center">
                            <Button type="button" onClick={toggleRecording}>
                              {recording ? t('stop') : t('startRecording')}
                            </Button>
                          </Col>
                        )}
                        <Recorder
                          mediaOpts={{
                            mimeType: 'audio/webm;codecs=opus',
                          }}
                          command={recording ? 'start' : 'stop'}
                          onStop={record => {
                            setRecord(record);
                          }}
                        />
                        {record && (
                          <Col width={1 / 1} pb={2} justifyContent="center">
                            <audio controls src={window.URL.createObjectURL(record)} />
                          </Col>
                        )}

                        {record && (
                          <Col width={1 / 1} pb={2} justifyContent="center">
                            <Grid css="flex: 1 1 auto; max-width: 40rem; margin: auto;">
                              <Col width={[1 / 1, 1 / 2]}>
                                <Button type="button" onClick={toggleRecording} dark css="width: 100%;">
                                  {t('restart')}
                                </Button>
                              </Col>
                              <Col width={[1 / 1, 1 / 2]}>
                                <Button
                                  type="button"
                                  css="width: 100%;"
                                  onClick={() => {
                                    mutate({
                                      variables: { file: record },
                                      context: {
                                        hasUpload: true,
                                      },
                                    }).then(({ data: { SingleUpload } }) => {
                                      setFieldValue(recordFieldName, SingleUpload);
                                      closeRecording();
                                    });
                                  }}
                                >
                                  {t('validate')}
                                </Button>
                              </Col>
                            </Grid>
                          </Col>
                        )}
                      </Grid>
                    </Box>
                  </Modal>
                  <Flex
                    css={`
                    position: sticky;
                    bottom: 5rem;
                    justify-content: end;
                    align-items: center;
                    right: 0;
                    margin-bottom: 5rem;
                    gap: 4rem;
                    width: fit-content;
                    margin-left: auto;
                    z-index: 10;
                  `}
                  >
                    {errors && countErrors(errors) > 0 && <Warning count={countErrors(errors)} onClick={() => handleFocusOnFirstError(errors, this.redirectTab, this.state.currentStep)} />}
                    <TriangleIcon
                      title={t('backToTopOfTheList')}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }} />
                  </Flex>
                </form>
              )}
            />
          </Content >
        </Container >
        <ArrowSwiper disabled={this.state.currentStep === Object.keys(Step).length / 2 - 1} onClick={this.handleNextClick} />
      </>
    );
  }
}

export default withNamespaces()(CreateCollection);
