import animationData from 'assets/json/brio-cortex.json';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';
import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  0% { transform: rotate(0deg) }
  50% { transform: rotate(2deg) }
  100% { transform: rotate(0deg) }
`;

const Animate = styled.div.attrs({})`
  width: 100%;
  height: 100%;
  padding-top: 8%;
  animation: ${rotateAnimation} ${props => props.rotation && '500ms linear infinite'};
`;

class Cortex extends PureComponent {
  static propTypes = {
    startTransition: PropTypes.bool,
  };

  render() {
    const { startTransition } = this.props;

    return (
      <Animate rotation={!startTransition}>
        <Lottie
          options={{
            render: 'svg',
            animationData,
            loop: false,
            autoplay: false,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height="100%"
          width="100%"
          isStopped={!startTransition}
          isPaused={!startTransition}
          isClickToPauseDisabled={true}
        />
      </Animate>
    );
  }
}

export default Cortex;
