import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { GET_FOLDER } from 'src/queries/folders';
import Content from './Content';

class FolderContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
  };

  isContentLoaded = false;

  render() {
    const { match, user } = this.props;

    return (
      <Query
        query={GET_FOLDER}
        fetchPolicy='network-only'
        variables={{
          id: match.params.id,
          withCollections: false,
        }}
      >
        {({ data, error, loading }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return <Content folder={data.folder} match={match} user={user} />;
        }}
      </Query>
    );
  }
}

export default FolderContainer;
