import { Tab as ReactTab } from 'react-tabs';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';

const Tab = styled(ReactTab)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 4.6rem;
  border: none;
  cursor: pointer;
  border-bottom: 0.2rem solid ${props => (props.selected ? theme.colors.secondary : 'rgba(22, 216, 197, .4)')};
  background-color: transparent;
  color: ${theme.colors.black};
  font-size: 2rem;
  font-weight: ${props => (props.selected ? theme.fontWeights.semibold : 'normal')};
  white-space: nowrap;
  transition: border-color 225ms ease;
  ${props => props.css};
  &:hover {
    border-bottom: 0.2rem solid ${theme.colors.secondary};
  }
  ${props => props.separator && `
    position: relative;
    :after {
      content: ' ';
      position: absolute;
      right: -.5px;
      height: 3rem;
      border: 1px solid #707070;
    }
  `}
`;

Tab.TabsRole = 'Tab';

export default Tab;
