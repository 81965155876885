import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import Item from './Item';
import Section from './Section';
import { AssoIcon, BookIcon, BrainIcon, MemoIcon, RectoVersoIcon, TypoIcon } from './Icons';

class Navigation extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    const { children } = this.props;

    return (
      <Box bg="gray200">
        <Container>
          <Content py={2}>
            <Flex justifyContent="center">{children}</Flex>
          </Content>
        </Container>
      </Box>
    );
  }
}

Navigation.Item = Item;
Navigation.Section = Section;
Navigation.AssoIcon = AssoIcon;
Navigation.BookIcon = BookIcon;
Navigation.BrainIcon = BrainIcon;
Navigation.MemoIcon = MemoIcon;
Navigation.QuizakoIcon = TypoIcon; /* TODO: Quizako icon */
Navigation.RectoVersoIcon = RectoVersoIcon;
Navigation.TypoIcon = TypoIcon;

export default Navigation;
