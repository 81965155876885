import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import CloseSvg from 'assets/svg/close.svg';
import DuelSvg from 'assets/svg/duel.svg';
import SoloSvg from 'assets/svg/solo-uniq.svg';

export const CloseIcon = styled(CloseSvg)`
  ${svgStyle};
  width: 1.6rem;
  height: 1.6rem;
`;

export const DuelIcon = styled(DuelSvg)`
  ${svgStyle};
  width: 4rem;
  height: 3.5rem;

  @media (min-width: ${breakpoints.md}) {
    width: 6.2rem;
    height: 5.6rem;
  }
`;

export const SoloIcon = styled(SoloSvg)`
  ${svgStyle};
  width: 1.2rem;
  height: 4.2rem;

  @media (min-width: ${breakpoints.md}) {
    width: 2.6rem;
    height: 7rem;
  }
`;
