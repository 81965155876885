import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Box, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { breakpoints } from 'src/styles/theme';
import MediaQuery from 'react-responsive';
import { Status, TO_REVIEW_STATUS, LEARNED_STATUS } from 'src/components/elements/Status';
import { UserContext } from 'src/context';
import { hasTeacherRole } from 'src/utils/roles';

class Details extends PureComponent {
  static contextType = UserContext;
  static propTypes = {
    t: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    cards: PropTypes.array,
  };

  render() {
    const { t, title, description, cards } = this.props;
    let learnedCards = 0;
    let toReviewCards = 0;
    const user = this.context;

    cards.map(card => {
      if (card.userLinkCard) {
        if (card.userLinkCard.status === TO_REVIEW_STATUS) {
          toReviewCards++;
        } else if (card.userLinkCard.status === LEARNED_STATUS) {
          learnedCards++;
        }
      }
    });

    return (
      <Box px={[0, 0, 2]} py="1.8rem" css="flex: 1 1 auto;">
        <MediaQuery query={`(min-width: ${breakpoints.md})`}>
          <Text as="h2" pb=".2rem" fontFamily="heading" fontSize="1.8rem" fontWeight="semibold" lineHeight="1.4">
            {title}
          </Text>
        </MediaQuery>
        <Text as="span" fontSize="1.4rem">
          {description}
        </Text>
        <Grid pt={2}>
          {user && !hasTeacherRole(user) &&
            <>
              <Col width={[1 / 2, 1 / 2, 1 / 2, 3 / 12]} alignItems="center" pb={[2, 2, 1, 0]}>
                <Status status={LEARNED_STATUS} />
                <Text pl={1} fontSize="1.2rem">
                  {t('acquiredCardWithCount', { count: learnedCards })}
                </Text>
              </Col>
              <Col width={[1 / 2, 1 / 2, 1 / 2, 3 / 12]} alignItems="center" pb={[2, 2, 1, 0]}>
                <Status status={TO_REVIEW_STATUS} />
                <Text pl={1} fontSize="1.2rem">
                  {t('toReviewCardWithCount', { count: toReviewCards })}
                </Text>
              </Col>
            </>
          }
          {toReviewCards > 0 && (
            <Col width={[1 / 1, 1 / 1, 1 / 1, 4 / 12]} pb={[1, 1, 1, 0]}>
              <Text
                as="span"
                width={1 / 1}
                fontFamily="heading"
                fontSize="1.2rem"
                fontWeight="semibold"
                textAlign={['center', 'center', 'left']}
              >
                {t('youMustSeeThisSerieAgain')}
              </Text>
            </Col>
          )}
        </Grid>
      </Box>
    );
  }
}

export default withNamespaces()(Details);
