import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { GET_CURRENT_USER, UPDATE_USER } from 'src/queries/users';
import Content from './Content';

class AddFriendsContainer extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    showBackToProfileButton: PropTypes.bool,
    searchOnlyStudent: PropTypes.bool,
  };

  isContentLoaded = false;
  state = {
    search: '',
  };

  addFriend = (user, newFriend, updateUser) => () => {
    return updateUser({
      variables: {
        input: {
          friends: [...user.friends.map((friend) => ({ id: friend.id })), { id: newFriend.id }],
        },
      },
    });
  };

  removeFriend = (user, oldFriend, updateUser) => () => {
    return updateUser({
      variables: {
        input: {
          friends: [
            ...user.friends.filter((friend) => friend.id !== oldFriend.id).map((friend) => ({ id: friend.id })),
          ],
        },
      },
    });
  };

  onSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  render() {
    const { history, showBackToProfileButton, searchOnlyStudent } = this.props;
    const { search } = this.state;
    const currentAvatar = history.location.state ? history.location.state.currentAvatar : null;

    /*eslint-disable */
    const queries = {
      me: ({ render }) => (
        <Query query={GET_CURRENT_USER} variables={{ withFriends: true }}>
          {render}
        </Query>
      ),
      updateUser: ({ render }) => <Mutation mutation={UPDATE_USER}>{render}</Mutation>,
    };

    if (!this.Composed) {
      this.Composed = adopt(queries);
    }
    /*eslint-enable */

    return (
      <this.Composed>
        {({ updateUser, me }) => {
          if ((updateUser.loading || me.loading) && !this.isContentLoaded) return <div />;
          if (updateUser.error || me.updateUser) return <p>Error :(</p>;
          this.isContentLoaded = true;
          const user = me.data.me;

          // TODO Composant friends search recevant juste un term de recherche
          return (
            <Content
              addFriend={this.addFriend}
              removeFriend={this.removeFriend}
              onSearchChange={this.onSearchChange}
              search={search}
              showBackToProfileButton={showBackToProfileButton}
              searchOnlyStudent={searchOnlyStudent}
              updateUser={updateUser}
              currentUser={user}
              currentAvatar={currentAvatar}
            />
          );
        }}
      </this.Composed>
    );
  }
}

export default AddFriendsContainer;
