"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var styled_components_1 = __importStar(require("styled-components"));
var SwiperContainer = styled_components_1.default.div.attrs({})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  background-color: #FFFFFF;\n  border: 2px solid #E9E9E9;\n  border-radius: 1rem;\n  padding: 0.3rem;\n"], ["\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  background-color: #FFFFFF;\n  border: 2px solid #E9E9E9;\n  border-radius: 1rem;\n  padding: 0.3rem;\n"])));
var Option = styled_components_1.default.div.attrs({})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  font-size: 1.4rem;\n  font-weight: ", ";\n  color: #747474;\n  position: relative;\n  z-index: 2;\n  transition: all 0.3s ease;\n  border-radius: 0.7rem;\n  color: ", ";\n  padding: 0.5rem 0.2rem;\n  user-select: none;\n"], ["\n  flex: 1;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  font-size: 1.4rem;\n  font-weight: ", ";\n  color: #747474;\n  position: relative;\n  z-index: 2;\n  transition: all 0.3s ease;\n  border-radius: 0.7rem;\n  color: ", ";\n  padding: 0.5rem 0.2rem;\n  user-select: none;\n"])), function (_a) {
    var active = _a.active;
    return active ? 'bold' : 'normal';
}, function (_a) {
    var active = _a.active;
    return active ? '#FFFFFF' : '#747474';
});
var AnimatedBackground = styled_components_1.default.div.attrs({})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0.3rem;\n  height: calc(100% - 0.6rem);\n  background-color: #13d8c6;\n  border-radius: 0.7rem;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  z-index: 1;\n  \n  ", "\n"], ["\n  position: absolute;\n  top: 0.3rem;\n  height: calc(100% - 0.6rem);\n  background-color: #13d8c6;\n  border-radius: 0.7rem;\n  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);\n  z-index: 1;\n  \n  ", "\n"])), function (_a) {
    var activeIndex = _a.activeIndex, totalOptions = _a.totalOptions;
    return (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width: calc(", "% - 0.6rem);\n    left: calc(", "% + 0.3rem);\n  "], ["\n    width: calc(", "% - 0.6rem);\n    left: calc(", "% + 0.3rem);\n  "])), 100 / totalOptions, (activeIndex * 100) / totalOptions);
});
var HiddenSelect = styled_components_1.default.select(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  display: none;\n  opacity: 0;\n  width: 0;\n  height: 0;\n"], ["\n  position: absolute;\n  display: none;\n  opacity: 0;\n  width: 0;\n  height: 0;\n"])));
var TypeSwiper = function (_a) {
    var t = _a.t, input = __rest(_a, ["t"]);
    var options = [
        { value: 'SINGLE_RESPONSE', label: t('cards.types.single_response') },
        { value: 'MULTIPLE_RESPONSE', label: t('cards.types.multiple_response') },
        { value: 'TRUE_FALSE', label: t('cards.types.true_false') }
    ];
    var activeIndex = options.findIndex(function (option) { return option.value === input.value; });
    var handleKeyDown = function (e) {
        switch (e.key) {
            case 'ArrowLeft':
                e.preventDefault();
                e.stopPropagation();
                if (activeIndex > 0) {
                    input.onChange(options[activeIndex - 1].value);
                }
                break;
            case 'ArrowRight':
                e.preventDefault();
                e.stopPropagation();
                if (activeIndex < options.length - 1) {
                    input.onChange(options[activeIndex + 1].value);
                }
                break;
        }
    };
    return (React.createElement(SwiperContainer, { tabIndex: 0, onKeyDown: handleKeyDown, role: "radiogroup", "aria-label": t('cards.types.select_type') },
        React.createElement(AnimatedBackground, { activeIndex: activeIndex, totalOptions: options.length }),
        React.createElement(HiddenSelect, __assign({}, input), options.map(function (option) { return (React.createElement("option", { key: option.value, value: option.value }, option.label)); })),
        options.map(function (option) { return (React.createElement(Option, { key: option.value, active: input.value === option.value, onClick: function () { return input.onChange(option.value); }, role: "radio", "aria-checked": input.value === option.value, tabIndex: input.value === option.value ? 0 : -1 }, option.label)); })));
};
exports.default = (0, react_i18next_1.withNamespaces)()(TypeSwiper);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
