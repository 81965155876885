import styled, { css } from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import AssoSvg from 'assets/svg/asso.svg';
import BookSvg from 'assets/svg/book.svg';
import BrainSvg from 'assets/svg/brain.svg';
import MemoSvg from 'assets/svg/memo.svg';
import RectoVersoSvg from 'assets/svg/recto-verso.svg';
import TypoSvg from 'assets/svg/typo.svg';

const style = css`
  ${svgStyle};
  width: 2.8rem;
  height: 2.8rem;

  @media (min-width: ${breakpoints.md}) {
    width: 4rem;
    height: 4rem;
  }
`;

export const AssoIcon = styled(AssoSvg)`
  ${style};
`;

export const BookIcon = styled(BookSvg)`
  ${style};
`;

export const BrainIcon = styled(BrainSvg)`
  ${style};
`;

export const MemoIcon = styled(MemoSvg)`
  ${style};
`;

export const RectoVersoIcon = styled(RectoVersoSvg)`
  ${style};
`;

export const TypoIcon = styled(TypoSvg)`
  ${style};
`;
