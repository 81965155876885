import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withNamespaces } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { Box, Flex, Text } from 'rebass';
import Card from 'src/components/blocks/Card';
import Link from 'react-router-dom/Link';
import SubscribeButton from 'src/components/elements/SubscribeButton';
import { breakpoints, theme } from 'src/styles/theme';

const Container = styled(Flex)`
  overflow: hidden;
  width: 100%;
  background-color: #f6f7fc;
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);
`;

const Summary = styled(Flex)`
  @media (min-width: ${breakpoints.lg}) {
    box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);
  }
`;

const separatorStyle = css`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 1.2rem;
    display: block;
    width: calc(100% - 2.4rem);
    height: 1px;
    background-color: #939393;
    opacity: 0.12;
  }

  @media (min-width: ${breakpoints.lg}) {
    &:after {
      top: 1.2rem;
      left: 0;
      width: 1px;
      height: calc(100% - 2.4rem);
    }
  }
`;

const List = styled(Box)`
  padding-left: 0;
  list-style: none;
`;

const authorActionsStyle = css`
  width: 100%;
  @media (min-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const editLinkStyle = css`
  @media (min-width: ${breakpoints.md}) {
    padding-top: 2rem;
  }
`;

const CollectionLink = styled(Link)`
  color: ${theme.colors.text};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

class SubscriptionFullCard extends Component {
  static propTypes = {
    t: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    schoolSubject: PropTypes.object.isRequired,
    countCollections: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    linkUsers: PropTypes.array,
    editable: PropTypes.bool,
    isPublished: PropTypes.bool,
  };

  render() {
    const {
      t,
      id,
      collections,
      schoolSubject,
      countCollections,
      countCollectionsUnseen,
      linkUsers,
      editable,
      isPublished,
    } = this.props;

    return (
      <Container flexDirection={['column', 'column', 'row', 'row']} justifyContent="space-between">
        <Flex width={[1 / 1, 1 / 1, 1 / 1, 'auto']} flexDirection={['column', 'column', 'column', 'row']}>
          <Box width={[1 / 1, 1 / 1, 1 / 1, '42.8rem']}>
            <Card.Folder
              {...this.props}
              editable={false}
              showSubscribeButton={false}
              css="border-radius: .4rem 0 0 .4rem; box-shadow: none;"
              contentBg={theme.colors.gray100}
              footerBg="transparent"
            />
          </Box>
          <MediaQuery minWidth={breakpoints.lg}>
            <Box width={[1 / 1, 1 / 1, 1 / 1, '42.8rem']} mt={['1.2rem', '1.2rem', '1.2rem', 0]} css={separatorStyle}>
              <Box px={['1.2rem', '1.2rem', '1.2rem', '2rem']} py="2rem">
                <Text as="h2" color={schoolSubject.color} fontSize="1.6rem" fontWeight="semibold">
                  {t('latestSeriesAdded')}
                </Text>
                {collections && collections.results.length > 0 ? (
                  <List as="ul" mt="1.2rem">
                    {collections.results.map((collection, key) => {
                      return (
                        <CollectionLink key={key} to={`/series/${collection.id}`}>
                          <Flex key={collection.title + key} as="li" pb=".4rem" justifyContent="space-between">
                            <Text
                              fontFamily="heading"
                              fontWeight="semibold"
                              fontSize="1.2rem"
                              css="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                            >
                              {collection.title}
                            </Text>
                            <Text as="span" pl="2rem" fontFamily="heading" fontSize="1.2rem" css="flex: 0 0 auto;">
                              {t('cardWithCount', { count: collection.countCards })}
                            </Text>
                          </Flex>
                        </CollectionLink>
                      );
                    })}
                  </List>
                ) : collections && collections.results.length === 0 ? (
                  <Text pt={1} fontSize="1.2rem">
                    {t('noSeriesInThisChain')}
                  </Text>
                ) : (
                  <p>Loading...</p>
                )}
              </Box>
            </Box>
          </MediaQuery>
        </Flex>
        {editable ? (
          <Flex
            width={[1 / 1, 1 / 1, '30rem', '20.4rem']}
            bg="white"
            justifyContent="flex-end"
            css="text-align: center;"
          >
            <Summary
              width={1 / 1}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              p={[0, 0, '1.6rem']}
            >
              <Card.FolderAuthorActions
                id={id}
                isPublished={isPublished}
                cssContainer={authorActionsStyle}
                cssEditLink={editLinkStyle}
              />
            </Summary>
          </Flex>
        ) : (
          <Flex
            width={[1 / 1, 1 / 1, '30rem', '20.4rem']}
            bg="white"
            justifyContent="flex-end"
            css="text-align: center;"
          >
            <Summary width={1 / 1} flexDirection="column" alignItems="center" justifyContent="center" p="1.6rem">
              <Text as="span" fontFamily="heading" fontSize="1.2rem">
                <span
                  dangerouslySetInnerHTML={{ __html: t('serieInTheChainWithCount', { count: countCollections }) }}
                />
                <MediaQuery minWidth={breakpoints.md}>
                  <br />
                </MediaQuery>{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('serieInTheChainAlreadySeenWithCount', {
                      count: countCollections - countCollectionsUnseen,
                    }),
                  }}
                />
              </Text>
              <Text as="span" pt={['1.2rem', '1.2rem', 2]} color="#636363" fontSize="1.2rem">
                <SubscribeButton folder={id} isSubscriber={linkUsers.length > 0 ? true : false} />
              </Text>
            </Summary>
          </Flex>
        )}
      </Container>
    );
  }
}

export default withNamespaces()(SubscriptionFullCard);
