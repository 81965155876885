import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/client/react/components';
import { GET_OFFICIAL_COLLECTIONS } from 'src/queries/collections';
import { GET_OFFICIAL_FOLDERS } from 'src/queries/folders';
import Content from './Content';
import { Redirect } from 'react-router-dom';

class RecommendationsContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;

  render() {
    const { user } = this.props;

    if (!user.schoolDegree) {
      return <Redirect to="/" />;
    }

    /*eslint-disable */
    const queries = {
      officialCollections: ({ render }) => (
        <Query query={GET_OFFICIAL_COLLECTIONS} variables={{ schoolDegree: user.schoolDegree.id }}>
          {render}
        </Query>
      ),
      officialFolders: ({ render }) => (
        <Query query={GET_OFFICIAL_FOLDERS} variables={{ schoolDegree: user.schoolDegree.id }}>
          {render}
        </Query>
      ),
    };

    const Composed = adopt(queries);
    /*eslint-enable */

    return (
      <Composed>
        {({ officialCollections, officialFolders }) => {
          if ((officialCollections.loading || officialFolders.loading) && !this.isContentLoaded) return <div />;
          if (officialCollections.error || officialFolders.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const dataCollections = officialCollections.data;
          const dataFolders = officialFolders.data;

          return <Content dataCollections={dataCollections} dataFolders={dataFolders} />;
        }}
      </Composed>
    );
  }
}

export default RecommendationsContainer;
