import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { GET_COLLECTION } from 'src/queries/collections';
import { LEARNED_STATUS, TO_REVIEW_STATUS } from 'src/components/elements/Status';
import Content from './Content';

class GameContainer extends React.Component {
  static propTypes = {
    collectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.any,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    answers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isPlaying: PropTypes.bool,
    isTeacher: PropTypes.bool,
    oldUserScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    newUserScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  isContentLoaded = null;

  render() {
    const { answers, collectionId, errors, isPlaying, isTeacher, score, time } = this.props;

    return (
      <Query query={GET_COLLECTION} variables={{ id: collectionId, withUserLinkCard: true }}>
        {(collections) => {
          if (collections.loading && !this.isContentLoaded) return <div />;
          if (collections.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          let learnedCards = 0;
          let toReviewCards = 0;
          collections.data.collection.cards.map((card) => {
            if (card.userLinkCard) {
              if (card.userLinkCard.status === TO_REVIEW_STATUS) {
                toReviewCards++;
              } else if (card.userLinkCard.status === LEARNED_STATUS) {
                learnedCards++;
              }
            }
          });

          return (
            <Content
              answers={answers}
              errors={errors}
              isPlaying={isPlaying}
              isTeacher={isTeacher}
              learnedCards={learnedCards}
              score={score}
              time={time}
              toReviewCards={toReviewCards}
            />
          );
        }}
      </Query>
    );
  }
}

export default GameContainer;
