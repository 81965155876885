import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import BadgeSvg from 'assets/svg/badge.svg';
import Title from 'src/components/typography/Title';
import Statistic from 'src/components/elements/Statistic';
import PadlockSvg from 'assets/svg/padlock.svg';

import BadgeDuelsBronzeSvg from 'assets/svg/badges/badge-duels-bronze.svg';
import BadgeDuelsSilverSvg from 'assets/svg/badges/badge-duels-silver.svg';
import BadgeDuelsGoldSvg from 'assets/svg/badges/badge-duels-gold.svg';
import BadgePointsBronzeSvg from 'assets/svg/badges/badge-points-bronze.svg';
import BadgePointsSilverSvg from 'assets/svg/badges/badge-points-silver.svg';
import BadgePointsGoldSvg from 'assets/svg/badges/badge-points-gold.svg';
import {
  SCORE_BRONZE,
  SCORE_SILVER,
  SCORE_GOLD,
  WINNING_DUELS_BRONZE,
  WINNING_DUELS_SILVER,
  WINNING_DUELS_GOLD,
} from 'src/utils/score';

const BadgeIcon = styled(BadgeSvg)`
  ${svgStyle};
  width: 2.8rem;
  height: 2.8rem;
`;

const badgeStyle = css`
  ${svgStyle};
  width: 13rem;
  height: 13rem;
`;

const BadgeDuelsBronzeIcon = styled(BadgeDuelsBronzeSvg)`
  ${badgeStyle};
`;
const BadgeDuelsSilverIcon = styled(BadgeDuelsSilverSvg)`
  ${badgeStyle};
`;
const BadgeDuelsGoldIcon = styled(BadgeDuelsGoldSvg)`
  ${badgeStyle};
`;
const BadgePointsBronzeIcon = styled(BadgePointsBronzeSvg)`
  ${badgeStyle};
`;
const BadgePointsSilverIcon = styled(BadgePointsSilverSvg)`
  ${badgeStyle};
`;
const BadgePointsGoldIcon = styled(BadgePointsGoldSvg)`
  ${badgeStyle};
`;

const PadlockIcon = styled(PadlockSvg)`
  ${svgStyle};
  width: 3rem;
  height: 3rem;
  @media (min-width: ${breakpoints.md}) {
    width: 4.5rem;
    height: 5rem;
  }
`;

class Rewards extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, user } = this.props;

    return (
      <Flex css="flex: 1 1 auto; min-height: 0;" bg="gray100">
        <Box width={1 / 1}>
          <Container bg="white" flex="0 0 auto;">
            <Content>
              <Flex py={2}>
                <Title icon={<BadgeIcon />}>{t('myRewards')}</Title>
              </Flex>
              <Grid pb={4} css="max-width: 65rem; margin: auto;">
                <Col pt={3}>
                  <Box width={1 / 1}>
                    <Text fontWeight="semibold">{t('duel_plural')}</Text>
                    <Grid pt={['1.2rem', '1.2rem', '1.2rem', '2.6rem']}>
                      <Col pb={3} justifyContent="center" width={1 / 3}>
                        <Text
                          textAlign="center"
                          css={`
                            flex: 0 0 7.4rem;
                            @media (min-width: ${breakpoints.md}) {
                              flex: 0 0 13rem;
                            }
                          `}
                        >
                          <Flex css="position: relative;">
                            <Text color="primary">
                              <Box css={user.countWinningDuels < WINNING_DUELS_BRONZE && 'opacity: .4;'}>
                                <BadgeDuelsBronzeIcon />
                              </Box>
                              {user.countWinningDuels < WINNING_DUELS_BRONZE && (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  css="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"
                                >
                                  <PadlockIcon />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                          <Text pt="1.4rem" fontSize={['1.2rem', '1.2rem', '1.2rem', '1.4rem']}>
                            {t('rewards.bronze')}
                          </Text>
                        </Text>
                      </Col>
                      <Col pb={3} justifyContent="center" width={1 / 3}>
                        <Text
                          textAlign="center"
                          css={`
                            flex: 0 0 7.4rem;
                            @media (min-width: ${breakpoints.md}) {
                              flex: 0 0 13rem;
                            }
                          `}
                        >
                          <Flex css="position: relative;">
                            <Text color="primary">
                              <Box css={user.countWinningDuels < WINNING_DUELS_SILVER && 'opacity: .4;'}>
                                <BadgeDuelsSilverIcon />
                              </Box>
                              {user.countWinningDuels < WINNING_DUELS_SILVER && (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  css="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"
                                >
                                  <PadlockIcon />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                          <Text pt="1.4rem" fontSize={['1.2rem', '1.2rem', '1.2rem', '1.4rem']}>
                            {t('rewards.silver')}
                          </Text>
                        </Text>
                      </Col>
                      <Col pb={3} justifyContent="center" width={1 / 3}>
                        <Text
                          textAlign="center"
                          css={`
                            flex: 0 0 7.4rem;
                            @media (min-width: ${breakpoints.md}) {
                              flex: 0 0 13rem;
                            }
                          `}
                        >
                          <Flex css="position: relative;">
                            <Text color="primary">
                              <Box css={user.countWinningDuels < WINNING_DUELS_GOLD && 'opacity: .4;'}>
                                <BadgeDuelsGoldIcon />
                              </Box>
                              {user.countWinningDuels < WINNING_DUELS_GOLD && (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  css="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"
                                >
                                  <PadlockIcon />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                          <Text pt="1.4rem" fontSize={['1.2rem', '1.2rem', '1.2rem', '1.4rem']}>
                            {t('rewards.gold')}
                          </Text>
                        </Text>
                      </Col>
                    </Grid>
                  </Box>
                </Col>
                <Col pt={3}>
                  <Box width={1 / 1}>
                    <Text fontWeight="semibold">{t('point_plural')}</Text>
                    <Grid pt={['1.2rem', '1.2rem', '1.2rem', '2.6rem']}>
                      <Col pb={3} justifyContent="center" width={1 / 3}>
                        <Text
                          textAlign="center"
                          css={`
                            flex: 0 0 7.4rem;
                            @media (min-width: ${breakpoints.md}) {
                              flex: 0 0 13rem;
                            }
                          `}
                        >
                          <Flex css="position: relative;">
                            <Text color="primary">
                              <Box css={user.score < SCORE_BRONZE && 'opacity: .4;'}>
                                <BadgePointsBronzeIcon />
                              </Box>
                              {user.score < SCORE_BRONZE && (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  css="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"
                                >
                                  <PadlockIcon />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                          <Text pt="1.4rem" fontSize={['1.2rem', '1.2rem', '1.2rem', '1.4rem']}>
                            {t('rewards.bronze')}
                          </Text>
                        </Text>
                      </Col>
                      <Col pb={3} justifyContent="center" width={1 / 3}>
                        <Text
                          textAlign="center"
                          css={`
                            flex: 0 0 7.4rem;
                            @media (min-width: ${breakpoints.md}) {
                              flex: 0 0 13rem;
                            }
                          `}
                        >
                          <Flex css="position: relative;">
                            <Text color="primary">
                              <Box css={user.score < SCORE_SILVER && 'opacity: .4;'}>
                                <BadgePointsSilverIcon />
                              </Box>
                              {user.score < SCORE_SILVER && (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  css="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"
                                >
                                  <PadlockIcon />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                          <Text pt="1.4rem" fontSize={['1.2rem', '1.2rem', '1.2rem', '1.4rem']}>
                            {t('rewards.silver')}
                          </Text>
                        </Text>
                      </Col>
                      <Col pb={3} justifyContent="center" width={1 / 3}>
                        <Text
                          textAlign="center"
                          css={`
                            flex: 0 0 7.4rem;
                            @media (min-width: ${breakpoints.md}) {
                              flex: 0 0 13rem;
                            }
                          `}
                        >
                          <Flex css="position: relative;">
                            <Text color="primary">
                              <Box css={user.score < SCORE_GOLD && 'opacity: .4;'}>
                                <BadgePointsGoldIcon />
                              </Box>
                              {user.score < SCORE_GOLD && (
                                <Flex
                                  alignItems="center"
                                  justifyContent="center"
                                  css="position: absolute; top: 0; left: 0; right: 0; bottom: 0;"
                                >
                                  <PadlockIcon />
                                </Flex>
                              )}
                            </Text>
                          </Flex>
                          <Text pt="1.4rem" fontSize={['1.2rem', '1.2rem', '1.2rem', '1.4rem']}>
                            {t('rewards.gold')}
                          </Text>
                        </Text>
                      </Col>
                    </Grid>
                  </Box>
                </Col>
              </Grid>
            </Content>
          </Container>
          <Container flex="0 0 auto;">
            <Content>
              <Flex py={2}>
                <Title icon={<BadgeIcon />}>{t('myStatistics')}</Title>
              </Flex>
              <Grid py={['2rem', '2rem', '4rem']}>
                <Col
                  alignItems="center"
                  justifyContent="center"
                  width={[1 / 1, 1 / 1, 1 / 4]}
                  pb={['10rem', '10rem', '10rem', '20rem']}
                >
                  <Statistic
                    number={user.score}
                    label={t('pointLowercase', { count: user.score })}
                    icon={Statistic.TrophyIcon}
                  />
                </Col>
                <Col
                  alignItems="center"
                  justifyContent="center"
                  width={[1 / 1, 1 / 1, 1 / 4]}
                  pb={['10rem', '10rem', '10rem', '20rem']}
                >
                  <Statistic
                    number={user.countCollections}
                    label={t('gameLowercase', { count: user.countCollections })}
                    icon={Statistic.BrainIcon}
                  />
                </Col>
                <Col
                  alignItems="center"
                  justifyContent="center"
                  width={[1 / 1, 1 / 1, 1 / 4]}
                  pb={['10rem', '10rem', '10rem', '20rem']}
                >
                  <Statistic
                    number={user.countDuels}
                    label={t('duelLowercase', { count: user.countDuels })}
                    icon={Statistic.DuelIcon}
                  />
                </Col>
                <Col
                  alignItems="center"
                  justifyContent="center"
                  width={[1 / 1, 1 / 1, 1 / 4]}
                  pb={['10rem', '10rem', '10rem', '20rem']}
                >
                  <Statistic
                    number={user.countWinningDuels}
                    label={t('victoryLowercase', { count: user.countWinningDuels })}
                    icon={Statistic.BadgeIcon}
                  />
                </Col>
              </Grid>
            </Content>
          </Container>
        </Box>
      </Flex>
    );
  }
}

export default withNamespaces()(Rewards);
