import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { ModalContext } from 'src/context';
import { COLLECTIONS_ORDER_BY } from 'src/queries/collections';
import { GET_FOLDER } from 'src/queries/folders';
import styled, { css } from 'styled-components';

import Chain from 'assets/svg/chain.svg';
import Swiper from 'react-id-swiper';
import { Box, Text } from 'rebass';
import CollectionList from 'src/components/blocks/CollectionList';
import Pagination from 'src/components/blocks/Pagination';
import Tabs from 'src/components/blocks/Tabs';
import Select from 'src/components/elements/Select';
import SubscribeButton from 'src/components/elements/SubscribeButton';
import Title from 'src/components/typography/Title';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';

import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';

const ChainIcon = styled(Chain)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

const tabStyle = css`
  flex: 0 0 auto;
  width: auto;
  padding-right: 2rem;
  padding-left: 2rem;
`;

/* eslint-disable react/display-name */
const SLIDER_PARAMS = {
  freeMode: true,
  freeModeMomentumBounce: false,
  resistanceRatio: 0,
  observer: true,
  slidesPerView: 'auto',
  slideToClickedSlide: true,
  watchOverflow: true,
  navigation: {
    nextEl: '.tabswiper-button-next',
    prevEl: '.tabswiper-button-prev',
  },
  renderPrevButton: () => (
    <Tabs.TabSwiperButton className="tabswiper-button-prev" direction={Tabs.TabSwiperButton.prevDirection} />
  ),
  renderNextButton: () => (
    <Tabs.TabSwiperButton className="tabswiper-button-next" direction={Tabs.TabSwiperButton.nextDirection} />
  ),
};
/* eslint-enable react/display-name */

class Folder extends Component {
  static contextType = ModalContext;
  static propTypes = {
    t: PropTypes.func,
    match: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.isAuthor = false;
    this.state = {
      collectionsOrderBy: 'newest',
      tab: 0,
      filters: this.getFilters(0),
      page: 1,
    };
  }

  getFilters = tabIndex => {
    const { user } = this.props;
    const FILTERS = {
      // All
      0: [],
      // New collections
      1: [{ join: 'linkUsers', property: 'lastSeen', where: 'null' }],
      // Already seen
      2: [{ join: 'linkUsers', property: 'user', where: user.id }],
      // To review
      3: [
        { join: 'linkUsers', property: 'user', where: user.id },
        { join: 'linkUsers', property: 'status', where: "'TO_REVIEW'" },
      ],
    };

    return FILTERS[tabIndex];
  };

  onTabChange = index => {
    this.setState({
      tab: index,
      filters: this.getFilters(index),
      page: 1,
    });
  };

  onSort = event => {
    this.setState({
      collectionsOrderBy: event.target.value,
    });
  };

  onPageChange = page => {
    this.setState({
      page,
    });
  };

  render() {
    const { t, match } = this.props;
    const { tab, collectionsOrderBy, filters, page } = this.state;

    return (
      <Query
        query={GET_FOLDER}
        fetchPolicy="network-only"
        variables={{
          id: match.params.id,
          collectionPage: page,
          collectionsOrderBy: [COLLECTIONS_ORDER_BY[collectionsOrderBy]],
          collectionsFilterBy: filters,
        }}
      >
        {({ error, data }) => {
          if (error) return <p>Error :(</p>;
          if (!data) return (<div />);
          const { folder } = data;
          if (!folder) return <div />;

          const { collections } = folder;

          return (
            <Container>
              <Content mt={1}>
                <Grid justifyContent="space-between">
                  <Col>
                    <Grid>
                      <Col pb="1rem">
                        <Title as="h1" icon={<ChainIcon />} medium>
                          {folder.title}
                        </Title>
                      </Col>
                      <Col alignItems="center" pb="1rem">
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="1.2rem"
                          fontWeight="semibold"
                          css="text-transform: uppercase;"
                        >
                          {t('byAuthor', { val: `${folder.author.firstname} ${folder.author.lastname}` })}
                        </Text>
                      </Col>
                    </Grid>
                  </Col>
                  <Col alignItems="center" justifyContent="flex-end" pb="3rem">
                    <Grid justifyContent="flex-end">
                      <Col>
                        <Box width={1 / 1}>
                          <Text
                            as="label"
                            id="subject"
                            pb=".4rem"
                            color="selectLabel"
                            fontSize="1.2rem"
                            css="display: block;"
                          >
                            {t('sortBy')}
                          </Text>
                          <Select
                            name="sort"
                            onChange={this.onSort}
                            value={collectionsOrderBy}
                            options={[
                              { id: 'newest', name: t('filters.newest') },
                              { id: 'oldest', name: t('filters.oldest') },
                              { id: 'alphabetical', name: t('filters.alphabetical') },
                              { id: 'reverseAlphabetical', name: t('filters.reverseAlphabetical') },
                            ]}
                          />
                        </Box>
                      </Col>
                      <Col pt="2rem">
                        <SubscribeButton folder={folder.id} isSubscriber={folder.linkUsers.length > 0 ? true : false} />
                      </Col>
                    </Grid>
                  </Col>
                </Grid>
                {collections && (
                  <Tabs onSelect={() => { }} selectedIndex={tab}>
                    <Box pt={2} css="position: relative; .swiper-container { overflow: initial; }">
                      <Tabs.TabList
                        css={`
                          display: block;
                          @media (min-width: ${breakpoints.md}) {
                            display: block;
                          }
                        `}
                      >
                        <Swiper
                          {...SLIDER_PARAMS}
                          ref={node => {
                            if (node) this.swiper = node.swiper;
                          }}
                        >
                          <Tabs.Tab as="div" css={tabStyle} onClick={() => this.onTabChange(0)}>
                            {t('allTheSeries')}
                          </Tabs.Tab>
                          <Tabs.Tab as="div" css={tabStyle} onClick={() => this.onTabChange(1)}>
                            {t('newSerie_plural')}
                          </Tabs.Tab>
                          <Tabs.Tab as="div" css={tabStyle} onClick={() => this.onTabChange(2)}>
                            {t('serieAlreadySeen_plural')}
                          </Tabs.Tab>
                          <Tabs.Tab as="div" css={tabStyle} onClick={() => this.onTabChange(3)}>
                            {t('toReview')}
                          </Tabs.Tab>
                        </Swiper>
                      </Tabs.TabList>
                    </Box>
                    <Tabs.TabPanel>
                      <Box as="section" pt={1}>
                        <CollectionList data={collections.results} />
                      </Box>
                    </Tabs.TabPanel>
                    <Tabs.TabPanel>
                      <Box as="section" pt={1}>
                        <CollectionList data={collections.results} />
                      </Box>
                    </Tabs.TabPanel>
                    <Tabs.TabPanel>
                      <Box as="section" pt={1}>
                        <CollectionList data={collections.results} />
                      </Box>
                    </Tabs.TabPanel>
                    <Tabs.TabPanel>
                      <Box as="section" pt={1}>
                        <CollectionList data={collections.results} />
                      </Box>
                    </Tabs.TabPanel>
                  </Tabs>
                )}
                {collections &&
                  collections.count > Pagination.limit && (
                    <Pagination total={collections.count} page={page} onPageChange={this.onPageChange} />
                  )}
              </Content>
            </Container>
          );
        }}
      </Query>
    );
  }
}

export default withNamespaces()(Folder);
