import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { GET_FOLDERS } from 'src/queries/folders';
import { COLLECTIONS_ORDER_BY } from 'src/queries/collections';
import { GET_SUBJECTS_OF_THE_FOLDERS_TO_WHICH_THE_USER_IS_SUBSCRIBED } from 'src/queries/subjects';
import Content from './Content';

const DEFAULT_SELECT_FILTER = '';

class SubsciptionsContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    orderBy: 'newest',
    filterBy: DEFAULT_SELECT_FILTER,
  };

  generateFilters(user, filterBy) {
    const defaultFilter = [{ join: 'linkUsers', property: 'user', where: user.id }];
    const filters =
      filterBy !== DEFAULT_SELECT_FILTER
        ? defaultFilter.concat([{ join: 'schoolSubject', property: 'id', where: filterBy }])
        : defaultFilter;

    return filters;
  }

  handleFilterChange = (event) => {
    this.setState({
      filterBy: event.target.value,
    });
  };

  handleSortChange = (event) => {
    this.setState({
      orderBy: event.target.value,
    });
  };

  render() {
    const { user } = this.props;
    const { orderBy, filterBy } = this.state;

    return (
      <Query
        query={GET_FOLDERS}
        fetchPolicy='network-only'
        variables={{
          filterBy: this.generateFilters(user, filterBy),
          withCollections: true,
          orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
        }}
      >
        {(folders) => (
          <Query query={GET_SUBJECTS_OF_THE_FOLDERS_TO_WHICH_THE_USER_IS_SUBSCRIBED}>
            {(schoolSubjects) => {
              if ((folders.loading || schoolSubjects.loading) && !this.isContentLoaded) return <div />;
              if (folders.error || schoolSubjects.error) return <p>Error :(</p>;
              this.isContentLoaded = true;

              return (
                <Content
                  handleFilterChange={this.handleFilterChange}
                  handleSortChange={this.handleSortChange}
                  filterBy={filterBy}
                  folders={folders}
                  orderBy={orderBy}
                  schoolSubjects={schoolSubjects}
                />
              );
            }}
          </Query>
        )}
      </Query>
    );
  }
}

export default SubsciptionsContainer;
