import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { Box, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { breakpoints } from 'src/styles/theme';

const Container = styled(Box)`
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);

  @media (min-width: ${breakpoints.md}) {
    border-radius: 0.7rem;
  }
`;

function renderRow(label, value) {
  const hasValue = value !== null;
  const newValue = hasValue ? value : '?';

  return (
    <Col
      justifyContent="center"
      width={[1 / 1, 1 / 1, 1 / 2]}
      pt={['1rem', '1rem', '1.8rem']}
      pb={['1rem', '1rem', '1.8rem']}
    >
      <Text textAlign="center">
        <Text pb={['.6rem', '.6rem', '1.4rem']} fontSize={['1.4rem', '1.4rem', '1.6rem']} fontWeight="semibold">
          {label}
        </Text>
        <Text
          color={hasValue ? 'secondary' : 'others'}
          fontSize={['1.4rem', '1.4rem', '2rem']}
          fontWeight="bold"
          dangerouslySetInnerHTML={{ __html: newValue }}
        />
      </Text>
    </Col>
  );
}

class DuelScores extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    data: PropTypes.object,
  };

  render() {
    const { t, data } = this.props;
    const challengerScore = data.duel.scoreChallenger;
    const opponentScore = data.duel.scoreOpponent;

    return (
      <Grid alignItems={['flex-start', 'flex-start', 'center']} flex="1 1 auto;">
        <Col width={1 / 2}>
          <Container width={1 / 1} pt={['2rem', '2rem', '1rem']} pb={['.4rem', '.4rem', 0]} bg="white">
            <Grid flex="1 1 auto;">
              {renderRow(
                'Chrono',
                data.duel.timeChallenger ? moment.utc(data.duel.timeChallenger * 1000).format('HH:mm:ss') : null
              )}
              {renderRow(
                t('earnedPoint', { count: challengerScore || 0 }),
                challengerScore !== null ? t('pointWithCount', { count: challengerScore }) : null
              )}
              {renderRow(t('rightAnswer', { count: data.duel.answersChallenger }), data.duel.answersChallenger)}
              {renderRow(t('error', { count: data.duel.errorsChallenger }), data.duel.errorsChallenger)}
            </Grid>
          </Container>
        </Col>
        <Col width={1 / 2}>
          <Container width={1 / 1} pt={['2rem', '2rem', '1rem']} pb={['.4rem', '.4rem', 0]} bg="white">
            <Grid flex="1 1 auto;">
              {renderRow(
                'Chrono',
                data.duel.timeOpponent ? moment.utc(data.duel.timeOpponent * 1000).format('HH:mm:ss') : null
              )}
              {renderRow(
                t('earnedPoint', { count: opponentScore || 0 }),
                opponentScore !== null ? t('pointWithCount', { count: opponentScore }) : null
              )}
              {renderRow(t('rightAnswer', { count: data.duel.answersOpponent }), data.duel.answersOpponent)}
              {renderRow(t('error', { count: data.duel.errorsOpponent }), data.duel.errorsOpponent)}
            </Grid>
          </Container>
        </Col>
      </Grid>
    );
  }
}

export default withNamespaces()(DuelScores);
