import { Mutation, Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { adopt } from 'react-adopt';
import { Redirect } from 'react-router-dom';

import { ModalContext, UserContext } from 'src/context';
import { ADD_COLLECTION, GET_COLLECTION, REMOVE_COLLECTION } from 'src/queries/collections';
import { GET_DEGREES } from 'src/queries/degrees';
import { GET_FOLDERS } from 'src/queries/folders';

import { UPLOAD_FILE } from 'src/queries/files';
import { canEditCollection } from 'src/utils/acl';
import { handleSubmissionErrors } from 'src/utils/form';
import Content from './Content';

const withUploadMutation = graphql(UPLOAD_FILE);

@withUploadMutation
class CreateCollection extends PureComponent {
  static contextType = ModalContext;
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    mutate: PropTypes.func,
  };

  Composed = null;
  isContentLoaded = false;
  state = {
    modalCreateFolderIsOpen: false,
    modalRecordIsOpen: false,
    recording: false,
    record: null,
  };

  static formatFormValues(values) {
    const formattedData = {};
    if (values.schoolSubject) {
      formattedData.schoolSubject = { id: values.schoolSubject };
    }

    if (values.schoolDegrees) {
      formattedData.schoolDegrees = values.schoolDegrees.map(schoolDegree => ({ id: schoolDegree.value }));
    }

    if (values.folders) {
      formattedData.folders = values.folders.map(folder => ({ id: folder.value }));
    }

    if (values.cards) {
      const formattedCards = values.cards
        .filter(card =>
          card.frontFace?.text || card.frontFace?.image || card.frontFace?.audio ||
          card.backFace?.text || card.backFace?.image || card.backFace?.audio ||
          card.backFaces?.some(face => face.text || face.image || face.audio)
        )
        .map((card, position) => {
          const formattedCard = { ...card };
          // Clear backFaces for non-multiple response cards
          if (card.type !== 'MULTIPLE_RESPONSE') {
            formattedCard.backFaces = [];
          }
          // Clear backFace for non-single response cards
          if (card.type !== 'SINGLE_RESPONSE') {
            formattedCard.backFace = null;
          }
          return { position, ...formattedCard };
        });
      formattedData.cards = formattedCards;
    }

    return { ...values, ...formattedData };
  }

  onSubmit = (data, addCollection) => {
    const { history } = this.props;
    return addCollection({
      variables: {
        input: CreateCollection.formatFormValues(data),
      },
    }).then(({ data: { AddCollection } }) => {
      const historyState = history.location.state;
      if (historyState?.isFirstSplit && historyState?.secondAddCollectionId) {
        history.push(`/series/${historyState.secondAddCollectionId}/editer`, { isSecondSplit: true });

      } else {
        history.push(`/series/${AddCollection.id}`);
      }
    })
      .catch(errors => handleSubmissionErrors(errors));
  };

  upload = uploadMutation => ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    return validity.valid && uploadMutation({ variables: { file } });
  };

  toggleRecording = () => {
    const recording = !this.state.recording;
    this.setState({
      recording: !this.state.recording,
      record: recording ? null : this.state.record,
    });
  };

  closeRecording = () => {
    this.setState({
      modalRecordIsOpen: false,
      recordFieldName: null,
      record: false,
      recording: false,
    });
  };

  setRecordFieldName = recordFieldName => {
    this.setState({
      modalRecordIsOpen: true,
      recordFieldName,
    });
  };

  setRecord = record => {
    this.setState({
      record,
    });
  };

  normalizeCollection(apiData) {
    return {
      id: parseInt(apiData.id, 10),
      title: apiData.title,
      description: apiData.description,
      isPublished: apiData.isPublished,
      schoolSubject: apiData.schoolSubject.id,
      schoolDegrees: apiData.schoolDegrees.map(degree => ({ value: degree.id, label: degree.name })),
      folders: apiData.folders.map(folder => ({ value: folder.id, label: folder.title })),
      cards: apiData.cards.map(card => ({
        ...card, __typename: undefined,
        frontFace: { ...card.frontFace, __typename: undefined },
        backFace: { ...card.backFace, __typename: undefined },
        backFaces: card.backFaces?.map(face => ({ ...face, __typename: undefined })),
      })),
      assoMode: apiData.assoMode,
      memoMode: apiData.memoMode,
      typoMode: apiData.typoMode,
      brioMode: apiData.brioMode,
      quizakoMode: apiData.quizakoMode,
      ignoreCase: apiData.ignoreCase,
      ignoreOrder: apiData.ignoreOrder,
      ignoreSpaces: apiData.ignoreSpaces,
      ignoreCharacters: apiData.ignoreCharacters,
    };
  }

  openModalCreateFolder = () => {
    this.setState({
      modalCreateFolderIsOpen: true,
    });
  };

  closeModalCreateFolder = () => {
    this.setState({
      modalCreateFolderIsOpen: false,
    });
  };

  render() {
    const { match, mutate, history } = this.props;
    const { record, recording, recordFieldName, modalCreateFolderIsOpen, modalRecordIsOpen } = this.state;
    const { toggleModal } = this.context;
    const editMode = !!match.params.id;

    /*eslint-disable */
    const queries = {
      user: ({ render }) => <UserContext.Consumer>{render}</UserContext.Consumer>,
      degrees: ({ render }) => <Query query={GET_DEGREES}>{render}</Query>,
      folders: ({ render, user }) => (
        <Query
          query={GET_FOLDERS}
          fetchPolicy="network-only"
          variables={{
            limit: 1000,
            filterBy: [{ property: 'author', where: user.id }],
          }}
        >
          {render}
        </Query>
      ),
      addCollection: ({ render }) => <Mutation mutation={ADD_COLLECTION}>{render}</Mutation>,
    };

    if (editMode) {
      queries.collection = ({ render }) => (
        <Query query={GET_COLLECTION} variables={{ id: match.params.id }}>
          {render}
        </Query>
      );
      queries.removeCollection = ({ render }) => (
        <Mutation
          mutation={REMOVE_COLLECTION}
          update={cache => {
            Object.keys(cache.data.data).forEach(key => key.match(/^Collection/) && cache.data.delete(key));
          }}
        >
          {render}
        </Mutation>
      );
    }

    if (!this.Composed) {
      this.Composed = adopt(queries);
    }
    /*eslint-enable */

    return (
      <this.Composed>
        {({ degrees, addCollection, removeCollection, collection, folders, user }) => {
          if (editMode) {
            if ((collection.loading || removeCollection.loading || user.loading) && !this.isContentLoaded)
              return <div />;
            if (collection.error || removeCollection.error || user.error) return <p>Error :(</p>;
            if (
              (match.params.id && !collection.data.collection) ||
              (!!collection.data.collection && !canEditCollection(collection.data.collection, user))
            ) {
              return <Redirect to="/" />;
            }
          }

          if ((degrees.loading || addCollection.loading || folders.loading) && !this.isContentLoaded) return <div />;
          if (degrees.error || addCollection.error || folders.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <Content
              closeRecording={this.closeRecording}
              normalizeCollection={this.normalizeCollection}
              onSubmit={this.onSubmit}
              openModalCreateFolder={this.openModalCreateFolder}
              closeModalCreateFolder={this.closeModalCreateFolder}
              setRecord={this.setRecord}
              setRecordFieldName={this.setRecordFieldName}
              toggleModal={toggleModal}
              toggleRecording={this.toggleRecording}
              addCollection={addCollection}
              collection={collection}
              degrees={degrees}
              editMode={editMode}
              folders={folders}
              modalCreateFolderIsOpen={modalCreateFolderIsOpen}
              modalRecordIsOpen={modalRecordIsOpen}
              mutate={mutate}
              record={record}
              recording={recording}
              recordFieldName={recordFieldName}
              subjects={user.schoolSubjects}
              removeCollection={removeCollection}
              collectionId={match.params.id}
              history={history}
              user={user}
            />
          );
        }}
      </this.Composed>
    );
  }
}

export default CreateCollection;
