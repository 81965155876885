import { hasAdminRole, hasRefRole } from './roles';

export function canEditCollection(collection, user) {
  return collection.author.id === user.id || hasRefRole(user);
}

export function canDeleteCollection(collection, user) {
  return collection.author.id === user.id || hasAdminRole(user);
}

export function canEditFolder(folder, user) {
  return folder.author.id === user.id;
}
