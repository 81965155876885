import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import some from 'lodash.some';
import { Query } from '@apollo/client/react/components';
import { USERS } from 'src/queries/users';

import { Box, Flex, Text } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { Button, LinkButton } from 'src/components/elements/Button';
import FriendItem from 'src/components/blocks/FriendItem';
import Input from 'src/components/elements/InputSearchbar';
import { SearchIconBtn, SearchIcon } from 'src/pages/Onboarding/Icons';

class AddFriends extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    addFriend: PropTypes.func.isRequired,
    removeFriend: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    search: PropTypes.string,
    showBackToProfileButton: PropTypes.bool,
    searchOnlyStudent: PropTypes.bool,
    updateUser: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    currentAvatar: PropTypes.string,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      addFriend,
      removeFriend,
      onSearchChange,
      search,
      showBackToProfileButton,
      searchOnlyStudent,
      updateUser,
      currentUser,
      currentAvatar,
    } = this.props;

    return (
      <Box width={1 / 1} bg="gray100">
        <Container bg="white" css="flex: 0 0 auto; padding-bottom: 3rem;">
          <Content pt={3} pb={2}>
            <Text fontSize={['1.6rem', '1.6rem', '2.4rem']} fontWeight="medium" textAlign="center">
              {t('inviteFriends')}
            </Text>
            <Box
              as="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              pt={[3, 3, 4]}
              css="width: 100%; max-width: 43rem; margin: auto;"
            >
              <Text
                as="label"
                htmlFor="search"
                fontSize={['1.4rem', '1.4rem', '1.2rem']}
                fontWeight={['semibold', 'semibold', 'normal']}
              >
                {t('searchAFriend')}
              </Text>
              <Flex alignItems="center" pt={1} css="width: 100%;">
                <Input
                  id="search"
                  sm
                  css="flex: 1 1 100%; margin-right: 1rem"
                  placeholder={t('studentId')}
                  value={search}
                  onChange={onSearchChange}
                />
                <Button secondary smIcon css="flex: 0 0 auto;" type="submit">
                  <SearchIconBtn />
                </Button>
              </Flex>
            </Box>
          </Content>
        </Container>
        <Container bg="gray100">
          <Content py={3}>
            {search !== '' && (
              <Query
                query={USERS}
                variables={{
                  filterBy: [
                    { property: 'firstname', where: search },
                    { property: 'lastname', where: search },
                    { property: 'username', where: search },
                  ],
                  onlyStudent: searchOnlyStudent,
                }}
              >
                {({ data }) => {
                  return (
                    <Box pb="6rem">
                      <Text color="primary" fontWeight="semibold">
                        {t('resultWithCount', { count: data && data.users ? data.users.results.length : 0 })}
                      </Text>
                      {data &&
                        data.users && (
                          <Grid py={2}>
                            {data.users &&
                              data.users.results.map((user) => (
                                <Col key={`search${user.id}`} width={[1 / 1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} pb={1}>
                                  <FriendItem
                                    friend={user}
                                    disabled={some(currentUser.friends, { id: user.id })}
                                    onAddFriend={addFriend(currentUser, user, updateUser)}
                                  />
                                </Col>
                              ))}
                          </Grid>
                        )}
                    </Box>
                  );
                }}
              </Query>
            )}
            <Box>
              <Text color="primary" fontWeight="semibold">
                {t('myFriends')} ({currentUser.friends.length})
              </Text>
              {currentUser.friends.length > 0 && (
                <Grid py={2}>
                  {currentUser.friends.map((friend) => (
                    <Col key={friend.id} width={[1 / 1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} pb={1}>
                      <FriendItem
                        friend={friend}
                        isAdded={true}
                        onRemoveFriend={removeFriend(currentUser, friend, updateUser)}
                      />
                    </Col>
                  ))}
                </Grid>
              )}
              {currentUser.friends.length === 0 && (
                <Box py="6rem" css="max-width: 54rem; margin: auto;">
                  <Text pb={4} color="primary" textAlign="center" css="opacity: .3;">
                    <SearchIcon />
                  </Text>
                  <Text pb={2} fontSize={['1.4rem', '1.4rem', '2rem']} textAlign="center">
                    {t('noFriends')}
                  </Text>
                  <Text fontSize={['1.2rem', '1.2rem', '1.4rem']} fontWeight="semibold" textAlign="center">
                    {t('noFriendsWarning')}
                  </Text>
                </Box>
              )}
            </Box>

            {showBackToProfileButton && (
              <Flex pt="5rem" pb={3} justifyContent="center">
                <LinkButton
                  to={{ pathname: '/', state: { currentAvatar: currentAvatar } }}
                  css="width: 100%; max-width: 22rem;"
                >
                  {t('backToProfile')}
                </LinkButton>
              </Flex>
            )}
          </Content>
        </Container>
      </Box>
    );
  }
}

export default withNamespaces()(AddFriends);
