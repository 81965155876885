import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Grid, Col } from 'src/components/utilities/Grids';
import { Button } from 'src/components/elements/Button';
import Modal from 'src/components/elements/Modal';
import Settings from 'src/components/games/Settings';
import { DUEL_MODE, GAME_ASSO, GAME_BRIO, GAME_MEMO, GAME_QUIZAKO, GAME_TYPO, games, SOLO_MODE } from 'src/utils/games';

class TutorialLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    history: PropTypes.object,
    badge: PropTypes.element,
    onPlay: PropTypes.func,
    id: PropTypes.string.isRequired,
    game: PropTypes.oneOf(games).isRequired,
    onCloseModal: PropTypes.func,
  };

  state = {
    settingsModalIsOpen: false,
    mode: SOLO_MODE,
  };

  onDifficultyChange = difficulty => {
    const { history, id, game, onPlay } = this.props;
    const { mode } = this.state;

    if (mode === DUEL_MODE) {
      history.push(`/series/${id}/provoke`, { game: game, difficulty: difficulty });
    } else {
      onPlay(difficulty);
    }
  };

  onModeChange = e => {
    this.setState({ mode: e.target.value });
  };

  closeModal = () => {
    this.props.onCloseModal();
  };

  render() {
    const { children, badge } = this.props;
    const { settingsModalIsOpen } = this.state;

    return (
      <React.Fragment>
        <Modal isOpen={settingsModalIsOpen} onRequestClose={this.closeModal}>
          <Settings onDifficultyChange={this.onDifficultyChange} onModeChange={this.onModeChange} />
        </Modal>
        <Box css="flex: 1 1 auto">
          <Box bg="gray200">
            <Container>
              <Content>
                <Grid pt={[1]} pb={[3, 3, 3, 0]} css="gap: 1rem;">
                  <Col flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 14rem']}>{badge}</Col>
                  <Col
                    flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 1 auto']}
                    alignItems="center"
                    justifyContent="center"
                    pb={[1, 1, 0]}
                  >
                    <Box>
                      <Button md onClick={() => this.setState({ settingsModalIsOpen: true })}>
                        Commencer
                      </Button>
                    </Box>
                  </Col>
                  <Col flex={['0 0 100%', '0 0 100%', '0 0 14rem']} />
                </Grid>
              </Content>
            </Container>
          </Box>
          {children}
        </Box>
      </React.Fragment>
    );
  }
}

export default TutorialLayout;
