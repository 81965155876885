import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { Button } from 'src/components/elements/Button';
import Mode from 'src/components/games/Settings/Mode';
import { DuelIcon, SoloIcon } from './Icons';
import { SOLO_MODE, DUEL_MODE, SOFT_DIFFICULTY, NORMAL_DIFFICULTY, HARD_DIFFICULTY } from 'src/utils/games';
import { hasTeacherRole } from 'src/utils/roles';

class Settings extends PureComponent {
  static contextType = UserContext;
  static propTypes = {
    t: PropTypes.func,
    onDifficultyChange: PropTypes.func,
    onModeChange: PropTypes.func,
  };

  render() {
    const { t, onDifficultyChange, onModeChange } = this.props;
    const isTeacher = hasTeacherRole(this.context);

    return (
      <Box pt={3} pb={2} px={[1, 2, 2]}>
        <Text as="h4" fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
          {isTeacher ? t('yourGameModePoliteForm') : t('selectYourGameMode')}
        </Text>
        <Box pt={['1.2rem', '1.2rem', '2.2rem']} css="max-width: 43rem; margin: auto;">
          <Grid justifyContent="center">
            <Col width={1 / 2}>
              <Mode
                name="mode"
                value={SOLO_MODE}
                checked={true}
                onChange={onModeChange}
                icon={<SoloIcon />}
                label="Solo"
              />
            </Col>
            <Col width={1 / 2}>
              <Mode name="mode" value={DUEL_MODE} onChange={onModeChange} icon={<DuelIcon />} label="Duel" />
            </Col>
          </Grid>
        </Box>
        <Text as="h4" pt={2} fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
          {t('difficulty')}
        </Text>
        <Grid pt="1.8rem" justifyContent="center">
          <Col width={[1 / 1, 3 / 4, 1 / 3]} pb={2}>
            <Button css="width: 100%;" onClick={() => onDifficultyChange(SOFT_DIFFICULTY)}>
              {t('easy')}
            </Button>
          </Col>
          <Col width={[1 / 1, 3 / 4, 1 / 3]} pb={2}>
            <Button css="width: 100%;" onClick={() => onDifficultyChange(NORMAL_DIFFICULTY)}>
              {t('medium')}
            </Button>
          </Col>
          <Col width={[1 / 1, 3 / 4, 1 / 3]} pb={2}>
            <Button css="width: 100%;" onClick={() => onDifficultyChange(HARD_DIFFICULTY)}>
              {t('hard')}
            </Button>
          </Col>
        </Grid>
      </Box>
    );
  }
}

export default withNamespaces()(Settings);
