import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { GET_CURRENT_USER } from 'src/queries/users';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import styled, { css } from 'styled-components';
import DuelHeader from './DuelHeader';
import DuelScores from './DuelScores';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';

import BadgeDuelsBronzeSvg from 'assets/svg/badges/badge-duels-bronze.svg';
import BadgeDuelsSilverSvg from 'assets/svg/badges/badge-duels-silver.svg';
import BadgeDuelsGoldSvg from 'assets/svg/badges/badge-duels-gold.svg';
import { WINNING_DUELS_BRONZE, WINNING_DUELS_SILVER, WINNING_DUELS_GOLD } from 'src/utils/score';
import { hasTeacherRole } from 'src/utils/roles';

const badgeStyle = css`
  ${svgStyle};
  width: 13rem;
  height: 13rem;
`;

const BadgeDuelsBronzeIcon = styled(BadgeDuelsBronzeSvg)`
  ${badgeStyle};
`;
const BadgeDuelsSilverIcon = styled(BadgeDuelsSilverSvg)`
  ${badgeStyle};
`;
const BadgeDuelsGoldIcon = styled(BadgeDuelsGoldSvg)`
  ${badgeStyle};
`;

const iconStyle = css`
  display: flex;
  align-items: center;
  min-height: 8.2rem;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  @media (min-width: ${breakpoints.md}) {
    min-height: 10.5rem;
  }
`;

const DuelIcon = css`
  ${iconStyle} background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.94 17'%3E%3Ctitle%3Eduel%3C/title%3E%3Cpath d='M18.45,14a1.63,1.63,0,0,0-1.1-.5c-.1,0-.2,0-.2-.1l-1.7-1.9.1-.1h0l.9-1a.49.49,0,1,0-.7-.7l-.5.6-3.1-3.4a.3.3,0,0,1,0-.4l3-3.3a.35.35,0,0,1,.1-.2l.5-2.3a.87.87,0,0,0-.1-.5.43.43,0,0,0-.5-.1l-2.3.4a.3.3,0,0,1-.2.1L9.75,3.8a.45.45,0,0,1-.5,0L6.35.6a.37.37,0,0,0-.3-.1L4,0a.87.87,0,0,0-.5.1c-.1.2-.2.3-.2.5l.5,2.3a.35.35,0,0,0,.1.2l3,3.3a.3.3,0,0,1,0,.4l-3.1,3.4-.5-.6a.49.49,0,1,0-.7.7l.9,1h0v.2l-1.7,1.9c-.1,0-.1.1-.2.1a1.63,1.63,0,0,0-1.1.5,2.08,2.08,0,0,0,0,2.5,1.69,1.69,0,0,0,2.39,0h0a1.85,1.85,0,0,0,.5-1.2c0-.1,0-.2.1-.2l1.7-1.8h0l.9,1a.52.52,0,0,0,.4.2.37.37,0,0,0,.3-.1.49.49,0,0,0,0-.68l0,0-.6-.7,3.1-3.4a.45.45,0,0,1,.5,0l3.1,3.4-.7.5a.49.49,0,0,0,0,.68l0,0a.37.37,0,0,0,.3.1.76.76,0,0,0,.4-.2l.9-1h0l1.7,1.8c.1.1.1.1.1.2a1.85,1.85,0,0,0,.5,1.2,1.69,1.69,0,0,0,2.39,0h0a1.51,1.51,0,0,0,.16-2.14A1.21,1.21,0,0,0,18.45,14ZM2.05,15.8a.56.56,0,0,1-.78.12l-.12-.12a.78.78,0,0,1,0-1.1.56.56,0,0,1,.5-.3c.2,0,.3.1.5.2A1,1,0,0,1,2.05,15.8ZM3,14.1a.09.09,0,0,0-.1-.1h0c0-.1-.1-.1-.2-.1l1.5-1.6.2.2ZM4.75,2.6l-.3-1.4,1.2.3,2.1,2.3a1.45,1.45,0,0,1,0,2.05l0,.05h0Zm8.5-1.1,1.2-.3-.3,1.4-8.7,9.5h0l-.9-1h0l-.1-.1Zm-2.1,5.9,3.3,3.6-.1.1h0l-.9,1h0l-2.3-2.6a1.45,1.45,0,0,1-.05-2Zm3.4,5,.2-.2,1.5,1.6c-.1,0-.1.1-.2.1a.09.09,0,0,1-.1.1h0Zm3.2,3.4a.56.56,0,0,1-.78.12.68.68,0,0,1-.12-.12.78.78,0,0,1,0-1.1.72.72,0,0,1,.5-.2c.2,0,.3.1.5.2h0A.9.9,0,0,1,17.75,15.8Z' transform='translate(-0.03 0)' style='fill:%23e6e7eb'/%3E%3C/svg%3E");
`;

const WonIcon = css`
  ${iconStyle} background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.94 19.2'%3E%3Ctitle%3Ebadge%3C/title%3E%3Cpath d='M9.48,3.1A3.44,3.44,0,0,0,6,6.4a3.51,3.51,0,0,0,7,0A3.44,3.44,0,0,0,9.48,3.1Zm0,5.7A2.44,2.44,0,0,1,7,6.5a2.51,2.51,0,0,1,5,0A2.44,2.44,0,0,1,9.48,8.8Z' transform='translate(0 0.2)' style='fill:%23d8f1f2'/%3E%3Cpath d='M18.88,15.3l-3.1-6.1a1.1,1.1,0,0,1,0-.8,6.47,6.47,0,0,0,.3-2,6.6,6.6,0,1,0-13.2,0,6.47,6.47,0,0,0,.3,2,1.1,1.1,0,0,1,0,.8L.08,15.3a.45.45,0,0,0,0,.5c.1.2.3.2.5.2l3.7-.6a.72.72,0,0,1,.5.2l1.7,3.1a.56.56,0,0,0,.4.3h0c.2,0,.4-.1.4-.3l1.9-3.8a.21.21,0,0,1,.4,0l1.9,3.8a.56.56,0,0,0,.4.3h0c.2,0,.3-.1.4-.3L14,15.6a.37.37,0,0,1,.48-.21h0l3.7.6c.2,0,.4,0,.5-.2C19,15.7,19,15.5,18.88,15.3ZM7.08,17.2a.21.21,0,0,1-.4,0l-1.4-2.6a.56.56,0,0,0-.5-.3l-2.9.5c-.2,0-.3-.2-.2-.3l2.2-4.2a.28.28,0,0,1,.5,0A6.65,6.65,0,0,0,8,12.6h.1c.4.1.6.1.8.5h0a1.51,1.51,0,0,1-.3,1.4Zm2.4-5.4a5.49,5.49,0,0,1-5.6-5.38v0A5.49,5.49,0,0,1,9.46,1h0a5.49,5.49,0,0,1,5.6,5.38v0A5.49,5.49,0,0,1,9.5,11.8Zm7.7,3-2.9-.5a.56.56,0,0,0-.5.3l-1.4,2.5a.21.21,0,0,1-.4,0l-2-4c-.1-.2,0-.4.2-.4a6.65,6.65,0,0,0,4.6-2.5.28.28,0,0,1,.5,0l2.2,4.2C17.48,14.7,17.28,14.9,17.18,14.8Z' transform='translate(0 0.2)' style='fill:%23d8f1f2'/%3E%3C/svg%3E");
`;

const LostIcon = css`
  ${iconStyle} background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.94 19.2'%3E%3Ctitle%3Ebadge%3C/title%3E%3Cpath d='M9.48,3.1A3.44,3.44,0,0,0,6,6.4a3.51,3.51,0,0,0,7,0A3.44,3.44,0,0,0,9.48,3.1Zm0,5.7A2.44,2.44,0,0,1,7,6.5a2.51,2.51,0,0,1,5,0A2.44,2.44,0,0,1,9.48,8.8Z' transform='translate(0 0.2)' style='fill:%23f3e0e3'/%3E%3Cpath d='M18.88,15.3l-3.1-6.1a1.1,1.1,0,0,1,0-.8,6.47,6.47,0,0,0,.3-2,6.6,6.6,0,1,0-13.2,0,6.47,6.47,0,0,0,.3,2,1.1,1.1,0,0,1,0,.8L.08,15.3a.45.45,0,0,0,0,.5c.1.2.3.2.5.2l3.7-.6a.72.72,0,0,1,.5.2l1.7,3.1a.56.56,0,0,0,.4.3h0c.2,0,.4-.1.4-.3l1.9-3.8a.21.21,0,0,1,.4,0l1.9,3.8a.56.56,0,0,0,.4.3h0c.2,0,.3-.1.4-.3L14,15.6a.37.37,0,0,1,.48-.21h0l3.7.6c.2,0,.4,0,.5-.2C19,15.7,19,15.5,18.88,15.3ZM7.08,17.2a.21.21,0,0,1-.4,0l-1.4-2.6a.56.56,0,0,0-.5-.3l-2.9.5c-.2,0-.3-.2-.2-.3l2.2-4.2a.28.28,0,0,1,.5,0A6.65,6.65,0,0,0,8,12.6h.1c.4.1.6.1.8.5h0a1.51,1.51,0,0,1-.3,1.4Zm2.4-5.4a5.49,5.49,0,0,1-5.6-5.38v0A5.49,5.49,0,0,1,9.46,1h0a5.49,5.49,0,0,1,5.6,5.38v0A5.49,5.49,0,0,1,9.5,11.8Zm7.7,3-2.9-.5a.56.56,0,0,0-.5.3l-1.4,2.5a.21.21,0,0,1-.4,0l-2-4c-.1-.2,0-.4.2-.4a6.65,6.65,0,0,0,4.6-2.5.28.28,0,0,1,.5,0l2.2,4.2C17.48,14.7,17.28,14.9,17.18,14.8Z' transform='translate(0 0.2)' style='fill:%23f3e0e3'/%3E%3C/svg%3E");
`;

function waitingFor(label) {
  return (
    <Text
      color="others"
      fontFamily="heading"
      fontSize={['2rem', '2rem', '2.6rem']}
      fontWeight="semibold"
      css={DuelIcon}
    >
      {label}
    </Text>
  );
}

function youWon(label) {
  return (
    <Text
      color="secondary"
      fontFamily="heading"
      fontSize={['2rem', '2rem', '2.6rem']}
      fontWeight="semibold"
      css={WonIcon}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );
}

function draw(label) {
  return (
    <Text
      color="secondary"
      fontFamily="heading"
      fontSize={['2rem', '2rem', '2.6rem']}
      fontWeight="semibold"
      css={WonIcon}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );
}

function youLost(label) {
  return (
    <Text
      color="tertiary"
      fontFamily="heading"
      fontSize={['2rem', '2rem', '2.6rem']}
      fontWeight="semibold"
      css={LostIcon}
    >
      {label}
    </Text>
  );
}

class DuelResults extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    data: PropTypes.object,
    hasPlayed: PropTypes.bool,
    hasWon: PropTypes.number,
    otherPlayer: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, data: dataProps, hasPlayed, hasWon, otherPlayer } = this.props;

    return (
      <Query query={GET_CURRENT_USER} variables={{ withStudentStatistics: true }} fetchPolicy={'network-only'}>
        {({ loading, error, data }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const user = data.me;
          const isTeacher = hasTeacherRole(user);
          const newBadge = [WINNING_DUELS_BRONZE, WINNING_DUELS_SILVER, WINNING_DUELS_GOLD].includes(
            user.countWinningDuels
          );

          return (
            <Box
              py={[4, 4, 4, 0]}
              css={`
                @media (min-width: ${breakpoints.lg}) {
                  position: relative;
                  top: -8rem;
                }
              `}
            >
              <DuelHeader data={dataProps} />
              <Flex pt={['.8rem', '.8rem', '1.6rem']} justifyContent="center" css="max-width: 87.6rem; margin: auto;">
                <DuelScores data={dataProps} />
              </Flex>
              <Flex pt={['2.6rem', '2.6rem', '7rem']} justifyContent="center">
                {hasPlayed
                  ? hasWon > 0
                    ? youWon(newBadge ? t('duelAchievedWithCount', { count: user.countWinningDuels }) : t('youWon'))
                    : hasWon < 0
                      ? youLost(t('youLost'))
                      : draw(t('draw'))
                  : waitingFor(t('duelWaitingForOpponent', { player: otherPlayer.firstname }))}
              </Flex>
              {!isTeacher &&
                hasPlayed &&
                hasWon > 0 &&
                newBadge && (
                  <React.Fragment>
                    <Flex py={2} pt={2} justifyContent="center">
                      {user.countWinningDuels == WINNING_DUELS_BRONZE && <BadgeDuelsBronzeIcon />}
                      {user.countWinningDuels === WINNING_DUELS_SILVER && <BadgeDuelsSilverIcon />}
                      {user.countWinningDuels == WINNING_DUELS_GOLD && <BadgeDuelsGoldIcon />}
                    </Flex>
                  </React.Fragment>
                )}
            </Box>
          );
        }}
      </Query>
    );
  }
}

export default withNamespaces()(DuelResults);
