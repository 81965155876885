import RetrySvg from 'assets/svg/retry.svg';
import VideoMp4 from 'assets/video/brio_tuto.mp4';
import VideoWebm from 'assets/video/brio_tuto.webm';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Box, Flex, Text } from 'rebass';
import Badge from 'src/components/elements/Badge';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { LoadingContext } from 'src/context';
import Layout from 'src/layouts/Tutorial';
import { svgStyle } from 'src/styles/helpers';
import { GAME_BRIO } from 'src/utils/games';
import styled from 'styled-components';

const PlayerWrapper = styled.div.attrs({})`
  position: relative;
  padding-top: 51.5151515152%;
`;

const Overlay = styled(Flex)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
`;

const RetryIcon = styled(RetrySvg)`
  ${svgStyle};
  widht: 8rem;
  height: 8rem;
  color: #fff;
`;

class Tutorial extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
  };

  state = {
    playing: true,
    stopped: false,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  onPlay = difficulty => {
    const { history, match } = this.props;
    history.push(`/series/${match.params.id}/brio`, { difficulty: difficulty, ...history.location.state });
  };

  onCloseModal = () => {
    const { history, match } = this.props;
    history.push(`/series/${match.params.id}`, { ...history.location.state });
  };

  render() {
    const { t, history, match } = this.props;
    const { playing, stopped } = this.state;

    if (match.params && match.params.difficulty)
      this.onPlay(match.params.difficulty);

    return (
      <Layout
        badge={<Badge game={GAME_BRIO} />}
        onPlay={this.onPlay}
        history={history}
        id={match.params.id}
        game={GAME_BRIO}
        onCloseModal={this.onCloseModal}
      >
        <Container>
          <Content>
            <Grid py={[0, 0, 0, '4rem']} justifyContent="center">
              <Col width={[1 / 1, 1 / 1, 1 / 1, 4 / 12]}>
                <Text width={1 / 1} pt={3} pb={[3, 3, 3, 0]} textAlign={['center', 'center', 'center', 'left']}>
                  <Text fontFamily="heading" fontWeight="semibold" pb="1rem" fontSize={['1.8rem', '1.8rem', '2.4rem']}>
                    {t('howItsWorks')}
                  </Text>
                  <Text fontSize={['1.6rem', '1.6rem', '2rem']}>{t('game.brioTutorial')}</Text>
                </Text>
              </Col>
              <Col width={[1 / 1, 1 / 1, 1 / 1, 7 / 12]} pb={4}>
                <Box width={1 / 1}>
                  <PlayerWrapper>
                    {stopped && (
                      <Overlay
                        onClick={() => this.setState({ playing: true, stopped: false })}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <RetryIcon />
                      </Overlay>
                    )}
                    <ReactPlayer
                      autoPlay={true}
                      playing={playing}
                      width="100%"
                      height="100%"
                      style={{ position: 'absolute', zIndex: 1, top: 0, left: 0 }}
                      url={[{ src: VideoWebm, type: 'video/webm' }, { src: VideoMp4, type: 'video/mp4' }]}
                      onEnded={() => this.setState({ playing: false, stopped: true })}
                    />
                  </PlayerWrapper>
                </Box>
              </Col>
            </Grid>
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default withNamespaces()(Tutorial);
