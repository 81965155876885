import React, { Component } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import { Query } from '@apollo/client/react/components';
import { GET_COLLECTIONS, COLLECTIONS_ORDER_BY } from 'src/queries/collections';
import Content from './Content';

class AuthorCollectionsContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
  };

  collections = null;
  schoolSubjects = null;
  isContentLoaded = false;
  state = {
    orderBy: 'newest',
    filterBy: null,
  };

  handleFilterChange = event => {
    this.setState({
      filterBy: event.target.value,
    });
  };

  handleOrderChange = event => {
    this.setState({
      orderBy: event.target.value,
    });
  };

  render() {
    const { user } = this.props;
    const { orderBy, filterBy } = this.state;
    const filters = [{ join: 'author', property: 'id', where: user.id }];

    if (filterBy) {
      filters.push({ property: 'schoolSubject', where: filterBy });
    }

    return (
      <Query
        query={GET_COLLECTIONS}
        fetchPolicy="cache-and-network"
        variables={{
          filterBy: filters,
          orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
          limit: 100,
        }}
      >
        {({ data, error, loading }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          if (!data) return null;
          this.isContentLoaded = true;

          const collections = data.collections ? groupBy(data.collections.results, obj => obj.schoolSubject.name) : {};

          if (data.collections && !this.schoolSubjects) {
            this.schoolSubjects = [];
            data.collections.results.map(collection => {
              if (
                this.schoolSubjects.length === 0 ||
                !this.schoolSubjects.some(schoolSubject => schoolSubject.id === collection.schoolSubject.id)
              ) {
                this.schoolSubjects.push({
                  id: collection.schoolSubject.id,
                  name: collection.schoolSubject.name,
                });
              }
            });
          }

          return (
            <Content
              handleFilterChange={this.handleFilterChange}
              handleOrderChange={this.handleOrderChange}
              filterBy={filterBy}
              orderBy={orderBy}
              count={data.collections.count}
              collections={collections}
              isLoading={loading}
              schoolSubjects={this.schoolSubjects}
              user={user}
            />
          );
        }}
      </Query>
    );
  }
}

export default AuthorCollectionsContainer;
