import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import DuelList from 'src/components/blocks/DuelList';
import Pagination from 'src/components/blocks/Pagination';
import Tabs from 'src/components/blocks/Tabs';
import Title from 'src/components/typography/Title';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import DuelSvg from 'assets/svg/duel.svg';

const DuelIcon = styled(DuelSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

const tabStyle = css`
  flex: 1;
  @media (min-width: ${breakpoints.md}) {
    min-width: 21.4rem;
  }
`;

class Duels extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    onPageChange: PropTypes.func,
    onTabChange: PropTypes.func,
    dataDuels: PropTypes.object,
    page: PropTypes.number,
    tab: PropTypes.number,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, onPageChange, onTabChange, dataDuels, page, tab, user } = this.props;

    return (
      <Container pb={3}>
        <Content mt={3}>
          <Title as="h1" icon={<DuelIcon />} medium>
            {t('myDuels')}
          </Title>
          <Tabs onSelect={onTabChange} defaultIndex={tab}>
            <Box pt={2}>
              <Tabs.TabList>
                <Tabs.Tab css={tabStyle}>{t('duelsWaiting')}</Tabs.Tab>
                <Tabs.Tab css={tabStyle}>{t('historyOfTheDuels')}</Tabs.Tab>
              </Tabs.TabList>
            </Box>
            <Tabs.TabPanel>
              {dataDuels && dataDuels.results.length > 0 ? (
                <DuelList data={dataDuels.results} user={user} />
              ) : (
                <p>{t('noResult')}</p>
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              {dataDuels && dataDuels.results.length > 0 ? (
                <DuelList data={dataDuels.results} expired={true} user={user} />
              ) : (
                <p>{t('noResult')}</p>
              )}
            </Tabs.TabPanel>
          </Tabs>
          {dataDuels &&
            dataDuels.count > Pagination.limit && (
              <Pagination total={dataDuels.count} page={page} onPageChange={onPageChange} />
            )}
        </Content>
      </Container>
    );
  }
}

export default withNamespaces()(Duels);
