"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRules = exports.compare = exports.ignoreCharacters = exports.ignoreOrder = exports.ignoreSpaces = exports.ignoreCase = exports.getGame = exports.defaultTimeSettings = exports.difficulties = exports.games = exports.DUEL_MODE = exports.SOLO_MODE = exports.SOFT_DIFFICULTY = exports.NORMAL_DIFFICULTY = exports.HARD_DIFFICULTY = exports.GAME_QUIZAKO = exports.GAME_BRIO = exports.GAME_TYPO = exports.GAME_MEMO = exports.GAME_ASSO = exports.GAME_RECTO_VERSO = exports.GAME_TRAINING_LIST = void 0;
exports.formatTime = formatTime;
exports.getIconForGame = getIconForGame;
exports.collectionHasGame = collectionHasGame;
exports.getLabelForGame = getLabelForGame;
exports.getLabelByDifficulty = getLabelByDifficulty;
exports.getCardsNumberByDifficulty = getCardsNumberByDifficulty;
exports.getRouteNameForGame = getRouteNameForGame;
exports.getTimeByDifficultyForGame = getTimeByDifficultyForGame;
exports.getFinalScore = getFinalScore;
var React = __importStar(require("react"));
var Badge_1 = require("src/components/elements/Badge");
exports.GAME_TRAINING_LIST = 'GAME_TRAINING_LIST ';
exports.GAME_RECTO_VERSO = 'GAME_RECTO_VERSO';
exports.GAME_ASSO = 'GAME_ASSO';
exports.GAME_MEMO = 'GAME_MEMO';
exports.GAME_TYPO = 'GAME_TYPO';
exports.GAME_BRIO = 'GAME_BRIO';
exports.GAME_QUIZAKO = 'GAME_QUIZAKO';
exports.HARD_DIFFICULTY = 'HARD_DIFFICULTY';
exports.NORMAL_DIFFICULTY = 'NORMAL_DIFFICULTY';
exports.SOFT_DIFFICULTY = 'SOFT_DIFFICULTY';
exports.SOLO_MODE = 'SOLO_MODE';
exports.DUEL_MODE = 'DUEL_MODE';
exports.games = [exports.GAME_ASSO, exports.GAME_TYPO, exports.GAME_MEMO, exports.GAME_BRIO, exports.GAME_QUIZAKO];
exports.difficulties = [exports.SOFT_DIFFICULTY, exports.NORMAL_DIFFICULTY, exports.HARD_DIFFICULTY];
function formatTime(time) {
    var minutes = Math.floor(time / 60);
    var seconds = time - minutes * 60;
    return "".concat(minutes.toString().padStart(2, '0'), ":").concat(seconds.toString().padStart(2, '0'));
}
function getIconForGame(mode, css) {
    if (css === void 0) { css = null; }
    return ((mode === exports.GAME_ASSO && React.createElement(Badge_1.BadgeWithIcons.AssociationIcon, { css: css })) ||
        (mode === exports.GAME_BRIO && React.createElement(Badge_1.BadgeWithIcons.BrioIcon, { css: css })) ||
        (mode === exports.GAME_MEMO && React.createElement(Badge_1.BadgeWithIcons.MemorizingIcon, { css: css })) ||
        (mode === exports.GAME_RECTO_VERSO && React.createElement(Badge_1.BadgeWithIcons.RectoVersoIcon, { css: css })) ||
        (mode === exports.GAME_TYPO && React.createElement(Badge_1.BadgeWithIcons.TypographyIcon, { css: css })) ||
        (mode === exports.GAME_QUIZAKO && React.createElement(Badge_1.BadgeWithIcons.TypographyIcon, { css: css })) /* TODO: Quizako icon */);
}
function collectionHasGame(collection, game) {
    return ((game === exports.GAME_ASSO && collection.assoMode) ||
        (game === exports.GAME_BRIO && collection.brioMode) ||
        (game === exports.GAME_MEMO && collection.memoMode) ||
        (game === exports.GAME_TYPO && collection.typoMode) ||
        (game === exports.GAME_QUIZAKO && collection.quizakoMode));
}
function getLabelForGame(mode) {
    return ((mode === exports.GAME_ASSO && 'Asso') ||
        (mode === exports.GAME_BRIO && 'Brio') ||
        (mode === exports.GAME_MEMO && 'Memo') ||
        (mode === exports.GAME_RECTO_VERSO && 'Recto/Verso') ||
        (mode === exports.GAME_TYPO && 'Typo') ||
        (mode === exports.GAME_QUIZAKO && 'Quizako'));
}
function getLabelByDifficulty(difficulty) {
    if (difficulty === exports.HARD_DIFFICULTY) {
        return 'Difficile';
    }
    else if (difficulty === exports.NORMAL_DIFFICULTY) {
        return 'Normal';
    }
    return 'Facile';
}
function getCardsNumberByDifficulty(difficulty, game) {
    if (game === exports.GAME_ASSO) {
        if (difficulty === exports.HARD_DIFFICULTY) {
            return 5;
        }
        else if (difficulty === exports.NORMAL_DIFFICULTY) {
            return 4;
        }
        return 3;
    }
    if (game === exports.GAME_TYPO) {
        if (difficulty === exports.HARD_DIFFICULTY) {
            return 8;
        }
        else if (difficulty === exports.NORMAL_DIFFICULTY) {
            return 6;
        }
        return 4;
    }
    if (game === exports.GAME_QUIZAKO) {
        if (difficulty === exports.HARD_DIFFICULTY) {
            return 10;
        }
        else if (difficulty === exports.NORMAL_DIFFICULTY) {
            return 8;
        }
        return 4;
    }
    if (difficulty === exports.HARD_DIFFICULTY) {
        return 10;
    }
    else if (difficulty === exports.NORMAL_DIFFICULTY) {
        return 8;
    }
    return 6;
}
function getRouteNameForGame(mode) {
    return ((mode === exports.GAME_ASSO && 'association') ||
        (mode === exports.GAME_BRIO && 'brio') ||
        (mode === exports.GAME_MEMO && 'memorisation') ||
        (mode === exports.GAME_TYPO && 'typographie') ||
        (mode === exports.GAME_QUIZAKO && 'quizako'));
}
function getTimeByDifficultyForGame(difficulty, game, settings) {
    var time = 10;
    if (game === exports.GAME_MEMO) {
        if (difficulty === exports.HARD_DIFFICULTY) {
            time = settings.memoTimerHard;
        }
        else if (difficulty === exports.NORMAL_DIFFICULTY) {
            time = settings.memoTimerMedium;
        }
        else {
            time = settings.memoTimerEasy;
        }
        time *= 1000;
    }
    if (game === exports.GAME_BRIO) {
        if (difficulty === exports.HARD_DIFFICULTY) {
            time = settings.brioTimerHard;
        }
        else if (difficulty === exports.NORMAL_DIFFICULTY) {
            time = settings.brioTimerMedium;
        }
        else {
            time = settings.brioTimerEasy;
        }
    }
    return time;
}
function getFinalScore(score, errors, goodAnswersInARow) {
    if (goodAnswersInARow >= 4) {
        score += 2;
    }
    if (!errors) {
        score += 5;
    }
    return score;
}
exports.defaultTimeSettings = {
    brioTimerEasy: 10,
    brioTimerMedium: 9,
    brioTimerHard: 8,
    memoTimerEasy: 10,
    memoTimerMedium: 30,
    memoTimerHard: 60,
};
var getGame = function (game) {
    if (game === exports.GAME_ASSO) {
        return exports.GAME_ASSO;
    }
    else if (game === exports.GAME_BRIO) {
        return exports.GAME_BRIO;
    }
    else if (game === exports.GAME_MEMO) {
        return exports.GAME_MEMO;
    }
    else if (game === exports.GAME_TYPO) {
        return exports.GAME_TYPO;
    }
    else if (game === exports.GAME_QUIZAKO) {
        return exports.GAME_QUIZAKO;
    }
    return null;
};
exports.getGame = getGame;
var ignoreCase = function (a) { return a.toLowerCase(); };
exports.ignoreCase = ignoreCase;
var ignoreSpaces = function (a) { return a.replace(/\s/g, ''); };
exports.ignoreSpaces = ignoreSpaces;
var ignoreOrder = function (a) { return a.split('').sort().join(''); };
exports.ignoreOrder = ignoreOrder;
var ignoreCharacters = function (params) { return function (a) { return a.split('').filter(function (char) { return !params.includes(char); }).join(''); }; };
exports.ignoreCharacters = ignoreCharacters;
var compare = function (a, b, validators) {
    if (validators) {
        validators.forEach(function (validator) {
            a = validator(a);
            b = validator(b);
        });
    }
    return a === b;
};
exports.compare = compare;
var getRules = function (collection) {
    var rules = [];
    if (collection.ignoreCase) {
        rules.push(exports.ignoreCase);
    }
    if (collection.ignoreSpaces) {
        rules.push(exports.ignoreSpaces);
    }
    if (collection.ignoreOrder) {
        rules.push(exports.ignoreOrder);
    }
    if (collection.ignoreCharacters) {
        rules.push((0, exports.ignoreCharacters)(collection.ignoreCharacters));
    }
    return rules;
};
exports.getRules = getRules;
