"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleFocusOnFirstError = exports.findFirstError = exports.countErrors = exports.validateSerie = void 0;
var graphql_1 = require("src/gql/graphql");
var Navigation_1 = require("./Navigation");
var required = function (errorMessage) { return function (key, values) {
    if (!values[key]) {
        return errorMessage || 'Ce champs est requis';
    }
    return undefined;
}; };
var minCard = function (count, errorMessage, type) { return function (key, values) {
    if (values[key] && values['cards'].length < count) {
        return errorMessage || "Vous devez ajouter au moins ".concat(count, " cartes");
    }
    if (type && values[key] && values['cards'].filter(function (card) { return type.includes(card.type); }).length < count) {
        return errorMessage || "Vous devez ajouter au moins ".concat(count, " cartes de type ").concat(type.join(', '));
    }
    return undefined;
}; };
var enoughNonTrueFalse = function (_key, values) {
    var hasClassicResponse = values['cards'].some(function (card) { return card.type !== graphql_1.CardType.TrueFalse; });
    var hasOrphanMultipleResponse = values['cards'].some(function (card) { return card.type === graphql_1.CardType.MultipleResponse && card.backFaces.length < 4; });
    if (hasClassicResponse && hasOrphanMultipleResponse) {
        var classicResponses = values['cards'].filter(function (card) { return card.type !== graphql_1.CardType.TrueFalse; });
        if (classicResponses.length < 4) {
            return "Vous devez ajouter au moins 4 cartes de type autre que Vrai/Faux";
        }
    }
    return undefined;
};
var atLeastOneMode = function (errorMessage) { return function (_key, values) {
    if (!values['assoMode'] && !values['typoMode'] && !values['memoMode'] && !values['brioMode'] && !values['quizakoMode']) {
        return errorMessage || "Vous devez choisir au moins un mode de jeu";
    }
}; };
var atLeastOneInput = function (errorMessage) { return function (_key, face) {
    var emptySide = !face.text && !face.image && !face.audio && !face.latexImage;
    if (emptySide) {
        return errorMessage || "Au moins un texte, une image ou un audio";
    }
    return undefined;
}; };
var notOnlySound = function (errorMessage) { return function (_key, card) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var condition = (((_a = card.frontFace) === null || _a === void 0 ? void 0 : _a.audio) && !((_b = card.frontFace) === null || _b === void 0 ? void 0 : _b.text) && !((_c = card.frontFace) === null || _c === void 0 ? void 0 : _c.image) && !((_d = card.frontFace) === null || _d === void 0 ? void 0 : _d.latexImage))
        || (card.type === graphql_1.CardType.SingleResponse && ((_e = card.backFace) === null || _e === void 0 ? void 0 : _e.audio) && !((_f = card.backFace) === null || _f === void 0 ? void 0 : _f.text) && !((_g = card.backFace) === null || _g === void 0 ? void 0 : _g.image) && !((_h = card.backFace) === null || _h === void 0 ? void 0 : _h.latexImage))
        || (card.type === graphql_1.CardType.MultipleResponse && card.backFaces.some(function (face) { return face.audio && !face.text && !face.image && !face.latexImage; }));
    if (condition) {
        return errorMessage || "Vous devez ajouter du texte ou une image";
    }
    return undefined;
}; };
var backHasText = function (errorMessage) { return function (_key, card) {
    var _a;
    if (card.type === graphql_1.CardType.SingleResponse && !((_a = card.backFace) === null || _a === void 0 ? void 0 : _a.text)) {
        return errorMessage || "Vous devez ajouter du texte au verso";
    }
    if (card.type === graphql_1.CardType.MultipleResponse && card.backFaces.length === 0) {
        return errorMessage || "Vous devez ajouter au moins une face au verso";
    }
    if (card.type === graphql_1.CardType.MultipleResponse && card.backFaces.some(function (face) { return !face.text; })) {
        return errorMessage || "Vous devez ajouter du texte \u00E0 toutes les faces du verso";
    }
    return undefined;
}; };
var cardsValidators = function () {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (key, values) {
        if (!values[key])
            return undefined;
        for (var _i = 0, _a = values.cards; _i < _a.length; _i++) {
            var card = _a[_i];
            for (var _b = 0, validators_1 = validators; _b < validators_1.length; _b++) {
                var validator = validators_1[_b];
                var error = validator(key, card);
                if (error)
                    return error;
            }
        }
        return undefined;
    };
};
var composeValidators = function () {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (key, values) { return validators.reduce(function (error, validator) { return error || validator(key, values); }, undefined); };
};
var collectionValidationConfig = [
    {
        name: 'schoolSubject',
        validation: required("Cette valeur ne doit pas être nulle.")
    },
    {
        name: 'title',
        validation: required("Cette valeur ne doit pas être vide.")
    },
    {
        name: 'mode',
        validation: atLeastOneMode("Sélectionnez au minimum un jeu")
    },
    {
        name: 'assoMode',
        validation: composeValidators(minCard(10, "Asso nécessite un minimum de 10 cartes de type Réponse simple ou Réponses multiples", [graphql_1.CardType.SingleResponse, graphql_1.CardType.MultipleResponse]), cardsValidators(notOnlySound("Asso ne fonctionne pas avec des faces composées uniquement de son")))
    },
    {
        name: 'typoMode',
        validation: composeValidators(minCard(8, "Typo nécessite un minimum de 8 cartes"), cardsValidators(backHasText("Typo nécessite que les versos contiennent du texte")))
    },
    {
        name: 'memoMode',
        validation: composeValidators(minCard(10, "Mémo nécessite un minimum de 10 cartes de type Réponse simple ou Réponses multiples", [graphql_1.CardType.SingleResponse, graphql_1.CardType.MultipleResponse]), cardsValidators(notOnlySound("Mémo ne fonctionne pas avec des faces composées uniquement de son")))
    },
    {
        name: 'brioMode',
        validation: composeValidators(cardsValidators(notOnlySound("Brio ne fonctionne pas avec des faces composées uniquement de son")), cardsValidators(backHasText("Brio nécessite que les versos contiennent du texte")))
    },
    {
        name: 'quizakoMode',
        validation: composeValidators(minCard(8, "Quizako nécessite un minimum de 8 cartes"), enoughNonTrueFalse)
    },
    {
        name: 'cards',
        fields: [
            {
                name: 'frontFace',
                fields: [
                    {
                        name: 'text',
                        validation: atLeastOneInput(),
                    }
                ]
            },
            {
                name: 'backFace',
                fields: [
                    {
                        name: 'text',
                        validation: atLeastOneInput()
                    }
                ],
                condition: function (card) { return card.type === graphql_1.CardType.SingleResponse; }
            },
            {
                name: 'backFaces',
                fields: [
                    {
                        name: 'text',
                        validation: atLeastOneInput()
                    }
                ],
                condition: function (card) { return card.type === graphql_1.CardType.MultipleResponse; }
            },
            {
                name: 'backFacesHasCorrect',
                validation: function (_key, card) {
                    return card.backFaces.some(function (face) { return face.isCorrect; }) ? undefined : "Vous devez saisir au moins une face correcte";
                },
                condition: function (card) { return card.type === graphql_1.CardType.MultipleResponse && card.backFaces.length > 0; }
            },
            {
                name: 'fillWithWrongFace',
                validation: function (_key, card) {
                    if (card.type === graphql_1.CardType.MultipleResponse && card.backFaces.length > 0) {
                        return !card.fillWithWrongFace && card.backFaces.length < 2 ? "Vous devez saisir au moins deux faces si vous n'avez pas coché la case pour compléter avec des faces incorrectes" : undefined;
                    }
                    return undefined;
                }
            }
        ]
    }
];
var validateField = function (config, value) {
    var _a;
    var errors = {};
    if (Array.isArray(config)) {
        config.forEach(function (field) {
            var error = validateField(field, value);
            if (error) {
                errors[field.name] = error;
            }
        });
    }
    else if (config.condition && !config.condition(value)) {
        return undefined;
    }
    else if (config.fields) {
        if (Array.isArray(value[config.name])) {
            return value[config.name].reduce(function (errors, item) {
                var error = validateField(config.fields, item);
                if (error) {
                    errors.push(error);
                }
                else {
                    errors.push(undefined);
                }
                return errors;
            }, []);
        }
        else {
            return config.fields.reduce(function (errors, field) {
                var error = validateField(field, value[config.name]);
                if (error) {
                    errors[field.name] = error;
                }
                else {
                    errors[field.name] = undefined;
                }
                return errors;
            }, {});
        }
    }
    else {
        var error = (_a = config.validation) === null || _a === void 0 ? void 0 : _a.call(config, config.name, value);
        if (error) {
            return error;
        }
        else {
            return undefined;
        }
    }
    var count = (0, exports.countErrors)(errors);
    if (count === 0) {
        return undefined;
    }
    return errors;
};
var validateSerie = function (values) {
    var errors = validateField(collectionValidationConfig, values);
    return errors;
};
exports.validateSerie = validateSerie;
var countErrors = function (errors) {
    var count = 0;
    Object.keys(errors).forEach(function (key) {
        if (typeof errors[key] === 'object') {
            count += (0, exports.countErrors)(errors[key]);
        }
        else if (errors[key]) {
            count++;
        }
    });
    return count;
};
exports.countErrors = countErrors;
var findFirstError = function (errors, path) {
    if (path === void 0) { path = ''; }
    // Handle arrays
    if (Array.isArray(errors)) {
        for (var i = 0; i < errors.length; i++) {
            if (errors[i]) {
                var errorPath = (0, exports.findFirstError)(errors[i], "".concat(path, "[").concat(i, "]"));
                if (errorPath)
                    return errorPath;
            }
        }
        return '';
    }
    if (typeof errors === 'object' && errors !== null) {
        for (var _i = 0, _a = Object.keys(errors); _i < _a.length; _i++) {
            var key = _a[_i];
            var newPath = path ? "".concat(path, ".").concat(key) : key;
            if (typeof errors[key] === 'string' && errors[key]) {
                return newPath;
            }
            var errorPath = (0, exports.findFirstError)(errors[key], newPath);
            if (errorPath)
                return errorPath;
        }
    }
    return '';
};
exports.findFirstError = findFirstError;
var handleFocusOnFirstError = function (errors, redirectTab, currentStep) {
    var firstError = (0, exports.findFirstError)(errors);
    if (firstError.startsWith('cards') && currentStep !== Navigation_1.Step.Cards) {
        redirectTab(Navigation_1.Step.Cards);
    }
    else if ((firstError.startsWith('assoMode') || firstError.startsWith('typoMode') || firstError.startsWith('memoMode') || firstError.startsWith('brioMode') || firstError.startsWith('quizakoMode')) && currentStep !== Navigation_1.Step.Game) {
        redirectTab(Navigation_1.Step.Game);
    }
    else if (firstError.startsWith('mode') && currentStep !== Navigation_1.Step.Game) {
        redirectTab(Navigation_1.Step.Game);
    }
    else if ((firstError.startsWith('title') || firstError.startsWith('schoolSubject')) && currentStep !== Navigation_1.Step.Infos) {
        redirectTab(Navigation_1.Step.Infos);
    }
    if (firstError) {
        setTimeout(function () {
            var _a, _b;
            (_a = document.getElementById(firstError)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // @ts-expect-error
            (_b = document.querySelector("[name=\"".concat(firstError, "\"]"))) === null || _b === void 0 ? void 0 : _b.focus();
        }, 0);
    }
};
exports.handleFocusOnFirstError = handleFocusOnFirstError;
