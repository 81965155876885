import { Mutation, Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { adopt } from 'react-adopt';
import { ModalContext, UserContext } from 'src/context';
import { GET_DEGREES } from 'src/queries/degrees';
import { ADD_FOLDER, GET_FOLDER, REMOVE_FOLDER } from 'src/queries/folders';
import { handleSubmissionErrors } from 'src/utils/form';
import Content from './Content';

class CreateFolderFormContainer extends PureComponent {
  static contextType = ModalContext;
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    partial: PropTypes.bool,
    onSuccess: PropTypes.func,
    folderId: PropTypes.string,
  };

  static defaultProps = {
    partial: false,
  };

  Composed = null;
  isContentLoaded = false;

  static formatFormValuesvalues(values) {
    const formattedData = {};

    if (values.schoolSubject) {
      formattedData.schoolSubject = { id: values.schoolSubject };
    }

    if (values.schoolDegrees) {
      formattedData.schoolDegrees = values.schoolDegrees.map((schoolDegree) => ({ id: schoolDegree.value }));
    }

    return { ...values, ...formattedData };
  }

  onSubmit = (data, addFolder) => {
    return addFolder({
      variables: {
        input: CreateFolderFormContainer.formatFormValuesvalues(data),
      },
    })
      .then(({ data: { AddFolder } }) => {
        if (this.props.onSuccess) {
          this.props.onSuccess(AddFolder);
        } else {
          this.props.history.push(`/chaines/${AddFolder.id}/editer-series`);
        }
      })
      .catch((errors) => {
        const test = handleSubmissionErrors(errors);
        return test;
      });
  };

  normalizeFolder(apiData) {
    return {
      id: parseInt(apiData.id, 10),
      title: apiData.title,
      isPublished: apiData.isPublished,
      schoolSubject: apiData.schoolSubject.id,
      schoolDegrees: apiData.schoolDegrees.map((degree) => ({ value: degree.id, label: degree.name })),
    };
  }

  render() {
    const { folderId, history, partial } = this.props;
    const { toggleModal } = this.context;

    /*eslint-disable */
    const queries = {
      user: ({ render }) => <UserContext.Consumer>{render}</UserContext.Consumer>,
      degrees: ({ render }) => <Query query={GET_DEGREES}>{render}</Query>,
      addFolder: ({ render }) => <Mutation mutation={ADD_FOLDER}>{render}</Mutation>,
    };

    if (folderId) {
      queries.folder = ({ render }) => (
        <Query
          query={GET_FOLDER}
          fetchPolicy='network-only'
          variables={{ id: folderId }}
        >
          {render}
        </Query>
      );
      queries.removeFolder = ({ render }) => <Mutation mutation={REMOVE_FOLDER}>{render}</Mutation>;
    }

    if (!this.Composed) {
      this.Composed = adopt(queries);
    }
    /*eslint-enable */

    return (
      <this.Composed>
        {({ degrees, addFolder, folder, removeFolder, user }) => {
          if (folderId) {
            if ((folder.loading || removeFolder.loading) && !this.isContentLoaded) return <div />;
            if (folder.error || removeFolder.error) return <p>Error :(</p>;
          }

          if ((degrees.loading || addFolder.loading) && !this.isContentLoaded) return <div />;
          if (degrees.error || addFolder.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <Content
              normalizeFolder={this.normalizeFolder}
              onSubmit={this.onSubmit}
              toggleModal={toggleModal}
              addFolder={addFolder}
              degrees={degrees}
              folder={folder}
              folderId={folderId}
              history={history}
              partial={partial}
              removeFolder={removeFolder}
              subjects={user.schoolSubjects}
            />
          );
        }}
      </this.Composed>
    );
  }
}

export default CreateFolderFormContainer;
