import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import ChevronSvg from 'assets/svg/chevron.svg';
import ChevronPrevSvg from 'assets/svg/chevron-prev.svg';

const PREV_DIRECTION = 'prev';
const NEXT_DIRECTION = 'next';

const chevronStyle = css`
  ${svgStyle};
  width: 0.8rem;
  height: 1.5rem;
`;

const ChevronIcon = styled(ChevronSvg)`
  ${chevronStyle};
`;

const ChevronPrevIcon = styled(ChevronPrevSvg)`
  ${chevronStyle};
`;

const Button = styled(Flex)`
  position: absolute;
  z-index: 2;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4.6rem;
  padding: 0;
  border: none;
  color: ${theme.colors.text};
  outline: none;
  cursor: pointer;
  transform: translateY(-50%);
  ${props =>
    props.direction === PREV_DIRECTION
      ? `right: calc(100% - 2.8rem); background: linear-gradient(to right, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%); @media (min-width: ${
          breakpoints.md
        }) { right: calc(100% - 1.6rem); }`
      : `left: calc(100% - 2.8rem); background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%); @media (min-width: ${
          breakpoints.md
        }) { left: calc(100% - 1.6rem); }`} &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

const TabSwiperButton = ({ ...props }) => (
  <Button as="button" type="button" {...props}>
    {props.direction === PREV_DIRECTION ? <ChevronPrevIcon /> : <ChevronIcon />}
  </Button>
);

TabSwiperButton.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.oneOf([PREV_DIRECTION, NEXT_DIRECTION]),
};

TabSwiperButton.prevDirection = PREV_DIRECTION;
TabSwiperButton.nextDirection = NEXT_DIRECTION;

export default TabSwiperButton;
