import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { theme } from 'src/styles/theme';
import styled from 'styled-components';

const InputStyled = styled.input`
  display: inline-block;
  padding: 0.786em 0 0.786em 0.8rem;
  border-radius: 0.4rem;
  border: 1px solid ${theme.colors.inputBorder};
  color: ${theme.colors.text};
  font-size: 1.4rem;
  outline: 0;
  ${props => props.css};
`;

const Label = styled(Flex)`
  positon: relative;
  display: flex;
  cursor: pointer;
  gap: 2rem;
  align-items: center;
`;

const Input = React.forwardRef(({ input, ...props }, ref) => <InputStyled {...input} {...props} ref={ref} />);

export const LabelledInput = React.forwardRef(({ input, label, detail, ...props }, ref) => {
  return (
    <Box>
      <Label
        as="label"
        htmlFor={input.name}
      >
        <Text color={theme.colors.black} fontSize='1.8rem'>{label}</Text>
        <Input {...input} {...props} style={{ width: "50%" }} ref={ref} />
      </Label>
      {detail && <Text pt={'1rem'} color='#A59D9D;' fontSize='1.2rem'>{detail}</Text>}
    </Box>
  );
});

Input.displayName = 'Input';

LabelledInput.displayName = 'LabelledInput';


export default Input;
