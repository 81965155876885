import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { breakpoints } from 'src/styles/theme';
import { Col, Grid } from 'src/components/utilities/Grids';
import Counter from 'src/components/blocks/UserWelcome/Counter';
import { ChronoIcon, CorrectIcon, RetryIcon, TrophyIcon } from 'src/components/blocks/UserWelcome/Icons';
import { formatTime } from 'src/utils/games';

class GameResults extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    answers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    errors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isPlaying: PropTypes.bool,
    isTeacher: PropTypes.bool,
    learnedCards: PropTypes.number,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    toReviewCards: PropTypes.number,
    userScoreBeforeSaveScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    avatarLevels: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, answers, errors, isPlaying, isTeacher, learnedCards, score, time, toReviewCards } = this.props;

    return (
      <Box
        py={[4, 4, 4, 0]}
        css={`
          @media (min-width: ${breakpoints.lg}) {
            position: relative;
            top: -8rem;
          }
        `}
      >
        <Grid justifyContent="center">
          {!!(time && time !== 0) && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
              <Counter icon={<ChronoIcon />} label={`${formatTime(time)}`} />
            </Col>
          )}
          {!!(score && score !== 0 && !isTeacher) && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
              <Counter icon={<TrophyIcon />} label={t('pointWithCount', { count: score })} />
            </Col>
          )}
          {!!(answers && answers !== 0) && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
              <Counter icon={<CorrectIcon />} label={t('correctAnswerWithCount', { count: answers })} />
            </Col>
          )}
          {!!(errors && errors !== 0) && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
              <Counter icon={<RetryIcon />} label={t('errorWithCount', { count: errors })} />
            </Col>
          )}
          {!isPlaying && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
              <Counter icon={<CorrectIcon />} label={t('acquiredWithCount', { count: learnedCards })} />
            </Col>
          )}
          {!isPlaying && (
            <Col width={[6 / 12, 6 / 12, 3 / 12]} alignItems="center" justifyContent="center" pb={[2, 2, 0]}>
              <Counter icon={<RetryIcon />} label={t('toReviewWithCount', { count: toReviewCards })} />
            </Col>
          )}
        </Grid>
      </Box>
    );
  }
}

export default withNamespaces()(GameResults);
