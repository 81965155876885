import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import RouterLink from 'react-router-dom/Link';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Query } from '@apollo/client/react/components';
import { GET_RANKINGS } from 'src/queries/users';
import { Box, Flex, Text } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Grid, Col } from 'src/components/utilities/Grids';
import { LinkButton } from 'src/components/elements/Button';
import Badge from 'src/components/elements/Badge';
import Navigation from 'src/components/games/Navigation';
import { breakpoints, theme } from 'src/styles/theme';
import GameResults from './Game';
import DuelResults from './Duel';
import { hasTeacherRole } from 'src/utils/roles';
import { GAME_BRIO } from 'src/utils/games';

const Link = styled(RouterLink)`
  display: flex;
  flex: 1;
  text-decoration: none;
`;

const UserContainer = styled(Box)`
  ${props =>
    !props.current &&
    `
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    @media (min-width: ${breakpoints.lg}) { 
      padding-right: 2.6rem;
      padding-left: 2.6rem;
    }
  `};
`;

const UserItem = styled(Flex)`
  min-height: 4rem;
  border: 2px solid #eeeef5;
  border-radius: 0.4rem;
  font-size: 1.6rem;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 6rem;
  }

  @media (min-width: ${breakpoints.xsm}) and (max-width: ${breakpoints.smPlus}) {
    word-break: break-word;
  }

  ${props =>
    props.current &&
    `
    min-height: 5rem;
    border-color: ${theme.colors.secondary};
    box-shadow: 0 3px 6px 0 rgba(150,144,144,0.39);

    @media (min-width: ${breakpoints.lg}) { 
      min-height: 7.6rem;
      font-size: 1.8rem;
    }
  `};
`;

const UserAvatar = styled(Box)`
  width: ${props => (props.current ? '4rem' : '3rem')};
  height: 100%;

  @media (min-width: ${breakpoints.lg}) {
    width: ${props => (props.current ? '6rem' : '5rem')};
  }
`;

class GameResult extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    getPlayAgainUrl: PropTypes.func,
    answers: PropTypes.number,
    collectionId: PropTypes.string,
    difficulty: PropTypes.string,
    duel: PropTypes.string,
    errors: PropTypes.number,
    game: PropTypes.string,
    isPlaying: PropTypes.bool,
    score: PropTypes.number,
    success: PropTypes.bool,
    time: PropTypes.number,
    user: PropTypes.object,
    newAvatarLevel: PropTypes.object,
  };

  render() {
    const {
      t,
      getPlayAgainUrl,
      answers,
      collectionId,
      difficulty,
      duel,
      errors,
      game,
      isPlaying,
      score,
      success,
      time,
      user,
      newAvatarLevel,
    } = this.props;
    const isTeacher = hasTeacherRole(user);

    return (
      <Box bg="gray200" css="flex: 1 1 auto">
        <Box bg="white" pb={[0, 0, 0, '6rem']}>
          <Container>
            <Content>
              <Grid pt={[1]} pb={2}>
                <Col flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 14rem']}>
                  <Box>
                    <Badge game={game} difficulty={difficulty} />
                  </Box>
                </Col>
                <Col
                  flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 1 auto']}
                  alignItems="center"
                  justifyContent="center"
                  pb={[0, 0, 0, 2]}
                >
                  <Box py={3} css="text-align: center;">
                    <Text fontFamily="heading" fontSize={['1.6rem', '1.6rem', '2.4rem']}>
                      {duel && t('duelEndOfTheGameWithCount', { count: answers, time: time })}
                      {!duel &&
                        (success
                          ? newAvatarLevel !== null && !isTeacher
                            ? t('avatarsUnlocked')
                            : t('bravo')
                          : t('youLost'))}
                    </Text>
                    {duel &&
                      newAvatarLevel !== null && (
                        <Text fontFamily="heading" fontSize={['1.6rem', '1.6rem', '2.4rem']}>
                          {t('avatarsUnlocked')}
                        </Text>
                      )}
                    {!isTeacher &&
                      success &&
                      newAvatarLevel &&
                      newAvatarLevel.avatars.length > 0 && (
                        <React.Fragment>
                          <Flex py={2} justifyContent="center">
                            <Box css="min-width: 10rem; max-width: 20rem;">
                              <img
                                src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${
                                  newAvatarLevel.avatars[0].image
                                }`}
                              />
                            </Box>
                          </Flex>
                          <Text fontFamily="heading" fontSize={['1.6rem', '1.6rem', '2.4rem']}>
                            {t('pointAchievedWithCount', { count: newAvatarLevel.score })}
                          </Text>
                          <Box py={2}>
                            <LinkButton to="/mes-parametres" css="min-width: 22rem;">
                              {t('changeMyAvatar')}
                            </LinkButton>
                          </Box>
                        </React.Fragment>
                      )}
                  </Box>
                </Col>
                <Col flex={['0 0 100%', '0 0 100%', '0 0 14rem']} />
              </Grid>
            </Content>
          </Container>
        </Box>
        <Box bg="gray200">
          <Container>
            <Content>
              {duel && <DuelResults duel={duel} user={user} />}
              {!duel && (
                <GameResults
                  collectionId={collectionId}
                  time={time}
                  score={score}
                  answers={answers}
                  errors={errors}
                  isPlaying={isPlaying}
                  isTeacher={isTeacher}
                />
              )}
              {!duel &&
                !isTeacher &&
                isPlaying &&
                success && (
                  <Box pb={4}>
                    <Query
                      query={GET_RANKINGS}
                      fetchPolicy="cache-and-network"
                      variables={{ game: game, collection: collectionId }}
                    >
                      {({ loading, error, data }) => {
                        if (loading) return <p>Loading...</p>;
                        if (error) return <p>Error :(</p>;

                        let gameName = game.slice(5); //removing "GAME_"
                        let gameScoreProperty = 'score' + gameName.charAt(0) + gameName.slice(1).toLowerCase();
                        let gameTimeProperty = 'time' + gameName.charAt(0) + gameName.slice(1).toLowerCase();

                        return (
                          <React.Fragment>
                            <Text
                              pb="2.5rem"
                              fontSize={['1.6rem', '1.6rem', '2rem']}
                              fontWeight={['medium', 'medium', 'semibold']}
                              textAlign="center"
                            >
                              {t('yourRankingForThisGame')}
                            </Text>
                            <Box as="ul" mt="0" pl="0" pb={4} css="list-style: none;">
                              {data.rankings.users.map((rankedUser, i) => (
                                <UserContainer
                                  key={i}
                                  pb={['.8rem', '.8rem', '1.2rem']}
                                  current={rankedUser.id === user.id ? 'true' : null}
                                  css="max-width: 60rem; margin: auto;"
                                >
                                  <UserItem
                                    pr={1}
                                    pl=".4rem"
                                    py=".2rem"
                                    bg="white"
                                    alignItems="center"
                                    current={rankedUser.id === user.id ? 'true' : null}
                                  >
                                    <UserAvatar current={rankedUser.id === user.id}>
                                      {rankedUser.avatar && (
                                        <img
                                          src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${
                                            rankedUser.avatar.image
                                          }`}
                                        />
                                      )}
                                    </UserAvatar>
                                    <Text pl={1} fontWeight="semibold" css="width: 10rem;">
                                      {rankedUser.firstname}
                                    </Text>
                                    <Text
                                      pl={1}
                                      color={rankedUser.id === user.id ? 'secondary' : 'text'}
                                      fontWeight="semibold"
                                      textAlign="right"
                                      css="flex: 1 1 auto;"
                                    >
                                      {data.rankings.firstRank + i}
                                      {data.rankings.firstRank + i === 1 ? 'er' : 'ème'}
                                    </Text>
                                    <Text
                                      pl={1}
                                      color={rankedUser.id === user.id ? 'secondary' : 'text'}
                                      fontWeight="semibold"
                                      textAlign="right"
                                      css="flex: 1 1 auto;"
                                    >
                                      {
                                        rankedUser.linkCollections.find(x => x.collection.id === collectionId)[
                                          gameScoreProperty
                                        ]
                                      }
                                      pts
                                    </Text>
                                    {game !== GAME_BRIO && (
                                      <Text
                                        pl={1}
                                        color={rankedUser.id === user.id ? 'secondary' : 'text'}
                                        fontWeight="semibold"
                                        textAlign="right"
                                        css="flex: 1 1 auto;"
                                      >
                                        {
                                          rankedUser.linkCollections.find(x => x.collection.id === collectionId)[
                                            gameTimeProperty
                                          ]
                                        }
                                        s
                                      </Text>
                                    )}
                                  </UserItem>
                                </UserContainer>
                              ))}
                            </Box>
                          </React.Fragment>
                        );
                      }}
                    </Query>
                  </Box>
                )}
              {!duel &&
                !isPlaying && (
                  <Box pb={4}>
                    <Text
                      pb="2.5rem"
                      fontSize={['1.6rem', '1.6rem', '2rem']}
                      fontWeight={['medium', 'medium', 'semibold']}
                      textAlign="center"
                    >
                      {t('keepOnTraining')}
                    </Text>
                    <Navigation>
                      <Navigation.Section label="Entraînement">
                        <Link to={`/series/${collectionId}`}>
                          <Navigation.Item
                            icon={<Navigation.BookIcon />}
                            label={t('game.list')}
                            color={theme.colors.primary}
                          />
                        </Link>
                        <Link to={`/series/${collectionId}`}>
                          <Navigation.Item
                            icon={<Navigation.RectoVersoIcon />}
                            label={t('game.rectoverso')}
                            color={theme.colors.primary}
                          />
                        </Link>
                      </Navigation.Section>
                      <Navigation.Section label="Jeux" pl="1.2rem">
                        <Link to={`/series/${collectionId}/association`}>
                          <Navigation.Item
                            icon={<Navigation.AssoIcon />}
                            label={t('game.asso')}
                            color={theme.colors.secondary}
                          />
                        </Link>
                        <Navigation.Item
                          icon={<Navigation.MemoIcon />}
                          label={t('game.memo')}
                          color={theme.colors.secondary}
                        />
                        <Link to={`/series/${collectionId}/typographie`}>
                          <Navigation.Item
                            icon={<Navigation.TypoIcon />}
                            label={t('game.typo')}
                            color={theme.colors.secondary}
                          />
                        </Link>
                        <MediaQuery query={`(min-width: ${breakpoints.md})`}>
                          <Navigation.Item
                            icon={<Navigation.BrainIcon />}
                            label={t('game.brio')}
                            color={theme.colors.secondary}
                          />
                        </MediaQuery>
                        <Link to={`/series/${collectionId}/quizako`}>
                          <Navigation.Item
                            icon={<Navigation.QuizakoIcon />}
                            label={t('game.quizako')}
                            color={theme.colors.secondary}
                          />
                        </Link>
                      </Navigation.Section>
                    </Navigation>
                    <Grid justifyContent="center" pt="8rem">
                      <Col justifyContent="center">
                        <LinkButton to="/" css="width: 100%; max-width: 22rem;">
                          {t('backToHome')}
                        </LinkButton>
                      </Col>
                    </Grid>
                  </Box>
                )}
              {isPlaying && (
                <Grid justifyContent="center">
                  {!duel && (
                    <Col flex={['0 0 100%', '0 0 100%', '0 0 auto']} pb={3} justifyContent="center">
                      <LinkButton to={getPlayAgainUrl(collectionId, game, difficulty)} css="min-width: 22rem;">
                        {t('redoGame')}
                      </LinkButton>
                    </Col>
                  )}
                  <Col flex={['0 0 100%', '0 0 100%', '0 0 auto']} pb={3} justifyContent="center">
                    <LinkButton to={`/series/${collectionId}`} css="min-width: 22rem;">
                      {t('backToSerie')}
                    </LinkButton>
                  </Col>
                  <Col flex={['0 0 100%', '0 0 100%', '0 0 auto']} pb={3} justifyContent="center">
                    <LinkButton to="/" css="min-width: 22rem;">
                      {t('backToHome')}
                    </LinkButton>
                  </Col>
                </Grid>
              )}
            </Content>
          </Container>
        </Box>
      </Box>
    );
  }
}

export default withNamespaces()(GameResult);
