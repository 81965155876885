import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';

import { Grid, Col } from 'src/components/utilities/Grids';
import Heading from 'src/components/typography/Heading';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints, theme } from 'src/styles/theme';
import AccountSvg from 'assets/svg/account.svg';
import DuelSvg from 'assets/svg/duel.svg';
import { createSchoolDegreeLabel } from 'src/utils/degrees';
import { hasWon } from 'src/utils/duels';
import { getIconForGame, getLabelByDifficulty, getLabelForGame, getRouteNameForGame } from 'src/utils/games';
import { LinkButton } from 'src/components/elements/Button';
import moment from 'moment';

const AccountIcon = styled(AccountSvg)`
  ${svgStyle};
  height: 3.4rem;
`;

const DuelIcon = styled(DuelSvg)`
  ${svgStyle};
  height: 3rem;
`;

const Container = styled(Box)`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    height: 0.6rem;
    background-color: ${props => props.theme || theme.colors['others']};
  }
`;

class DuelCard extends PureComponent {
  static propTypes = {
    duel: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      collection: PropTypes.object,
      game: PropTypes.string,
      difficulty: PropTypes.string,
      opponent: PropTypes.object,
      challenger: PropTypes.object,
      scoreChallenger: PropTypes.number,
      scoreOpponent: PropTypes.number,
      expiredAtInDays: PropTypes.number,
      created: PropTypes.string,
    }),
    expired: PropTypes.bool,
    user: PropTypes.object,
  };

  static defaultProps = {
    expired: false,
  };

  render() {
    const { duel, expired, user } = this.props;
    const {
      id,
      collection,
      game,
      difficulty,
      opponent,
      challenger,
      scoreChallenger,
      scoreOpponent,
      expiredAtInDays,
      created,
    } = duel;

    const hasPlayed = user.id === opponent.id ? scoreOpponent != null : scoreChallenger != null;
    const otherPlayer = user.id === opponent.id ? challenger : opponent;
    const won = hasWon(user, duel);
    return (
      <Container width={1} py={2} bg="white" theme={collection.schoolSubject.color}>
        <Box px="1.2rem">
          <Grid pt={1}>
            <Col flex="1">
              <Flex>
                <Box pr={1}>
                  {challenger && challenger.avatar ? (
                    <Box width="3.4rem">
                      <img
                        src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${
                          challenger.avatar.image
                        }`}
                      />
                    </Box>
                  ) : (
                    <AccountIcon />
                  )}
                </Box>
                <Flex flexDirection="column" pt=".5rem">
                  <Text as="span" color="black" fontFamily="heading" fontSize="1.4rem" fontWeight="semibold">
                    {challenger.firstname}
                  </Text>
                  <Text as="span" color="black" fontFamily="heading" fontSize="1.4rem" fontWeight="semibold">
                    {scoreChallenger === null ? '?' : scoreChallenger}
                  </Text>
                </Flex>
              </Flex>
            </Col>
            <Col
              flex="0 0 auto"
              pt=".2rem"
              css={`
                color: ${collection.schoolSubject.color};
              `}
            >
              <DuelIcon />
            </Col>
            <Col flex="1" justifyContent="flex-end">
              <Flex>
                <Flex flexDirection="column" pt=".5rem">
                  <Text
                    as="span"
                    color="text"
                    fontFamily="heading"
                    fontSize="1.4rem"
                    fontWeight="semibold"
                    textAlign="right"
                  >
                    {opponent.firstname}
                  </Text>
                  <Text
                    as="span"
                    color="text"
                    fontFamily="heading"
                    fontSize="1.4rem"
                    fontWeight="semibold"
                    textAlign="right"
                  >
                    {scoreOpponent === null ? '?' : scoreOpponent}
                  </Text>
                </Flex>
                <Box pl={1}>
                  {opponent && opponent.avatar ? (
                    <Box width="3.4rem">
                      <img
                        src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${opponent.avatar.image}`}
                      />
                    </Box>
                  ) : (
                    <AccountIcon />
                  )}
                </Box>
              </Flex>
            </Col>
          </Grid>
        </Box>
        <Box pt="1.2rem" px="1.2rem">
          <Grid>
            <Col width={1 / 2} alignItems="center">
              <Box pr={1} pl=".2rem">
                {getIconForGame(
                  game,
                  `width: 3rem; height: 3rem; @media (min-width: ${breakpoints.lg}) { width: 3rem; height: 3rem; }`
                )}
              </Box>
              <Text fontFamily="heading" fontSize="1.2rem" fontWeight="medium">
                {getLabelForGame(game)}
              </Text>
            </Col>
            <Col width={1 / 2} alignItems="center" justifyContent="flex-end">
              <Text as="span" fontFamily="heading" fontSize="1.2rem" fontWeight="medium">
                {getLabelByDifficulty(difficulty)}
              </Text>
            </Col>
          </Grid>
        </Box>
        <Flex py="1.2rem" px="1.5rem" mt={1} flexDirection="column" bg="gray100">
          <Flex alignItems="center" justifyContent="space-between" pb=".6rem">
            <Text as="span" color="text" fontFamily="heading" fontSize="1.2rem">
              {`${createSchoolDegreeLabel(collection.schoolDegrees)} - ${collection.schoolSubject.name}`}
            </Text>
            <Text fontSize="1.2rem" fontWeight="bold" css="text-transform: uppercase;">
              {collection.serialNumber}
            </Text>
          </Flex>
          <Heading as="h5" pb=".2rem" lineHeight="1.4">
            {collection.title}
          </Heading>
          <Text as="span" fontFamily="heading" fontSize="1.2rem" fontWeight="medium">
            {collection.description}
          </Text>
        </Flex>
        {!expired && (
          <Flex flexDirection="column" alignItems="center" px="1.2rem" pt="1.6rem" pb="1.1rem">
            {!hasPlayed && (
              <React.Fragment>
                <Text mb="1rem" fontFamily="heading" fontSize="1.2rem" textAlign="center">
                  Il te reste <b>{expiredAtInDays} jours</b> pour relever le duel
                </Text>
                <LinkButton
                  css="min-width: 14rem;"
                  to={{
                    pathname: `/series/${collection.id}/${getRouteNameForGame(game)}`,
                    search: '?duel=true',
                    state: { difficulty, duel: id },
                  }}
                >
                  Jouer
                </LinkButton>
              </React.Fragment>
            )}
            {hasPlayed && (
              <React.Fragment>
                <Text py="1.5rem" fontFamily="heading" fontSize="1.2rem" textAlign="center">
                  Il ne reste que <b>{expiredAtInDays} jours</b>
                  <br /> a {otherPlayer.firstname} pour relever le duel
                </Text>
              </React.Fragment>
            )}
          </Flex>
        )}
        {expired && (
          <Flex flexDirection="column" alignItems="center" px="1.2rem" py="1.6rem" pb="1.1rem">
            <Text mb="1rem" fontFamily="heading" fontSize="1.2rem" textAlign="center">
              {won > 0 ? 'Tu as gagné !' : won < 0 ? 'Tu as perdu !' : 'Égalité !'}
            </Text>
            <Text mb="1rem" fontFamily="heading" fontSize="1.2rem" textAlign="center">
              Le duel a débuté le <b>{moment(created).format('DD/MM/YYYY')}</b> à{' '}
              <b>{moment(created).format('HH:mm')}</b>
            </Text>
            <LinkButton
              css="min-width: 14rem;"
              to={{
                pathname: `/series/${collection.id}`,
                state: { difficulty },
              }}
            >
              Jouer à nouveau
            </LinkButton>
          </Flex>
        )}
      </Container>
    );
  }
}

export default DuelCard;
