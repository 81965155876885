import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box } from 'rebass';

import { Container, Content } from 'src/components/utilities/Containers';
import Navigation from './Navigation';
import Form from './Form';

class CreateFolder extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    partial: PropTypes.bool,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    partial: false,
  };

  render() {
    const { match, history, partial, onSuccess } = this.props;
    const folderId = match && match.params.id;

    return (
      <Box width={1 / 1}>
        <Navigation partial={partial} folderId={folderId} />
        <Container>
          <Content mt={3}>
            <Box as="section" pt={2}>
              <Form folderId={folderId} partial={partial} onSuccess={onSuccess} history={history} />
            </Box>
          </Content>
        </Container>
      </Box>
    );
  }
}

CreateFolder.Form = Form;
CreateFolder.Navigation = Navigation;

export default CreateFolder;
