"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.bounce = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var rebass_1 = require("rebass");
var theme_1 = require("src/styles/theme");
var styled_components_1 = __importStar(require("styled-components"));
exports.bounce = (0, styled_components_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%,\n  20%,\n  53%,\n    to {\n      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n      transform: translate3d(0, 0, 0);\n    }\n\n    40%,\n    43% {\n      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);\n      transform: translate3d(0, -15px, 0) scaleY(1.1);\n    }\n\n    70% {\n      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);\n      transform: translate3d(0, -7px, 0) scaleY(1.05);\n    }\n\n    80% {\n      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n      transform: translate3d(0, 0, 0) scaleY(0.95);\n    }\n\n    90% {\n      transform: translate3d(0, -2px, 0) scaleY(1.02);\n    }\n"], ["\n  0%,\n  20%,\n  53%,\n    to {\n      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n      transform: translate3d(0, 0, 0);\n    }\n\n    40%,\n    43% {\n      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);\n      transform: translate3d(0, -15px, 0) scaleY(1.1);\n    }\n\n    70% {\n      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);\n      transform: translate3d(0, -7px, 0) scaleY(1.05);\n    }\n\n    80% {\n      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n      transform: translate3d(0, 0, 0) scaleY(0.95);\n    }\n\n    90% {\n      transform: translate3d(0, -2px, 0) scaleY(1.02);\n    }\n"])));
var slideOutDown = (0, styled_components_1.keyframes)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n  25% {\n    transform: translateY(-10%);\n  }\n  100% {\n    transform: translateY(150%);\n    opacity: 0;\n  }\n"], ["\n  0% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n  25% {\n    transform: translateY(-10%);\n  }\n  100% {\n    transform: translateY(150%);\n    opacity: 0;\n  }\n"])));
var slideInUp = (0, styled_components_1.keyframes)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  25% {\n    transform: translateY(-150%);\n    opacity: 0;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n"], ["\n  25% {\n    transform: translateY(-150%);\n    opacity: 0;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n"])));
var ScoreWrapper = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  height: 1.8rem;\n  width: 2rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n"], ["\n  position: relative;\n  height: 1.8rem;\n  width: 2rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n"])));
var AnimatedScore = (0, styled_components_1.default)(rebass_1.Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: end;\n  left: 0;\n  right: 0;\n  text-align: center;\n  transform: translateY(", ");\n  animation: ", " 0.4s ease-in-out;\n  animation-fill-mode: forwards;\n"], ["\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: end;\n  left: 0;\n  right: 0;\n  text-align: center;\n  transform: translateY(", ");\n  animation: ", " 0.4s ease-in-out;\n  animation-fill-mode: forwards;\n"])), function (props) { return props.isExiting ? '0' : '-150%'; }, function (props) { return props.isAnimating ? (props.isExiting ? slideOutDown : slideInUp) : 'none'; });
var PanelContainer = (0, styled_components_1.default)(rebass_1.Flex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  overflow: hidden;\n  width: 10rem;\n  height: 4rem;\n  border-radius: 6px;\n  box-shadow: 2px 2px 5px 0 rgba(147, 147, 147, 0.34);\n  color: ", ";\n  font-weight: ", ";\n\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n  background-color: white;\n  gap: 0.4rem;\n"], ["\n  overflow: hidden;\n  width: 10rem;\n  height: 4rem;\n  border-radius: 6px;\n  box-shadow: 2px 2px 5px 0 rgba(147, 147, 147, 0.34);\n  color: ", ";\n  font-weight: ", ";\n\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n  background-color: white;\n  gap: 0.4rem;\n"])), theme_1.theme.colors.text, theme_1.theme.fontWeights.semibold);
var ScoreContainer = (0, styled_components_1.default)(PanelContainer)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  animation: ", " 0.4s ease-in-out;\n"], ["\n  animation: ", " 0.4s ease-in-out;\n"])), function (props) { return props.isAnimating ? exports.bounce : 'none'; });
var Score = function (_a) {
    var _b = _a.score, score = _b === void 0 ? 0 : _b, _c = _a.fontSize, fontSize = _c === void 0 ? "1.8rem" : _c, t = _a.t;
    var _d = (0, react_1.useState)(score), prevScore = _d[0], setPrevScore = _d[1];
    var _e = (0, react_1.useState)(false), isAnimating = _e[0], setIsAnimating = _e[1];
    (0, react_1.useEffect)(function () {
        if (score !== prevScore) {
            setIsAnimating(true);
            var timer_1 = setTimeout(function () {
                setPrevScore(score);
                setIsAnimating(false);
            }, 400);
            return function () { return clearTimeout(timer_1); };
        }
    }, [score, prevScore]);
    return (React.createElement(ScoreContainer, { isAnimating: isAnimating },
        React.createElement(ScoreWrapper, null,
            React.createElement(AnimatedScore, { fontSize: fontSize, color: theme_1.theme.colors.secondary, isExiting: false, isAnimating: isAnimating }, score),
            React.createElement(AnimatedScore, { fontSize: fontSize, color: theme_1.theme.colors.secondary, isExiting: true, isAnimating: isAnimating }, prevScore)),
        React.createElement(rebass_1.Text, { fontSize: "1.8rem" }, t('pointLowercase', { count: score }))));
};
exports.default = (0, react_i18next_1.withNamespaces)()(Score);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
