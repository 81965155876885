import { Box, Flex } from 'rebass';
import { breakpoints } from 'src/styles/theme';
import styled from 'styled-components';

export const Container = styled(Box)`
  flex: 1 1 auto;
  min-height: 0;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  padding-bottom: 2rem;
  @media (min-width: ${breakpoints.sm}) {
    padding-right: 1.2rem;
    padding-left: 1.2rem;
  }
  @media (min-width: ${breakpoints.md}) {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
  }
  ${props => props.css};
`;

export const Content = styled(Box)`
  max-width: 132rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2rem;
  ${props => props.css};
`;
