import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { Box } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import FolderCard from 'src/components/blocks/CardFolderLarge';
import ListLayout from 'src/layouts/List';
import Select from 'src/components/elements/Select';

class Subsciptions extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    handleFilterChange: PropTypes.func,
    handleSortChange: PropTypes.func,
    filterBy: PropTypes.string,
    folders: PropTypes.object,
    orderBy: PropTypes.string,
    schoolSubjects: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { handleFilterChange, handleSortChange, filterBy, folders, orderBy, schoolSubjects } = this.props;

    return (
      <ListLayout
        icon={<ListLayout.ChainIcon />}
        title="Mes abonnements"
        sort={
          <Select
            name="sort"
            onChange={handleSortChange}
            value={orderBy}
            options={[
              { id: 'newest', name: 'Plus récents' },
              { id: 'oldest', name: 'Plus anciens' },
              { id: 'alphabetical', name: 'De A à Z' },
              { id: 'reverseAlphabetical', name: 'De Z à A' },
            ]}
          />
        }
        filter={
          schoolSubjects.data.subjectsOfTheFoldersToWhichTheUserIsSubscribed ? (
            <Select
              name="filter"
              onChange={handleFilterChange}
              defaultValue="Toutes"
              options={schoolSubjects.data.subjectsOfTheFoldersToWhichTheUserIsSubscribed}
              value={filterBy}
            />
          ) : null
        }
      >
        <Box width={1 / 1}>
          {folders.data.folders && folders.data.folders.results.length > 0 ? (
            <Grid mt={2}>
              {folders.data.folders.results.map((values, key) => {
                return (
                  <Col key={key} width={1 / 1} pb={2}>
                    <FolderCard {...values} />
                  </Col>
                );
              })}
            </Grid>
          ) : folders.data.folders && folders.data.folders.results.length === 0 ? (
            <p>Pas de résultat</p>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </ListLayout>
    );
  }
}

export default Subsciptions;
