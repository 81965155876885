import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { COLLECTIONS_ORDER_BY } from 'src/queries/collections';
import { GET_FOLDERS } from 'src/queries/folders';
import Content from './Content';

class FolderRecommendationsContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    orderBy: 'newest',
  };

  handleChange = (event) => {
    this.setState({
      orderBy: event.target.value,
    });
  };

  render() {
    const { match, user } = this.props;
    const { orderBy } = this.state;

    return (
      <Query
        query={GET_FOLDERS}
        fetchPolicy="network-only"
        variables={{
          filtersBy: [
            { property: 'isOfficial', where: "true" },
            { join: 'schoolSubject', property: 'id', where: match.params.schoolSubject },
            { join: 'schoolDegrees', property: 'id', where: user.schoolDegree.id },
          ],
          orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
        }}
      >
        {({ data, error, loading }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <Content
              handleChange={this.handleChange}
              folders={data.folders}
              match={match}
              orderBy={orderBy}
              user={user}
            />
          );
        }}
      </Query>
    );
  }
}

export default FolderRecommendationsContainer;
