import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { GET_AVATAR_LEVELS } from 'src/queries/avatarLevels';
import { GAME_ASSO, GAME_BRIO, GAME_MEMO, GAME_QUIZAKO, GAME_TYPO, games } from 'src/utils/games';
import { GET_CURRENT_USER } from 'src/queries/users';
import inRange from 'lodash.inrange';
import Content from './Content';

class GameResultContainer extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,
  };

  isContentLoaded = false;
  allTimeScore = null;

  getPlayAgainUrl(collectionId, mode, difficulty) {
    const game =
      (mode === GAME_ASSO && 'association') ||
      (mode === GAME_BRIO && 'brio') ||
      (mode === GAME_MEMO && 'memorisation') ||
      (mode === GAME_TYPO && 'typographie') ||
      (mode === GAME_QUIZAKO && 'quizako') ||
      '';
    return `/series/${collectionId}/${game}/${difficulty}`;
  }

  render() {
    const { match, location } = this.props;
    if (!this.allTimeScore) {
      this.allTimeScore = this.props.user.allTimeScore;
    }

    if (!location.state || !location.state.mode) {
      return <Redirect to="/" />;
    }

    const { difficulty, success, time, score, mode, answers, errors, duel } = location.state;
    const collectionId = match.params.id;
    const isPlaying = games.includes(mode);

    return (
      <Query query={GET_AVATAR_LEVELS} variables={{ type: 'TYPE_STUDENT' }}>
        {avatarLevels => (
          <Query query={GET_CURRENT_USER} fetchPolicy={'network-only'} variables={{ withLinkCollections: true }}>
            {currentUser => {
              // force user fetching for score
              if ((currentUser.loading || avatarLevels.loading) && !this.isContentLoaded) return <div />;
              if (currentUser.error || avatarLevels.error) return <p>Error :(</p>;
              this.isContentLoaded = true;
              const user = currentUser.data.me;
              let newAvatarLevel = null;

              avatarLevels.data.avatarLevels.map(level => {
                if (inRange(level.score, this.allTimeScore + 1, user.allTimeScore + 1)) {
                  newAvatarLevel = level;
                }
              });

              return (
                <Content
                  getPlayAgainUrl={this.getPlayAgainUrl}
                  answers={answers}
                  collectionId={collectionId}
                  difficulty={difficulty}
                  duel={duel}
                  errors={errors}
                  game={mode}
                  isPlaying={isPlaying}
                  score={score}
                  success={success}
                  time={time}
                  user={user}
                  newAvatarLevel={newAvatarLevel}
                />
              );
            }}
          </Query>
        )}
      </Query>
    );
  }
}

export default GameResultContainer;
