import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import CollectionList from 'src/components/blocks/CollectionList';
import ListLayout from 'src/layouts/List';
import Select from 'src/components/elements/Select';

class CollectionRecommendations extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleChange: PropTypes.func,
    collections: PropTypes.object,
    match: PropTypes.object,
    orderBy: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, handleChange, collections, match, orderBy, user } = this.props;

    return (
      <ListLayout
        icon={<ListLayout.CollectionIcon />}
        title={`${t('seriesRecommendations')} ${match.params.schoolSubject} - ${user.schoolDegree.name}`}
        sort={
          <Select
            name="sort"
            onChange={handleChange}
            value={orderBy}
            options={[
              { id: 'newest', name: t('filters.newest') },
              { id: 'oldest', name: t('filters.oldest') },
              { id: 'alphabetical', name: t('filters.alphabetical') },
              { id: 'reverseAlphabetical', name: t('filters.reverseAlphabetical') },
            ]}
          />
        }
      >
        {collections && collections.results.length > 0 ? (
          <CollectionList data={collections.results} />
        ) : (
          <p>{t('noResult')}</p>
        )}
      </ListLayout>
    );
  }
}

export default withNamespaces()(CollectionRecommendations);
