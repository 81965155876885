"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimers = exports.useTimer = void 0;
var react_1 = require("react");
var useTimer = function (ref, timerRef) {
    (0, react_1.useImperativeHandle)(ref, function () {
        var _a, _b;
        return ({
            timer: {
                time: (_b = (_a = timerRef.current) === null || _a === void 0 ? void 0 : _a.time) !== null && _b !== void 0 ? _b : 0,
                resetTime: function (newTime) { var _a; return (_a = timerRef.current) === null || _a === void 0 ? void 0 : _a.resetTime(newTime); },
                startTime: function () { var _a; return (_a = timerRef.current) === null || _a === void 0 ? void 0 : _a.startTime(); },
                stopTime: function () { var _a; return (_a = timerRef.current) === null || _a === void 0 ? void 0 : _a.stopTime(); }
            },
        });
    });
};
exports.useTimer = useTimer;
var useTimers = function (ref, timerRefs, keys) {
    (0, react_1.useImperativeHandle)(ref, function () {
        return keys.reduce(function (acc, key, index) {
            acc[key] = {
                get time() {
                    var _a, _b, _c;
                    return (_c = (_b = (_a = timerRefs[index].current) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.time) !== null && _c !== void 0 ? _c : 0;
                },
                resetTime: function (newTime) { var _a; return (_a = timerRefs[index].current) === null || _a === void 0 ? void 0 : _a.resetTime(newTime); },
                startTime: function () { var _a; return (_a = timerRefs[index].current) === null || _a === void 0 ? void 0 : _a.startTime(); },
                stopTime: function () { var _a; return (_a = timerRefs[index].current) === null || _a === void 0 ? void 0 : _a.stopTime(); }
            };
            return acc;
        }, {});
    });
};
exports.useTimers = useTimers;
