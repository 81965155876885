"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGame = void 0;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var cards_1 = require("src/queries/cards");
var collections_1 = require("src/queries/collections");
var duels_1 = require("src/queries/duels");
var duels_2 = require("src/utils/duels");
var games_1 = require("src/utils/games");
var useGame = function (_a) {
    var collection = _a.collection, difficulty = _a.difficulty, gameType = _a.gameType, isDuelSession = _a.isDuelSession, selectedUser = _a.selectedUser, history = _a.history, initialCards = _a.initialCards, duel = _a.duel, _b = _a.maxErrors, maxErrors = _b === void 0 ? 3 : _b, onGameEnd = _a.onGameEnd;
    var _c = (0, react_1.useState)({
        numOfAnswers: 0,
        numOfErrors: 0,
        score: 0,
        goodAnswersInRow: 0
    }), uiState = _c[0], setUiState = _c[1];
    var game = (0, react_1.useRef)({
        numOfAnswers: 0,
        numOfErrors: 0,
        score: 0,
        goodAnswersInRow: 0
    });
    var contentRef = (0, react_1.useRef)(null);
    var cards = (0, react_1.useMemo)(function () { return initialCards; }, [initialCards]);
    var setCardStatus = (0, client_1.useMutation)(cards_1.SET_CARD_STATUS)[0];
    var finishGame = (0, client_1.useMutation)(collections_1.FINISH_GAME)[0];
    var saveScore = (0, client_1.useMutation)(collections_1.SAVE_SCORE)[0];
    var saveDuelScore = (0, client_1.useMutation)(duels_1.SAVE_DUEL_SCORE)[0];
    var addDuel = (0, client_1.useMutation)(duels_1.ADD_DUEL)[0];
    var getTime = (0, react_1.useCallback)(function () { var _a, _b, _c; return (_c = (_b = (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.timer) === null || _b === void 0 ? void 0 : _b.time) !== null && _c !== void 0 ? _c : 0; }, []);
    var updateGameState = (0, react_1.useCallback)(function (updates) {
        game.current = __assign(__assign({}, game.current), updates);
        setUiState(game.current);
    }, []);
    var handleEndOfTheGame = (0, react_1.useCallback)(function (success_1) {
        var args_1 = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args_1[_i - 1] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([success_1], args_1, true), void 0, function (success, duelId) {
            var _a, score, numOfErrors, goodAnswersInRow, numOfAnswers, time, finalScore;
            if (duelId === void 0) { duelId = null; }
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = game.current, score = _a.score, numOfErrors = _a.numOfErrors, goodAnswersInRow = _a.goodAnswersInRow, numOfAnswers = _a.numOfAnswers;
                        time = getTime();
                        finalScore = (0, games_1.getFinalScore)(score, numOfErrors, goodAnswersInRow);
                        return [4 /*yield*/, finishGame({
                                variables: {
                                    collection: collection.id,
                                    game: gameType,
                                    score: finalScore,
                                    time: time,
                                    won: success,
                                },
                            })];
                    case 1:
                        _b.sent();
                        if (onGameEnd) {
                            onGameEnd(success, duelId);
                        }
                        history.push("/series/".concat(collection.id, "/resultats"), {
                            answers: numOfAnswers,
                            difficulty: difficulty,
                            duel: duelId,
                            errors: numOfErrors,
                            mode: gameType,
                            score: success ? finalScore : 0,
                            success: success,
                            time: time,
                        });
                        return [2 /*return*/];
                }
            });
        });
    }, [collection.id, difficulty, gameType, getTime, history, onGameEnd]);
    var onFinish = (0, react_1.useCallback)(function (success) { return __awaiter(void 0, void 0, void 0, function () {
        var time, errors;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    time = getTime();
                    errors = !success ? maxErrors : game.current.numOfErrors;
                    if (!isDuelSession) return [3 /*break*/, 1];
                    (0, duels_2.handleDuel)(addDuel, saveDuelScore, (_a = duel === null || duel === void 0 ? void 0 : duel.duel.id) !== null && _a !== void 0 ? _a : null, collection.id, selectedUser, gameType, difficulty, game.current.numOfAnswers, (0, games_1.getFinalScore)(game.current.score, errors, game.current.goodAnswersInRow), errors, time, cards, function (duelId) { return handleEndOfTheGame(true, duelId); });
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, saveScore({
                        variables: {
                            collection: collection.id,
                            errors: errors,
                            game: gameType,
                            score: (0, games_1.getFinalScore)(game.current.score, errors, game.current.goodAnswersInRow),
                            time: time,
                        },
                    })];
                case 2:
                    _b.sent();
                    handleEndOfTheGame(success);
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); }, [collection.id, difficulty, gameType, isDuelSession, selectedUser, cards, getTime, handleEndOfTheGame]);
    var onError = (0, react_1.useCallback)(function (cardId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setCardStatus({
                        variables: {
                            card: cardId,
                            status: 'TO_REVIEW',
                        },
                    })];
                case 1:
                    _a.sent();
                    if (game.current.numOfErrors + 1 === maxErrors) {
                        onFinish(false);
                    }
                    updateGameState({
                        score: 0,
                        goodAnswersInRow: 0,
                        numOfAnswers: 0,
                        numOfErrors: game.current.numOfErrors + 1
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [maxErrors, onFinish, updateGameState]);
    var onSuccess = (0, react_1.useCallback)(function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (win, cardId, incrementAnswers, incrementScore) {
            var updates;
            if (win === void 0) { win = false; }
            if (incrementAnswers === void 0) { incrementAnswers = true; }
            if (incrementScore === void 0) { incrementScore = true; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, setCardStatus({
                            variables: {
                                card: cardId,
                                status: 'LEARNED',
                            },
                        })];
                    case 1:
                        _a.sent();
                        updates = {};
                        if (incrementScore) {
                            updates.score = game.current.score + 1;
                        }
                        if (incrementAnswers) {
                            updates.goodAnswersInRow = game.current.goodAnswersInRow + 1;
                            updates.numOfAnswers = game.current.numOfAnswers + 1;
                        }
                        updateGameState(updates);
                        if (win) {
                            onFinish(true);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }, [onFinish, updateGameState]);
    var onPass = (0, react_1.useCallback)(function () {
        updateGameState({
            goodAnswersInRow: 0,
            score: Math.max(0, game.current.score - 1)
        });
    }, [updateGameState]);
    return {
        contentRef: contentRef,
        gameState: game.current,
        uiState: uiState,
        cards: cards,
        actions: {
            onError: onError,
            onSuccess: onSuccess,
            onPass: onPass,
            onFinish: onFinish
        }
    };
};
exports.useGame = useGame;
