import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { adopt } from 'react-adopt';
import { QueryContext } from 'src/context';
import { COLLECTIONS_ORDER_BY, GET_COLLECTIONS } from 'src/queries/collections';
import { GET_DEGREES } from 'src/queries/degrees';
import { GET_FOLDERS } from 'src/queries/folders';
import { GET_SUBJECTS } from 'src/queries/subjects';
import Content from './Content';
class SearchContainer extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
  };

  collections = null;
  folders = null;
  isContentLoaded = false;
  state = {
    orderBy: 'newest',
    filterBy: {
      degree: '',
      subject: '',
    },
    collectionsPage: 1,
    foldersPage: 1,
  };

  handleOrder = event => {
    this.setState({
      orderBy: event.target.value,
    });
  };

  onCollectionsPageChange = collectionsPage => {
    this.setState({
      collectionsPage,
    });
  };

  onFoldersPageChange = foldersPage => {
    this.setState({
      foldersPage,
    });
  };

  onSubjectChange = e => {
    this.setState({
      filterBy: {
        ...this.state.filterBy,
        subject: e.target.value,
      },
    });
  };

  onDegreeChange = e => {
    this.setState({
      filterBy: {
        ...this.state.filterBy,
        degree: e.target.value,
      },
    });
  };

  render() {
    const { match } = this.props;
    const { orderBy, collectionsPage, foldersPage, filterBy } = this.state;
    const searchTerm = match.params.search || null;
    
    const collectionsFilters = [
      { property: 'title', where: searchTerm, search: true },
      { property: 'description', where: searchTerm, search: true },
      { property: 'serialNumber', where: searchTerm, search: true },
      { join: 'author', property: 'firstname, lastname', where: searchTerm, search: true },
      { join: 'author', property: 'lastname, firstname', where: searchTerm, search: true },
    ];
    const foldersFilters = [
      { property: 'title', where: searchTerm, search: true },
      { property: 'serialNumber', where: searchTerm, search: true },
    ];

    // Generate filters
    if (filterBy.degree) {
      const filter = { join: 'schoolDegrees', property: 'id', where: filterBy.degree };
      collectionsFilters.push(filter);
      foldersFilters.push(filter);
    }
    if (filterBy.subject) {
      const filter = { join: 'schoolSubject', property: 'id', where: filterBy.subject };
      collectionsFilters.push(filter);
      foldersFilters.push(filter);
    }

    foldersFilters.push({ property: 'isPublished', where: 'true' });

    /* eslint-disable */
    const queries = {
      degrees: ({ render }) => (
        <Query fetchPolicy={'network-only'} query={GET_DEGREES}>
          {render}
        </Query>
      ),
      subjects: ({ render }) => <Query query={GET_SUBJECTS}>{render}</Query>,
      collections: ({ render }) => (
        <Query
          query={GET_COLLECTIONS}
          fetchPolicy="network-only"
          variables={{
            page: collectionsPage,
            filterBy: collectionsFilters,
            orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
            limit: 20
          }}
        >
          {render}
        </Query>
      ),
      folders: ({ render }) => (
        <Query
          query={GET_FOLDERS}
          fetchPolicy="network-only"
          variables={{
            page: foldersPage,
            filterBy: foldersFilters,
            orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
          }}
        >
          {render}
        </Query>
      ),
    };

    const Composed = adopt(queries);
    /* eslint-enable */

    return (
      <Composed>
        {({ collections, folders, degrees, subjects }) => {
          if (!collections.loading && collections.data.collections) {
            this.collections = collections.data.collections;
          }

          if (!folders.loading && folders.data.folders) {
            this.folders = folders.data.folders;
          }

          if (!degrees.loading && degrees.data.degrees) {
            this.degrees = degrees.data.degrees;
          }

          const queryRefetch = {
            collections: collections.refetch,
            folders: folders.refetch,
          };

          const isLoading = collections.loading || folders.loading || degrees.loading || subjects.loading;
          if (isLoading && !this.isContentLoaded) return <div />;
          if (collections.error || folders.error || degrees.error || subjects.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <QueryContext.Provider value={queryRefetch}>
              <Content
                handleOrder={this.handleOrder}
                onCollectionsPageChange={this.onCollectionsPageChange}
                onFoldersPageChange={this.onFoldersPageChange}
                onSubjectChange={this.onSubjectChange}
                onDegreeChange={this.onDegreeChange}
                collections={this.collections}
                degrees={this.degrees}
                folders={this.folders}
                isLoading={isLoading}
                searchTerm={searchTerm}
                subjects={subjects.data.subjects}
                orderBy={orderBy}
                collectionsPage={collectionsPage}
                foldersPage={foldersPage}
                filterBy={filterBy}
              />
            </QueryContext.Provider>
          );
        }}
      </Composed>
    );
  }
}

export default SearchContainer;
