import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import MediaQuery from 'react-responsive';
import RouterLink from 'react-router-dom/Link';
import { UserContext } from 'src/context';
import styled from 'styled-components';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { ADD_COLLECTION } from 'src/queries/collections';
import { ADD_COLLECTION_TO_FOLDERS, GET_FOLDERS } from 'src/queries/folders';
import { Field, Form } from 'react-final-form';
import { FieldError, handleSubmissionErrors } from 'src/utils/form';
import { Box, Flex, Text } from 'rebass';
import FavoriteButton from 'src/components/blocks/Card/FavoriteButton';
import { Button } from 'src/components/elements/Button';
import CustomSelect from 'src/components/elements/CustomSelect';
import Dropdown from 'src/components/elements/Dropdown';
import FormRow from 'src/components/elements/FormRow';
import Modal from 'src/components/elements/Modal';
import SelectClassroom from 'src/components/elements/Selects/SelectClassrooms';
import { Col, Grid } from 'src/components/utilities/Grids';
import { PROPOSE_COLLECTION } from 'src/queries/propose';
import { breakpoints, theme } from 'src/styles/theme';
import { canEditCollection } from 'src/utils/acl';
import { hasTeacherRole } from 'src/utils/roles';
import ActionDropdown from './ActionDropdown';
import ActionIcon from './ActionIcon';

const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  color: ${theme.colors.text};
  font-size: 1.2rem;
  text-decoration: none;

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

const RealLink = styled.a`
  display: flex;
  align-items: center;
  color: ${theme.colors.text};
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.secondary};
  }
`;

const Item = ({ icon, label, color, onClick }) => {
  return (
    <Text
      onClick={onClick}
      css={`
        cursor: pointer;
        display: flex;
        align-items: center;
        colors: ${theme.colors.text};
        font-size: 1.2rem;
        text-decoration: none;

        &:hover {
          color: ${theme.colors.secondary};
        }
      `}
    >
      <Text as="span" mr=".4rem" color={color}>
        {icon}
      </Text>
      {label}
    </Text>
  );
};

Item.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

Item.defaultProps = {
  color: 'secondary',
};

function formatDuplicateCollection(collection) {
  return {
    title: collection.title,
    description: collection.title,
    cards: collection.cards.map((card) => ({
      frontFace: {
        text: card.frontFace.text,
        description: card.frontFace.description,
        image: card.frontFace.image,
        audio: card.frontFace.audio,
        latexImage: card.frontFace.latexImage,
        latexText: card.frontFace.latexText,
        isCorrect: card.frontFace.isCorrect,
      },
      backFace: card.backFace ? {
        text: card.backFace.text,
        description: card.backFace.description,
        image: card.backFace.image,
        audio: card.backFace.audio,
        latexImage: card.backFace.latexImage,
        latexText: card.backFace.latexText,
        isCorrect: card.backFace.isCorrect,
      } : null,
      backFaces: card.backFaces ? card.backFaces.map((backFace) => ({
        text: backFace.text,
        description: backFace.description,
        image: backFace.image,
        audio: backFace.audio,
        latexImage: backFace.latexImage,
        latexText: backFace.latexText,
        isCorrect: backFace.isCorrect,
      })) : [],
      position: card.position,
      type: card.type,
      fillWithWrongFace: card.fillWithWrongFace,
      
    })),
    schoolDegrees: collection.schoolDegrees.map((schoolDegree) => ({ id: schoolDegree.id })),
    schoolSubject: collection.schoolSubject && { id: collection.schoolSubject.id },
    assoMode: collection.assoMode,
    brioMode: collection.brioMode,
    memoMode: collection.memoMode,
    typoMode: collection.typoMode,
    quizakoMode: collection.quizakoMode,
    ignoreCase: collection.ignoreCase,
    ignoreOrder: collection.ignoreOrder,
    ignoreCharacters: collection.ignoreCharacters,
    ignoreSpaces: collection.ignoreSpaces,
  };
}

class Actions extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    collection: PropTypes.object,
    classrooms: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    addToFolderModal: false,
    proposeToClassroomModal: false,
  };

  duplicate = (duplicateMutation) => {
    const { history } = this.props;
    duplicateMutation()
      .then(({ data: { AddCollection } }) => {
        history.push(`/series/${AddCollection.id}/editer`);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleAddToFolderModal = () => {
    this.setState({
      addToFolderModal: !this.state.addToFolderModal,
    });
  };

  toggleProposeToClassroomModal = () => {
    this.setState({
      proposeToClassroomModal: !this.state.proposeToClassroomModal,
    });
  };

  proposeToClassroom = (data, proposeToClassroomMutation) => {
    return proposeToClassroomMutation({
      variables: {
        classroom: data.classroom.value,
        collection: this.props.collection.id,
      },
    })
      .then(() => {
        this.setState({
          proposeToClassroomModal: false,
        });
      })
      .catch((errors) => handleSubmissionErrors(errors));
  };


  addToFolders = (data, addCollectionToFoldersMutation) => {
    return addCollectionToFoldersMutation({
      variables: {
        folders: data.folders.map((folder) => folder.value),
        collection: this.props.collection.id,
      },
    })
      .then(() => {
        this.setState({
          addToFolderModal: false,
        });
      })
      .catch((errors) => handleSubmissionErrors(errors));
  };

  render() {
    const { t, collection, classrooms, history } = this.props;
    /* eslint-disable */
    const queries = {
      user: ({ render }) => <UserContext.Consumer>{render}</UserContext.Consumer>,
      addCollectionToFolders: ({ render }) => <Mutation mutation={ADD_COLLECTION_TO_FOLDERS}>{render}</Mutation>,
      proposeToClassroom: ({ render }) => <Mutation mutation={PROPOSE_COLLECTION}>{render}</Mutation>,
      duplicateCollection: ({ render }) => (
        <Mutation
          mutation={ADD_COLLECTION}
          variables={{
            input: formatDuplicateCollection(collection),
          }}
        >
          {render}
        </Mutation>
      ),
    };

    const Composed = adopt(queries);
    /* eslint-enable */

    let users = classrooms?.results?.flatMap(classrooms => classrooms.students);
    // eslint-disable-next-line
    users = [...new Set(users)];

    return (
      <Composed>
        {({ user, addCollectionToFolders, duplicateCollection, proposeToClassroom }) => (
          <Grid flexDirection={['column', 'column', 'row']}>
            {!hasTeacherRole(user) &&
              <Col pb={[3, 3, 0]} alignItems="center">
                <ActionDropdown
                  button={<Item icon={<ActionIcon name="gamepad" />} label="Jouer" />}
                >
                  {collection &&
                    <>
                      {collection.assoMode &&
                        <Dropdown.Item
                          label="Asso"
                          icon={<ActionDropdown.AssoIcon />}
                          reverse
                          to={`/series/${collection.id}/association`}
                        />
                      }
                      {collection.typoMode &&
                        <Dropdown.Item
                          label="Typo"
                          icon={<ActionDropdown.TypoIcon />}
                          reverse
                          to={`/series/${collection.id}/typographie`}
                        />
                      }
                      {collection.memoMode &&
                        <Dropdown.Item
                          label="Memo"
                          icon={<ActionDropdown.MemoIcon />}
                          reverse
                          to={`/series/${collection.id}/memorisation`}
                        />
                      }
                      {collection.brioMode &&
                        <Dropdown.Item
                          label="Brio"
                          icon={<ActionDropdown.BrioIcon />}
                          reverse
                          to={`/series/${collection.id}/brio`}
                        />
                      }
                      {collection.quizakoMode &&
                        <Dropdown.Item
                          label="Quizako"
                          icon={<ActionDropdown.TypoIcon />} /* TODO: Quizako icon */
                          reverse
                          to={`/series/${collection.id}/quizako`}
                        />
                      }
                    </>
                  }
                </ActionDropdown>
              </Col>
            }
            {canEditCollection(collection, user) && (
              <>
                <Col pb={[3, 3, 0]}>
                  <Item onClick={this.toggleAddToFolderModal} icon={<ActionIcon name="folder" />} label={t('addToAChain')} />
                </Col>
                <Col pb={[3, 3, 0]}>
                  <Link to={`/series/${collection.id}/editer`}>
                    <Text as="span" mr=".4rem" color="secondary">
                      <ActionIcon name="edit" />
                    </Text>
                    {t('edit')}
                  </Link>
                </Col>
              </>
            )}
            {hasTeacherRole(user) && (
              <>
                <Col pb={[3, 3, 0]}>
                  <Item onClick={() => this.duplicate(duplicateCollection)} icon={<ActionIcon name="duplicate" />} label="Dupliquer" />
                </Col>
                <Col pb={[3, 3, 0]}>
                  <Link to={`/series/${collection.id}/split`}>
                    <Item icon={<ActionIcon name="split" />} label="Scinder" />
                  </Link>
                </Col>
                <Col pb={[3, 3, 0]}>
                  <Link to={`/series/${collection.id}/merge`}>
                    <Item icon={<ActionIcon name="merge" />} label="Fusionner" />
                  </Link>
                </Col>
              </>
            )}
            <Col pb={[3, 3, 0]}>
              <RealLink href={`${process.env.BASENAME}export-collection/pdf/${collection.id}`}>
                <Text as="span" mr=".4rem" color="secondary">
                  <ActionIcon name="download" />
                </Text>
                {t('pdf')}
              </RealLink>
            </Col>
            <Col pb={[3, 3, 0]}>
              <RealLink href={`${process.env.BASENAME}export-collection/csv/${collection.id}`}>
                <Text as="span" mr=".4rem" color="secondary">
                  <ActionIcon name="download" />
                </Text>
                CSV
              </RealLink>
            </Col>
            <Col pb={[3, 3, 0]}>
              <Item
                onClick={() => {
                  window.print();
                }}
                icon={<ActionIcon name="print" />}
                label={t('print')}
              />
            </Col>
            {(!hasTeacherRole(user) || (users && users.length > 0)) && collection.isPublished && (
              <Col pb={[3, 3, 0]} alignItems="center">
                <ActionDropdown
                  button={<Item icon={<ActionIcon name="statistic" />} label="Statistiques" />}
                >
                  {users && users.length > 0 && (
                    <Dropdown.Item
                      to={`/series/${collection.id}/classroom/${classrooms.results[0].id}/stats`}
                      label="Série par classe"
                      icon={<ActionDropdown.StatClassroomIcon />}
                      reverse
                    />
                  )}
                  {hasTeacherRole(user) ?
                    <Dropdown.Item
                      to={`/series/${collection.id}/student/${users[0].id}/stats`}
                      label="Série par élève"
                      icon={<ActionDropdown.StatStudentIcon />}
                      reverse
                    /> :
                    <Dropdown.Item
                      to={`/series/${collection.id}/stats`}
                      label="Mes statistiques"
                      icon={<ActionDropdown.StatStudentIcon />}
                      reverse
                    />
                  }
                </ActionDropdown>
              </Col>
            )}
            {hasTeacherRole(user) && collection.isPublished && (
              <>
                <Col pb={[3, 3, 0]}>
                  <Item
                    onClick={() => {
                      history.push(`/series/${collection.id}/duel`);
                    }}
                    icon={<ActionIcon name="duel" />}
                    label={'Lancer des duels'}
                  />
                </Col>
                <Col pb={[3, 3, 0]}>
                  <Item
                    onClick={() => { this.toggleProposeToClassroomModal(); }}
                    icon={<ActionIcon name="share" />}
                    label={'Proposer à une classe'}
                  />
                </Col>
              </>
            )}
            <MediaQuery minWidth={breakpoints.md}>
              <Col>
                <FavoriteButton
                  collection={collection.id}
                  isFavorite={
                    collection.linkUsers && collection.linkUsers[0] ? collection.linkUsers[0].isFavorite : false
                  }
                />
              </Col>
            </MediaQuery>
            <Modal
              isOpen={this.state.addToFolderModal}
              showCloseButton={true}
              style={{ maxWidth: '65rem' }}
              onRequestClose={this.toggleAddToFolderModal}
            >
              <Box pt={3} pb={2} px={[1, 2, 2]}>
                <Text as="h4" fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
                  {t('addToAChain')}
                </Text>
                <Form
                  onSubmit={(data) => this.addToFolders(data, addCollectionToFolders)}
                  initialValues={{
                    folders: collection.folders.map((folder) => ({ value: folder.id, label: folder.title })),
                  }}
                  keepDirtyOnReinitialize={true}
                  render={({ handleSubmit, submitting, pristine }) => (
                    <Flex
                      as="form"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      onSubmit={handleSubmit}
                    >
                      <FieldError name="error" />
                      <Query
                        query={GET_FOLDERS}
                        fetchPolicy='network-only'
                        variables={{
                          filterBy: [{ property: 'author', where: user.id }],
                        }}
                      >
                        {(folders) => {
                          if (folders.loading) return null;
                          return (
                            <Field name="folders">
                              {({ input }) => {
                                const { data, loading } = folders;
                                const options =
                                  (data.folders &&
                                    data.folders.results.length > 0 &&
                                    data.folders.results.map((folder) => ({
                                      label: folder.title,
                                      value: folder.id,
                                    }))) ||
                                  [];

                                return (
                                  <FormRow
                                    id="folders"
                                    label={t('addToAChain')}
                                    field={
                                      <CustomSelect
                                        {...input}
                                        isMulti={true}
                                        isClearable={false}
                                        isLoading={loading}
                                        options={options}
                                        placeholder={t('searchInTheList')}
                                        truncateLabel={t('chainLowercase', {
                                          count: input.value ? input.value.length : 0,
                                        })}
                                      />
                                    }
                                  />
                                );
                              }}
                            </Field>
                          );
                        }}
                      </Query>
                      <Button type="submit" disabled={submitting || pristine} css="min-width: 30rem; margin-top: 1rem">
                        {t('add')}
                      </Button>
                    </Flex>
                  )}
                />
              </Box>
            </Modal>
            <Modal
              isOpen={this.state.proposeToClassroomModal}
              showCloseButton={true}
              style={{ maxWidth: '65rem' }}
              onRequestClose={this.toggleProposeToClassroomModal}
            >
              <Box pt={3} pb={2} px={[1, 2, 2]}>
                <Text as="h4" fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
                  {"Proposer à une classe"}
                </Text>
                <Form
                  onSubmit={(data) => {
                    if (!data.classroom) {
                      return { classroom: "Veuillez sélectionner une classe" };
                    }
                    return this.proposeToClassroom(data, proposeToClassroom);
                  }}
                  initialValues={{
                    classroom: null,
                  }}
                  keepDirtyOnReinitialize={true}
                  render={({ handleSubmit }) => (
                    <Flex
                      as="form"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      css={"gap: 4rem; margin-top: 4rem;"}
                      onSubmit={handleSubmit}
                    >
                      <FieldError name="classroom" />
                      <FormRow
                        id="classroom"
                        name="classroom"
                        label={"Proposer cette série à une classe"}
                        css={"width: 80%;"}
                        field={<Field
                          name="classroom"
                          component={({ input }) => (
                            <SelectClassroom
                              value={input.value}
                              onChange={(value) => input.onChange(value)}
                              placeholder={"Sélectionnez une classe"}
                              isClearable={false}
                              options={classrooms?.results.map((classroom) => ({
                                label: classroom.name,
                                value: classroom.id,
                              }))}
                            />
                          )}
                        />
                        }
                      />
                      <Button type="submit" css="min-width: 30rem; margin-top: 1rem">
                        {"Valider"}
                      </Button>
                    </Flex>
                  )}
                />
              </Box>
            </Modal>
          </Grid >
        )
        }
      </Composed>
    );
  }
}

export default withNamespaces()(Actions);
