import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ModalContext } from 'src/context';
import { Query } from '@apollo/client/react/components';
import { GET_FOLDER } from 'src/queries/folders';
import { COLLECTIONS_ORDER_BY } from 'src/queries/collections';

import { Container, Content } from 'src/components/utilities/Containers';
import { Box, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import Card from 'src/components/blocks/Card';
import CollectionList from 'src/components/blocks/CollectionList';
import Chain from 'assets/svg/chain.svg';
import Pagination from 'src/components/blocks/Pagination';
import Select from 'src/components/elements/Select';
import Title from 'src/components/typography/Title';

import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import { canEditFolder } from 'src/utils/acl';

const ChainIcon = styled(Chain)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

class Folder extends Component {
  static contextType = ModalContext;
  static propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.isAuthor = false;
    this.state = {
      collectionsOrderBy: 'newest',
      tab: 0,
      page: 1,
    };
  }

  onSort = (event) => {
    this.setState({
      collectionsOrderBy: event.target.value,
    });
  };

  onPageChange = (page) => {
    this.setState({
      page,
    });
  };

  render() {
    const { match, user } = this.props;
    const { collectionsOrderBy, page } = this.state;

    return (
      <Query
        query={GET_FOLDER}
        fetchPolicy={'network-only'}
        variables={{
          id: match.params.id,
          collectionPage: page,
          collectionsOrderBy: [COLLECTIONS_ORDER_BY[collectionsOrderBy]],
        }}
      >
        {({ error, data, loading }) => {
          if (error) return <p>Error :(</p>;

          if (loading) {
            return <p>Chargement...</p>;
          }

          const { folder } = data;

          this.isAuthor = folder.author.id === user.id;
          const { collections } = folder;

          return (
            <Container>
              <Content mt={3}>
                <Grid justifyContent="space-between">
                  <Col>
                    <Grid>
                      <Col>
                        <Title as="h1" icon={<ChainIcon />} medium>
                          {folder.title}
                        </Title>
                      </Col>
                      <Col alignItems="center">
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="1.2rem"
                          fontWeight="semibold"
                          css="text-transform: uppercase;"
                        >
                          Par {folder.author.firstname} {folder.author.lastname}
                        </Text>
                      </Col>
                    </Grid>
                  </Col>
                  <Col alignItems="center" justifyContent="flex-end">
                    <Grid justifyContent="flex-end">
                      <Col>
                        <Select
                          name="sort"
                          onChange={this.onSort}
                          value={collectionsOrderBy}
                          options={[
                            { id: 'newest', name: 'Plus récents' },
                            { id: 'oldest', name: 'Plus anciens' },
                            { id: 'alphabetical', name: 'De A à Z' },
                            { id: 'reverseAlphabetical', name: 'De Z à A' },
                          ]}
                        />
                      </Col>
                    </Grid>
                  </Col>
                  {canEditFolder(folder, user) && (
                    <Col width={1 / 1}>
                      <Card.FolderAuthorActions
                        id={folder.id}
                        isPublished={folder.isPublished}
                        cssContainer="width: 26rem; padding: 2rem 0 0 !important; background-color: transparent;"
                      />
                    </Col>
                  )}
                </Grid>
                <Box as="section" pt={1}>
                  <CollectionList data={collections.results} />
                </Box>
                {collections.count > Pagination.limit && (
                  <Pagination total={collections.count} page={page} onPageChange={this.onPageChange} />
                )}
              </Content>
            </Container>
          );
        }}
      </Query>
    );
  }
}

export default Folder;
