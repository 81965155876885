import { Tabs } from 'react-tabs';
import TabList from './TabList';
import Tab from './Tab';
import TabPanel from './TabPanel';
import TabSwiperButton from './TabSwiperButton';

Tabs.TabList = TabList;
Tabs.Tab = Tab;
Tabs.TabPanel = TabPanel;
Tabs.TabSwiperButton = TabSwiperButton;

export default Tabs;
