import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Col } from 'src/components/utilities/Grids';
import DuelCard from 'src/components/blocks/DuelCard';

const DuelList = ({ data, expired, user }) => (
  <Grid mt={2}>
    {data.map((values, key) => {
      values = values.node || values;
      return (
        <Col key={key} width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
          <DuelCard duel={values} expired={expired} user={user} />
        </Col>
      );
    })}
  </Grid>
);

DuelList.propTypes = {
  data: PropTypes.array,
  expired: PropTypes.bool,
  user: PropTypes.object,
};

export default DuelList;
