import { Query } from '@apollo/client/react/components';
import { cloneDeep } from '@apollo/client/utilities';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { adopt } from 'react-adopt';
import { GET_DUELS } from 'src/queries/duels';
import Content from './Content';

const FILTERS = {
  0: {},
  1: { old: true },
};

class DuelsContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    tab: 0,
    filters: FILTERS[0],
    page: 1,
  };

  getFilters = tabIndex => {
    return FILTERS[tabIndex];
  };

  onTabChange = index => {
    this.setState({
      tab: index,
      filters: this.getFilters(index),
      cursor: null,
    });
  };

  onPageChange = page => {
    this.setState({
      page,
    });
  };

  render() {
    const { user } = this.props;
    const { tab, filters, page } = this.state;

    /*eslint-disable */
    const queries = {
      duels: ({ render }) => (
        <Query
          query={GET_DUELS}
          fetchPolicy="no-cache"
          variables={{
            page: page,
            ...filters,
          }}
        >
          {render}
        </Query>
      ),
    };

    const Composed = adopt(queries);
    /*eslint-enable */

    return (
      <Composed>
        {({ duels }) => {
          if (duels.loading && !this.isContentLoaded) return <div />;
          if (duels.error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const dataDuels = cloneDeep(duels.data?.duels);
          dataDuels?.results.sort((a, b) => {
            return new Date(b.created) - new Date(a.created);
          });

          return (
            <Content
              onPageChange={this.onPageChange}
              onTabChange={this.onTabChange}
              dataDuels={dataDuels}
              page={page}
              tab={tab}
              user={user}
            />
          );
        }}
      </Composed>
    );
  }
}

export default DuelsContainer;
