"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Flip = exports.CardFlipInner = exports.CardFlipFront = exports.CardFlipBack = void 0;
var React = __importStar(require("react"));
var rebass_1 = require("rebass");
var styled_components_1 = __importStar(require("styled-components"));
var SideElement_1 = require("./Cards/SideElement");
var Container = (0, styled_components_1.default)(rebass_1.Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: transparent;\n  margin: 2rem auto;\n  ", "\n  ", "\n"], ["\n  background-color: transparent;\n  margin: 2rem auto;\n  ", "\n  ", "\n"])), SideElement_1.sideContainerSize, function (props) { return props.css; });
var CardFlipInner = styled_components_1.default.div.attrs({})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  text-align: center;\n  width: 100%;\n  height: 100%;\n  transition: transform 0.6s;\n  transform-style: preserve-3d;\n  \n  ", "\n"], ["\n  position: relative;\n  text-align: center;\n  width: 100%;\n  height: 100%;\n  transition: transform 0.6s;\n  transform-style: preserve-3d;\n  \n  ", "\n"])), function (props) { return props.isFlipped && "\n    transform: rotateY(180deg);\n  "; });
exports.CardFlipInner = CardFlipInner;
var cardFlipInnerStyle = (0, styled_components_1.css)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  backface-visibility: hidden;\n  -webkit-backface-visibility: hidden;\n  border-radius: 1rem;\n  transition: transform 0.6s;\n"], ["\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  backface-visibility: hidden;\n  -webkit-backface-visibility: hidden;\n  border-radius: 1rem;\n  transition: transform 0.6s;\n"])));
var CardFlipFront = styled_components_1.default.div.attrs({})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  color: black;\n  transform: rotateY(0deg);\n  z-index: 2;\n"], ["\n  ", "\n  color: black;\n  transform: rotateY(0deg);\n  z-index: 2;\n"])), cardFlipInnerStyle);
exports.CardFlipFront = CardFlipFront;
var CardFlipBack = styled_components_1.default.div.attrs({})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", "\n  color: white;\n  transform: rotateY(180deg);\n  position: absolute;\n  top: 0;\n  left: 0;\n"], ["\n  ", "\n  color: white;\n  transform: rotateY(180deg);\n  position: absolute;\n  top: 0;\n  left: 0;\n"])), cardFlipInnerStyle);
exports.CardFlipBack = CardFlipBack;
var Flip = function (_a) {
    var children = _a.children, isFlipped = _a.isFlipped, flexProps = __rest(_a, ["children", "isFlipped"]);
    return (React.createElement(Container, __assign({}, flexProps),
        React.createElement(CardFlipInner, { isFlipped: isFlipped }, children)));
};
exports.Flip = Flip;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
