import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Box, Text } from 'rebass';
import paginator from 'paginator';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import Chevron from 'assets/svg/chevron.svg';

const List = styled(Box)`
  display: inline-flex;
  padding-left: 0;
  vertical-align: top;
  list-style: none;
`;

const Item = styled(Box)`
  &:not(:last-child) {
    margin-right: 0.9rem;
  }

  ${props =>
    props.disabled &&
    `
    opacity: 0;
    pointer-events: none;
  `};
`;

const Link = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 3.2rem;
  height: 3.2rem;
  background-color: ${props => (props.current ? theme.colors.primary : 'transparent')};
  border-radius: 50%;
  color: ${props => (props.current ? theme.colors.white : theme.colors.black)};
  font-size: 1.2rem;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 225ms ease, color 225ms ease;

  &:hover {
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
`;

const chevronStyle = css`
  ${svgStyle};
  height: 1.2rem;
`;

const ChevronRightIcon = styled(Chevron)`
  ${chevronStyle};
  padding-left: 0.2rem;
`;

const ChevronLeftIcon = styled(Chevron)`
  ${chevronStyle};
  transform: rotate(180deg);
`;

class Pagination extends PureComponent {
  static propTypes = {
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
  };

  static limit = process.env.LIMIT_PER_PAGE;

  previous = () => {
    const { page, onPageChange } = this.props;

    if (page - 1 > 0) {
      onPageChange(page - 1);
    }
  };

  next = () => {
    const { page, onPageChange, total } = this.props;
    const totalPages = Math.ceil(total / Pagination.limit);

    if (page + 1 <= totalPages) {
      onPageChange(page + 1);
    }
  };

  render() {
    const pageRangedDisplayed = 10;
    const { page, total, onPageChange } = this.props;
    const pages = [];
    const paginationInfo = new paginator(Pagination.limit, pageRangedDisplayed).build(total, page);

    for (let i = paginationInfo.first_page; i <= paginationInfo.last_page; i++) {
      pages.push(
        <Item as="li" key={i}>
          <Link current={i === page} onClick={() => onPageChange(i)}>
            {i}
          </Link>
        </Item>
      );
    }

    return (
      <Text as="nav" textAlign="center" aria-label="pagination">
        <List as="ul">
          <Item as="li" disabled={!paginationInfo.has_previous_page}>
            <Link onClick={this.previous}>
              <ChevronLeftIcon />
            </Link>
          </Item>
          {pages}
          <Item as="li" disabled={!paginationInfo.has_previous_page}>
            <Link onClick={this.next}>
              <ChevronRightIcon />
            </Link>
          </Item>
        </List>
      </Text>
    );
  }
}

export default Pagination;
