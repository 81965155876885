import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GET_DUEL } from 'src/queries/duels';
import { hasWon } from 'src/utils/duels';
import Content from './Content';

class DuelContainer extends Component {
  static propTypes = {
    duel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user: PropTypes.object,
  };

  isContentLoaded = false;

  render() {
    const { duel, user } = this.props;

    return (
      <Query query={GET_DUEL} variables={{ id: duel }} pollInterval={3000} fetchPolicy={'cache-and-network'}>
        {({ loading, error, data }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const opponent = data.duel?.opponent;
          const challenger = data.duel?.challenger;
          const scoreOpponent = data.duel?.scoreOpponent;
          const scoreChallenger = data.duel?.scoreChallenger;
          const otherPlayer = user.id === opponent.id ? challenger : opponent;
          const hasPlayed = scoreOpponent != null && scoreChallenger != null;

          return (
            <Content data={data} hasPlayed={hasPlayed} hasWon={hasWon(user, data.duel)} otherPlayer={otherPlayer} />
          );
        }}
      </Query>
    );
  }
}

export default DuelContainer;
