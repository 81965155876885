import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldError } from 'src/utils/form';
import { Button } from 'src/components/elements/Button';
import { Col, Grid } from 'src/components/utilities/Grids';
import FormRow from 'src/components/elements/FormRow';
import Select from 'src/components/elements/Select';

class StudentForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    user: PropTypes.object,
    degrees: PropTypes.object,
    onSubmit: PropTypes.func,
  };

  render() {
    const { t, user, degrees, onSubmit } = this.props;
    const hasSchool = user.schools.length > 0;
    let filteredDegrees = [];
    const defaultOption = { name: t('select') };

    if (hasSchool) {
      filteredDegrees = [defaultOption, ...user.schools[0].schoolLevel.schoolDegrees];
    } else if (degrees.data.degrees) {
      filteredDegrees = [defaultOption, ...degrees.data.degrees];
    }

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{
          schoolDegree: user.schoolDegree && user.schoolDegree.id,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <Grid as="form" onSubmit={handleSubmit}>
            <Col width={1 / 1} pb={3}>
              <Field name="schoolDegree" type="select">
                {({ input }) => (
                  <FormRow
                    id="schoolDegree"
                    label={t('yourDegree')}
                    field={
                      <Select
                        id="schoolDegree"
                        {...input}
                        options={filteredDegrees}
                        required={true}
                        css="width: 100%;"
                      />
                    }
                    error={<FieldError name="schoolDegree" />}
                  />
                )}
              </Field>
            </Col>
            <Col width={1 / 1} justifyContent={['center', 'center', 'center', 'flex-start']}>
              <Button type="submit" disabled={submitting} css="width: 100%; max-width: 22rem;">
                {t('validate')}
              </Button>
            </Col>
          </Grid>
        )}
      </Form>
    );
  }
}

export default withNamespaces()(StudentForm);
