import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import CollectionList from 'src/components/blocks/CollectionList';
import ListLayout from 'src/layouts/List';
import Select from 'src/components/elements/Select';
import Title from 'src/components/typography/Title';

class CollectionsToReview extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleChange: PropTypes.func,
    collections: PropTypes.object,
    count: PropTypes.number,
    orderBy: PropTypes.string,
  };

  isContentLoaded = false;

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, handleChange, collections, count, orderBy } = this.props;

    return (
      <ListLayout
        icon={<ListLayout.RetryIcon />}
        title={t('mySeriesToReview')}
        sort={
          <Select
            name="sort"
            onChange={handleChange}
            value={orderBy}
            options={[
              { id: 'newest', name: 'Plus récents' },
              { id: 'oldest', name: 'Plus anciens' },
              { id: 'alphabetical', name: 'De A à Z' },
              { id: 'reverseAlphabetical', name: 'De Z à A' },
            ]}
          />
        }
      >
        <Box width={1 / 1}>
          {collections && count > 0 ? (
            Object.keys(collections).map((key, index) => (
              <Box key={`${index}-${index}`} as="section" pt={3}>
                <Title sm medium css="text-transform: capitalize;">
                  {key}
                </Title>
                <CollectionList data={collections[key]} />
              </Box>
            ))
          ) : (
            <p>{t('noResult')}</p>
          )}
        </Box>
      </ListLayout>
    );
  }
}

export default withNamespaces()(CollectionsToReview);
