"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var client_1 = require("@apollo/client");
var React = __importStar(require("react"));
var context_1 = require("src/context");
var classrooms_1 = require("src/queries/classrooms");
var rebass_1 = require("rebass");
var Button_1 = require("src/components/elements/Button");
var JoinClassroom = function (props) {
    var loadingContext = React.useContext(context_1.LoadingContext);
    //@ts-expect-error
    var classroomId = props.match.params.id;
    var _a = React.useState(false), hasJoined = _a[0], setHasJoined = _a[1];
    var _b = React.useState(false), error = _b[0], setError = _b[1];
    var _c = (0, client_1.useQuery)(classrooms_1.RESTRICTED_CLASSROOMS, { variables: { id: classroomId }, skip: !classroomId }), queryData = _c.data, loading = _c.loading;
    var joinClassroom = (0, client_1.useMutation)(classrooms_1.JOIN_CLASSROOM, { variables: { classroom: classroomId } })[0];
    var handleJoinClassroom = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingContext.addLoading();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, joinClassroom().then(function () {
                            setHasJoined(true);
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    setError(true);
                    return [2 /*return*/, error_1];
                case 4:
                    loadingContext.removeLoading();
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        if (classroomId && !loading) {
            loadingContext.removeLoading();
        }
    }, [queryData]);
    return (React.createElement(rebass_1.Box, { width: "100%", pt: 5, marginTop: 'auto', marginBottom: 'auto' },
        hasJoined ? React.createElement(rebass_1.Box, { py: "1rem", css: "max-width: 54rem; margin-left:auto; margin-right:auto;" },
            React.createElement(rebass_1.Text, { pb: 2, fontSize: ['1.4rem', '1.4rem', '2rem'], textAlign: "center" }, "Vous avez rejoint la classe")) :
            React.createElement(rebass_1.Box, { py: "1rem", css: "max-width: 54rem; margin-left:auto; margin-right:auto;" },
                React.createElement(rebass_1.Text, { pb: 2, fontSize: ['1.4rem', '1.4rem', '2rem'], textAlign: "center" }, "Voulez-vous rejoindre la classe ?"),
                React.createElement(rebass_1.Text, { pb: 2, fontSize: ['1.4rem', '1.4rem', '2.3rem'], textAlign: "center", fontWeight: 'bold' }, queryData && queryData.classroom ? queryData.classroom.name : '')),
        error && (React.createElement(rebass_1.Box, { py: "1rem", css: "max-width: 54rem; margin-left:auto; margin-right:auto;" },
            React.createElement(rebass_1.Text, { pb: 2, fontSize: ['1.4rem', '1.4rem', '1.4rem'], textAlign: "center", color: 'red' }, "Une erreur est survenue, assurez-vous d'avoir un profil élève pour rejoindre une classe."))),
        React.createElement(rebass_1.Text, { pt: 3, pb: "2rem", textAlign: "center" }, !hasJoined ? React.createElement(Button_1.Button, { css: "width: 100%; max-width: 18rem; margin: auto;", onClick: handleJoinClassroom, disabled: error }, "Rejoindre la classe")
            : React.createElement(Button_1.Button, { css: "width: 100%; max-width: 20rem; margin: auto;", onClick: function () { return props.history.push('/'); }, secondary: true }, "Retourner à l'accueil"))));
};
exports.default = JoinClassroom;
