import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Query } from '@apollo/client/react/components';
import { USERS } from 'src/queries/users';

import { Box, Flex, Text } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { Button } from 'src/components/elements/Button';
import FriendItem from 'src/components/blocks/FriendItem';
import Input from 'src/components/elements/InputSearchbar';
import { SearchIconBtn, SearchIcon } from 'src/pages/Onboarding/Icons';
import Card from 'src/components/blocks/Card';
import Badge from 'src/components/elements/Badge';

class DuelProvoke extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    onSearchChange: PropTypes.func,
    selectFriend: PropTypes.func,
    provokeDuel: PropTypes.func,
    addDuel: PropTypes.func,
    collectionData: PropTypes.object,
    difficulty: PropTypes.string,
    game: PropTypes.string,
    search: PropTypes.string,
    selectedUser: PropTypes.string,
    userData: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      onSearchChange,
      selectFriend,
      provokeDuel,
      addDuel,
      collectionData,
      difficulty,
      game,
      search,
      selectedUser,
      userData,
    } = this.props;

    return (
      <Box width={1 / 1}>
        <Container css="flex: 0 0 auto; padding-bottom: 3rem;">
          <Box bg="white">
            <Container>
              <Content>
                <Grid pt={[1]} pb={2}>
                  <Col flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 14rem']}>
                    <Badge game={game} difficulty={difficulty} />
                  </Col>
                  <Col
                    flex={['0 0 100%', '0 0 100%', '0 0 100%', '1 1 auto']}
                    alignItems="center"
                    justifyContent="center"
                    pb={[0, 0, 0, 2]}
                  >
                    <Text fontFamily="heading" fontSize={['1.6rem', '1.6rem', '2.4rem']}>
                      {t('provokeAFriend')}
                    </Text>
                  </Col>
                  <Col flex={['0 0 100%', '0 0 100%', '0 0 14rem']} />
                </Grid>
              </Content>
            </Container>
          </Box>
          <Content py={2}>
            <Box css="width: 100%; max-width: 43rem; margin: auto;">
              {collectionData && <Card.Collection {...collectionData} />}
            </Box>
            <Box
              as="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              pt={[3, 3, 4]}
              css="width: 100%; max-width: 43rem; margin: auto;"
            >
              <Text
                as="label"
                htmlFor="search"
                fontSize={['1.4rem', '1.4rem', '1.2rem']}
                fontWeight={['semibold', 'semibold', 'normal']}
              >
                {t('searchAFriend')}
              </Text>
              <Flex alignItems="center" pt={1} css="width: 100%;">
                <Input
                  id="search"
                  sm
                  css="flex: 1 1 100%; margin-right: 1rem"
                  placeholder="Recherche un ID"
                  value={search}
                  onChange={onSearchChange}
                />
                <Button secondary smIcon css="flex: 0 0 auto;" type="submit">
                  <SearchIconBtn />
                </Button>
              </Flex>
            </Box>
          </Content>
        </Container>
        <Container bg="gray100">
          {userData && (
            <Content py={3}>
              <Query
                query={USERS}
                variables={{
                  filterBy: [
                    { property: 'username', where: search },
                    { property: 'firstname', where: search },
                    { property: 'lastname', where: search },
                  ],
                  onlyStudent: true,
                }}
              >
                {({ data }) => {
                  return (
                    <Box pb="6rem">
                      <Text color="primary" fontWeight="semibold">
                        {t('resultWithCount', { count: data && data.users ? data.users.results.length : 0 })}
                      </Text>
                      {data &&
                        data.users && (
                          <Grid py={2}>
                            {data.users &&
                              data.users.results.map((user) => (
                                <Col key={`search${user.id}`} width={[1 / 1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} pb={1}>
                                  <FriendItem
                                    friend={user}
                                    isAdded={selectedUser === user.id}
                                    onAddFriend={selectFriend(user.id)}
                                    onRemoveFriend={selectFriend(null)}
                                    selectedMode={true}
                                  />
                                </Col>
                              ))}
                          </Grid>
                        )}
                    </Box>
                  );
                }}
              </Query>
              <Box>
                <Text color="primary" fontWeight="semibold">
                  {t('myFriends')} ({userData.friends.length})
                </Text>
                {userData.friends.length > 0 && (
                  <Grid py={2}>
                    {userData.friends.map((friend) => (
                      <Col key={friend.id} width={[1 / 1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]} pb={1}>
                        <FriendItem
                          friend={friend}
                          isAdded={selectedUser === friend.id}
                          onAddFriend={selectFriend(friend.id)}
                          onRemoveFriend={selectFriend(null)}
                          selectedMode={true}
                        />
                      </Col>
                    ))}
                  </Grid>
                )}
                {userData &&
                  userData.friends.length === 0 && (
                    <Box py="6rem" css="max-width: 54rem; margin: auto;">
                      <Text pb={4} color="primary" textAlign="center" css="opacity: .3;">
                        <SearchIcon />
                      </Text>
                      <Text pb={2} fontSize={['1.4rem', '1.4rem', '2rem']} textAlign="center">
                        {t('noFriends')}
                      </Text>
                      <Text fontSize={['1.2rem', '1.2rem', '1.4rem']} fontWeight="semibold" textAlign="center">
                        {t('noFriendsWarning')}
                      </Text>
                    </Box>
                  )}
              </Box>

              <Flex pt="5rem" pb={3} justifyContent="center">
                <Button onClick={provokeDuel(addDuel)} css="width: 100%; max-width: 22rem;">
                  {t('provokeADuel')}
                </Button>
              </Flex>
            </Content>
          )}
        </Container>
      </Box>
    );
  }
}

export default withNamespaces()(DuelProvoke);
