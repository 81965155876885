import { Mutation, Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Box } from 'rebass';
import CreateFolder from 'src/pages/CreateFolder';
import { COLLECTIONS_ORDER_BY, GET_COLLECTIONS } from 'src/queries/collections';
import { GET_DEGREES } from 'src/queries/degrees';
import { GET_FOLDER, SET_COLLECTIONS_OF_FOLDER } from 'src/queries/folders';
import { GET_SUBJECTS } from 'src/queries/subjects';
import Content from './Content';
import { ContentLoading } from "src/components/elements/Loading"

class AddCollectionToFolderContainer extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
  };

  state = {
    collectionsOrderBy: 'newest',
    collectionsOfFolders: null,
    subject: '',
    degree: '',
    search: '',
    authorCollectionsPage: 1,
    officialCollectionsPage: 1,
    collectionsPage: 1,
    tabsIndex: 0
  };

  defaultCollectionsOfFolders = [];

  onAuthorCollectionsPageChange = (authorCollectionsPage) => {
    this.setState({
      authorCollectionsPage: authorCollectionsPage,
    });
  };

  onCollectionsPageChange = (collectionsPage) => {
    this.setState({
      collectionsPage: collectionsPage,
    });
  };

  onOfficialCollectionsPageChange = (officialCollectionsPage) => {
    this.setState({
      officialCollectionsPage: officialCollectionsPage,
    });
  };

  handleChangeOrder = (event) => {
    this.setState({
      collectionsOrderBy: event.target.value,
    });
  };

  handleChangeSubject = (event) => {
    this.setState({
      subject: event.target.value,
      authorCollectionsPage: 1,
      officialCollectionsPage: 1,
      collectionsPage: 1,
    });
  };

  handleChangeDegree = (event) => {
    this.setState({
      degree: event.target.value,
      authorCollectionsPage: 1,
      officialCollectionsPage: 1,
      collectionsPage: 1,
    });
  };
  
  handleSearchChange = (search) => {
    this.setState({
      search: search,
      authorCollectionsPage: 1,
      officialCollectionsPage: 1,
      collectionsPage: 1
    });
  }

  toggleCollectionInFolder = (id) => {
    const collectionsOfFolders = this.state.collectionsOfFolders || this.defaultCollectionsOfFolders;
    if (collectionsOfFolders.includes(id)) {
      this.setState({
        collectionsOfFolders: collectionsOfFolders.filter((value) => value !== id),
      });
    } else {
      this.setState({
        collectionsOfFolders: [...collectionsOfFolders, id],
      });
    }
  };

  onChangeTabsIndex = (i) => {
    this.setState({
      tabsIndex: i
    })
  }

  render() {
    const { match, history, user } = this.props;
    const {
      collectionsOrderBy,
      collectionsOfFolders,
      degree,
      search,
      subject,
      authorCollectionsPage,
      officialCollectionsPage,
      collectionsPage,
      tabsIndex
    } = this.state;
    const folderId = match.params.id;

    const filtersBy = [];
    if (degree !== '') {
      filtersBy.push({ join: 'schoolDegrees', property: 'id', where: degree });
    }
    if (subject !== '') {
      filtersBy.push({ join: 'schoolSubject', property: 'id', where: subject });
    }

    return (
      <Box width={1 / 1}>
        <CreateFolder.Navigation partial={false} folderId={folderId} currentStep={CreateFolder.Navigation.ADD_SERIES} />
        <Query
          query={GET_FOLDER}
          fetchPolicy={'network-only'}
          variables={{
            id: folderId,
            collectionLimit: 2000,
          }}
        >
          {(folder) => {
            if (folder.error) {
              return <p>Error :(</p>;
            }
            if (folder.loading) {
              return <div/>
            }
            return (
              <Mutation mutation={SET_COLLECTIONS_OF_FOLDER}>
                {(setCollectionOfFolders) => (
                  <Query query={GET_DEGREES}>
                    {(degrees) => (
                      <Query query={GET_SUBJECTS}>
                        {(subjects) => (
                          <Query
                            query={GET_COLLECTIONS}
                            variables={{
                              filterBy: [
                                { property: 'title', where: search, search: true },
                                { property: 'serialNumber', where: search, search: true },
                                { property: 'author', where: user.id },
                                ...filtersBy,
                              ],
                              orderBy: [COLLECTIONS_ORDER_BY[collectionsOrderBy]],
                              page: authorCollectionsPage,
                            }}
                          >
                            {(authorCollections) => (
                              <Query
                                query={GET_COLLECTIONS}
                                variables={{
                                  filterBy: [
                                    { property: 'title', where: search, search: true },
                                    { property: 'serialNumber', where: search, search: true },
                                    { property: 'isOfficial', where: "true" },
                                    ...filtersBy,
                                  ],
                                  orderBy: [COLLECTIONS_ORDER_BY[collectionsOrderBy]],
                                  page: officialCollectionsPage,
                                }}
                              >
                                {(officialCollections) => (
                                  <Query
                                    query={GET_COLLECTIONS}
                                    variables={{
                                      filterBy: [
                                        { property: 'title', where: search, search: true },
                                        { property: 'serialNumber', where: search, search: true },
                                        { property: 'isOfficial', where: "false" },
                                        { property: 'author', not: user.id },
                                        ...filtersBy,
                                      ],
                                      orderBy: [COLLECTIONS_ORDER_BY[collectionsOrderBy]],
                                      page: collectionsPage,
                                    }}
                                  >
                                    {(collections) => {
                                      if (
                                        (degrees.loading ||
                                          subjects.loading ||
                                          setCollectionOfFolders.loading ||
                                          authorCollections.loading ||
                                          officialCollections.loading ||
                                          collections.loading) &&
                                        !this.isContentLoaded
                                      )
                                        return <ContentLoading />;
                                      if (
                                        degrees.error ||
                                        subjects.error ||
                                        setCollectionOfFolders.error ||
                                        authorCollections.error ||
                                        officialCollections.error
                                      )

                                        this.isContentLoaded = true;

                                      const authorCollectionsData =
                                        authorCollections.data && authorCollections.data.collections;
                                      const officialCollectionsData =
                                        officialCollections.data && officialCollections.data.collections;
                                      const collectionsData = collections.data && collections.data.collections;

                                      this.defaultCollectionsOfFolders = folder.data.folder.collections.results.map(
                                        (collection) => collection.id,
                                      );

                                      return (
                                        <Content
                                          handleChangeDegree={this.handleChangeDegree}
                                          handleChangeOrder={this.handleChangeOrder}
                                          handleChangeSubject={this.handleChangeSubject}
                                          handleSearchChange={this.handleSearchChange}
                                          onAuthorCollectionsPageChange={this.onAuthorCollectionsPageChange}
                                          onCollectionsPageChange={this.onCollectionsPageChange}
                                          onOfficialCollectionsPageChange={this.onOfficialCollectionsPageChange}
                                          setCollectionOfFolders={setCollectionOfFolders}
                                          toggleCollectionInFolder={this.toggleCollectionInFolder}
                                          authorCollectionsData={authorCollectionsData}
                                          authorCollectionsPage={authorCollectionsPage}
                                          collectionsData={collectionsData}
                                          collectionsPage={collectionsPage}
                                          collectionsOfFolders={collectionsOfFolders || this.defaultCollectionsOfFolders}
                                          collectionsOrderBy={collectionsOrderBy}
                                          degrees={degrees}
                                          degree={degree}
                                          folderId={folderId}
                                          history={history}
                                          officialCollectionsData={officialCollectionsData}
                                          officialCollectionsPage={officialCollectionsPage}
                                          search={search}
                                          subjects={subjects}
                                          subject={subject}
                                          tabsIndex={tabsIndex}
                                          onChangeTabsIndex={this.onChangeTabsIndex}
                                        />
                                      );
                                    }}
                                  </Query>
                                )}
                              </Query>
                            )}
                          </Query>

                        )}
                      </Query>
                    )}
                  </Query>
                )}
              </Mutation>
            );
          }}
        </Query>
      </Box>
    );
  }
}

export default AddCollectionToFolderContainer;
