import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Flex } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { svgStyle } from 'src/styles/helpers';
import BadgeSvg from 'assets/svg/badge.svg';
import Title from 'src/components/typography/Title';
import Statistic from 'src/components/elements/Statistic';

const BadgeIcon = styled(BadgeSvg)`
  ${svgStyle};
  width: 2.8rem;
  height: 2.8rem;
`;

class Statistics extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, user } = this.props;

    return (
      <Flex css="flex: 1 1 auto; min-height: 0;" bg="gray100">
        <Container>
          <Content>
            <Flex py={2}>
              <Title icon={<BadgeIcon />}>{t('myStatistics')}</Title>
            </Flex>
            <Grid py={['2rem', '2rem', '4rem']}>
              <Col
                alignItems="center"
                justifyContent="center"
                width={[1 / 1, 1 / 1, 1 / 2]}
                pb={['10rem', '10rem', '10rem', '20rem']}
              >
                <Statistic
                  number={user.countCreatedCollections}
                  label={t('serieCreated', { count: user.countCreatedCollections })}
                  icon={Statistic.CollectionIcon}
                />
              </Col>
              <Col
                alignItems="center"
                justifyContent="center"
                width={[1 / 1, 1 / 1, 1 / 2]}
                pb={['10rem', '10rem', '10rem', '20rem']}
              >
                <Statistic
                  number={user.countCreatedAndPublishedCollections}
                  label={t('seriePublished', { count: user.countCreatedAndPublishedCollections })}
                  icon={Statistic.CollectionIcon}
                />
              </Col>
              <Col
                alignItems="center"
                justifyContent="center"
                width={[1 / 1, 1 / 1, 1 / 2]}
                pb={['10rem', '10rem', '10rem', '20rem']}
              >
                <Statistic
                  number={user.countPlayersForCollections}
                  label={t('numberOfStudentsHavingUsedYourSeries', { count: user.countPlayersForCollections })}
                  icon={Statistic.AccountIcon}
                />
              </Col>
              <Col
                alignItems="center"
                justifyContent="center"
                width={[1 / 1, 1 / 1, 1 / 2]}
                pb={['10rem', '10rem', '10rem', '20rem']}
              >
                <Statistic
                  number={user.countPlayersIterationForCollections}
                  label={t('numberOfGamesMadeBasedOnYourSeries', { count: user.countPlayersIterationForCollections })}
                  icon={Statistic.BrainIcon}
                />
              </Col>
            </Grid>
          </Content>
        </Container>
      </Flex>
    );
  }
}

export default withNamespaces()(Statistics);
