import ChainSvg from 'assets/svg/chain.svg';
import ClassroomSvg from 'assets/svg/classroom.svg';
import CollectionSvg from 'assets/svg/collection.svg';
import FavoritesSvg from 'assets/svg/favorites.svg';
import RecommandationsSvg from 'assets/svg/recommandations.svg';
import RetrySvg from 'assets/svg/retry.svg';
import { svgStyle } from 'src/styles/helpers';
import { breakpoints } from 'src/styles/theme';
import styled, { css } from 'styled-components';

const style = css`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

export const ChainIcon = styled(ChainSvg)`
  ${style};
`;

export const ClassroomIcon = styled(ClassroomSvg)`
  ${style};
`;

export const CollectionIcon = styled(CollectionSvg)`
  ${style};
`;

export const FavoritesIcon = styled(FavoritesSvg)`
  ${style};
  .favorite-icon-full {
    fill: transparent;
  }
`;

export const RecommandationsIcon = styled(RecommandationsSvg)`
  ${style};
`;

export const RetryIcon = styled(RetrySvg)`
  ${style};
`;
