import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import { Button } from 'src/components/elements/Button';
import { FieldError } from 'src/utils/form';
import Select from 'src/components/elements/Select';
import Toggle from 'src/components/elements/Toggle';
import FormRow from 'src/components/elements/FormRow';
import Input from 'src/components/elements/Input';
import { Grid, Col } from 'src/components/utilities/Grids';
import Layout from 'src/Layout';
import CustomSelect from 'src/components/elements/CustomSelect';
import uniqBy from 'lodash.uniqby';
import property from 'lodash.property';
import { filteredDegreesWithSubject } from 'src/utils/degrees';

const formValidate = values => {
  let errors = {};

  if (!values.title) {
    errors.title = 'Tu dois renseigner un titre.';
  }

  if (!values.schoolSubject) {
    errors.schoolSubject = 'Tu dois renseigner une discipline.';
  }

  if (!values.schoolDegrees) {
    errors.schoolDegrees = 'Tu dois renseigner au moins un degré.';
  }

  return errors;
};

class CreateFolder extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    normalizeFolder: PropTypes.func,
    onSubmit: PropTypes.func,
    toggleModal: PropTypes.func,
    addFolder: PropTypes.func,
    degrees: PropTypes.object,
    folder: PropTypes.object,
    folderId: PropTypes.string,
    history: PropTypes.object,
    partial: PropTypes.bool,
    removeFolder: PropTypes.func,
    subjects: PropTypes.array,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      normalizeFolder,
      onSubmit,
      toggleModal,
      addFolder,
      degrees,
      folder,
      folderId,
      history,
      partial,
      removeFolder,
      subjects,
    } = this.props;

    return (
      <Form
        onSubmit={data => onSubmit(data, addFolder)}
        keepDirtyOnReinitialize={true}
        initialValues={folder && folder.data.folder ? normalizeFolder(folder.data.folder) : { title: '' }}
        validate={formValidate}
        render={({ handleSubmit, pristine, submitting }) => (
          <Grid as="form" onSubmit={handleSubmit} css="max-width: 42.8rem; margin: auto;">
            <Col width={1 / 1} pb={3}>
              <FormRow
                id="title"
                label={t('nameOfChain')}
                required={true}
                field={
                  <Field
                    name="title"
                    type="text"
                    required={true}
                    component={Input}
                    maxLength="150"
                    placeholder={t('maximumCharactersWithCount', { count: 150 })}
                    css="width: 100%;"
                  />
                }
                error={<FieldError name="title" />}
              />
            </Col>
            {!partial && (
              <Col width={1 / 1} pb={3}>
                <Field name="isPublished" type="checkbox">
                  {({ input }) => {
                    return (
                      <Toggle
                        {...input}
                        onChange={e => {
                          const checked = e.target.checked;

                          if (!checked) {
                            toggleModal(Layout.MODAL_UNPUBLISH_FOLDER, () => input.onChange(checked));
                          } else {
                            input.onChange(checked);
                          }
                        }}
                        defaultLabel={t('draft')}
                        checkedLabel={t('public')}
                      />
                    );
                  }}
                </Field>
              </Col>
            )}
            <Col width={1 / 1} pb={3}>
              <Field name="schoolSubject" required={true}>
                {({ input }) => (
                  <FormRow
                    id="schoolSubject"
                    label={t('schoolSubject')}
                    description={t('schoolSubjectSelectDescription')}
                    field={
                      <Select
                        {...input}
                        id="schoolSubject"
                        placeholder={t('searchInTheList')}
                        options={
                          folder && folder.data.folder
                            ? uniqBy(subjects.concat(folder.data.folder.schoolSubject), property('id'))
                            : subjects
                        }
                        css="width: 100%;"
                      />
                    }
                    error={<FieldError name="schoolSubject" />}
                  />
                )}
              </Field>
            </Col>
            <Col width={1 / 1} pb={3}>
              <Field name="schoolSubject" subscription={{ value: true }}>
                {schoolSubjectProps => {
                  const selectedSchoolSubject = schoolSubjectProps.input.value;
                  const filteredDegrees = filteredDegreesWithSubject(degrees.data.degrees, selectedSchoolSubject).map(
                    degree => ({
                      label: degree.name,
                      value: degree.id,
                    })
                  );

                  return (
                    <Field name="schoolDegrees" type="select" required={true}>
                      {({ input }) => {
                        return (
                          <FormRow
                            id="schoolDegrees"
                            label="Degré(s)"
                            description={t('schoolDegreeSelectDescription')}
                            field={
                              <CustomSelect
                                {...input}
                                isMulti={true}
                                isClearable={false}
                                isDisabled={!selectedSchoolSubject}
                                isLoading={degrees.loading}
                                options={filteredDegrees}
                                placeholder={t('searchInTheList')}
                                truncateLabel={t('schoolDegreeLowercase', {
                                  count: input.value ? input.value.length : 0,
                                })}
                              />
                            }
                            error={<FieldError name="schoolDegrees" />}
                          />
                        );
                      }}
                    </Field>
                  );
                }}
              </Field>
            </Col>
            <Col width={1 / 1} pb={3} justifyContent="center">
              {partial ? (
                <Button
                  type="button"
                  onClick={e => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  disabled={submitting || pristine}
                  css="width: 100%; max-width: 20rem;"
                >
                  {t('create')}
                </Button>
              ) : (
                <Button type="submit" disabled={submitting} css="width: 100%; max-width: 20rem;">
                  {t('next')}
                </Button>
              )}
            </Col>
            {folderId &&
              history && (
                <Col width={1 / 1} pb={3} justifyContent="center">
                  <Button
                    dark
                    onClick={e => {
                      e.preventDefault();
                      toggleModal(Layout.MODAL_DELETE_FOLDER, () => {
                        removeFolder({ variables: { folder: folderId } }).then(() => {
                          history.push(`/`);
                        });
                      });
                    }}
                    css="width: 100%; max-width: 20rem;"
                  >
                    {t('deleteChain')}
                  </Button>
                </Col>
              )}
          </Grid>
        )}
      />
    );
  }
}

export default withNamespaces()(CreateFolder);
