import { TabList as ReactTabList } from 'react-tabs';
import { breakpoints } from 'src/styles/theme';
import styled from 'styled-components';

const TabList = styled(ReactTabList)`
  display: flex;
  padding-left: 0;
  list-style: none;
  @media (min-width: ${breakpoints.md}) {
    display: inline-flex;
  }
  ${props => props.css};
`;

TabList.TabsRole = 'TabList';

export default TabList;
