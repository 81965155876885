import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'rebass';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import BrainSvg from 'assets/svg/brain.svg';

const BrainIcon = styled(BrainSvg)`
  ${svgStyle};
  width: 2.8rem;
  height: 3rem;
  fill: ${props => (props.active ? theme.colors.secondary : theme.colors.tertiary)};

  @media (min-width: ${breakpoints.md}) {
    width: 3.5rem;
    height: 4rem;
  }
`;

const Container = styled(Flex)`
  width: 3.6rem;
  height: 3.8rem;
  justify-content: center;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    width: 6rem;
    height: 6rem;
  }
`;

class Stamina extends PureComponent {
  static propTypes = {
    alive: PropTypes.bool,
  };

  render() {
    const { alive } = this.props;

    return (
      <Container alignItems="center" jutifyContent="center">
        <BrainIcon active={alive ? 'true' : null} />
      </Container>
    );
  }
}

export default Stamina;
