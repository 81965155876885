import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LoadingContext } from 'src/context';
import { getLanguage, setLanguage } from 'src/locales/i18n';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Image, Text } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import AddSvg from 'assets/svg/add.svg';
import SettingsSvg from 'assets/svg/settings.svg';
import TriangleSvg from 'assets/svg/triangle.svg';
import Title from 'src/components/typography/Title';
import Modal from 'src/components/elements/Modal';
import AvatarModal from './Modal';
import TeacherForm from './TeacherForm';
import StudentForm from './StudentForm';
import Select from 'src/components/elements/Select';
import FormRow from 'src/components/elements/FormRow';

const AddIcon = styled(AddSvg)`
  ${svgStyle};
  width: 5.8rem;
  height: 5.8rem;
`;

const SettingIcon = styled(SettingsSvg)`
  ${svgStyle};
  width: 2.8rem;
  height: 2.8rem;
`;

const TriangleIcon = styled(TriangleSvg)`
  ${svgStyle};
  width: 1rem;
  height: 0.8rem;
`;

class Settings extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    avatars: PropTypes.object.isRequired,
    customAvatars: PropTypes.object,
    degrees: PropTypes.object,
    levels: PropTypes.object,
    removeAvatar: PropTypes.func,
    removeUser: PropTypes.func.isRequired,
    subjects: PropTypes.object,
    updateUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    isTeacher: PropTypes.bool.isRequired,
    modalIsOpen: PropTypes.bool.isRequired,
  };

  state = {
    lang: getLanguage(),
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  updateLang(lang) {
    this.setState({ lang });
    setLanguage(lang);
    this.props.i18n.changeLanguage(lang);
  }

  render() {
    const {
      t,
      closeModal,
      onSubmit,
      openModal,
      avatars,
      customAvatars,
      degrees,
      levels,
      removeAvatar,
      removeUser,
      subjects,
      updateUser,
      user,
      isTeacher,
      modalIsOpen,
    } = this.props;

    return (
      <Flex css="flex: 1 1 auto; min-height: 0;">
        <Box width={1 / 1}>
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
            <AvatarModal
              isTeacher={isTeacher}
              userScore={user.allTimeScore}
              avatars={avatars}
              customAvatars={customAvatars}
              validateOnBlur={false}
              onSubmit={data => {
                onSubmit(data, updateUser);
              }}
              enableReinitialize={true}
              initialValues={{
                avatar: user.avatar && user.avatar.id,
              }}
              removeAvatar={removeAvatar}
            />
          </Modal>
          <Container>
            <Content>
              <Flex py={2}>
                <Title icon={<SettingIcon />}>{isTeacher ? t('mySettingsTeacher') : t('mySettingsStudent')}</Title>
              </Flex>
            </Content>
          </Container>
          <Box bg="gray100">
            <Container css="flex: 0 0 auto;">
              <Content>
                <Grid py={['2rem', '2rem', '4rem']}>
                  <Col
                    alignItems="center"
                    justifyContent="center"
                    width={[1 / 1, 1 / 1, 5 / 12, 4 / 12]}
                    css="min-width: 24rem;"
                  >
                    <Box
                      css={`
                        position: relative;
                        width: 14rem;
                        height: 14rem;
                        cursor: pointer;
                        @media (min-width: ${breakpoints.md}) {
                          width: 22rem;
                          height: 22rem;
                        }
                      `}
                      onClick={openModal}
                    >
                      {user.avatar && (
                        <Image
                          src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${user.avatar.image}`}
                        />
                      )}
                      <Box css="position: absolute; bottom: 0; right: 0;">
                        <AddIcon />
                      </Box>
                    </Box>
                  </Col>
                  <Col alignItems="center" width={[1 / 1, 1 / 1, 7 / 12, 8 / 12]} pt={[4, 4, 0]}>
                    <Text fontSize={['2rem', '2rem', '2.8rem']} textAlign={['center', 'center', 'left']}>
                      <Text as="h2" fontSize={['2rem', '2rem', '2.8rem']} fontWeight="normal">
                        {t('welcomeUser', { firstname: user.firstname, username: user.username })}
                      </Text>
                      {isTeacher &&
                        user.schools.length > 0 && (
                          <Text as="ul" mt=".8rem" fontWeight="normal" css="list-style: none; padding-left: 0;">
                            {user.schools.map(school => (
                              <li key={school.id}>{school.name}</li>
                            ))}
                          </Text>
                        )}
                      {!isTeacher &&
                        user.schools.length > 0 && (
                          <Text as="h3" fontSize={['2rem', '2rem', '2.8rem']} fontWeight="normal">
                            {t('yourSchoolIs')}{' '}
                            <Text as="span" fontWeight="semibold">
                              {user.schools[0].name}
                            </Text>
                          </Text>
                        )}
                    </Text>
                  </Col>
                </Grid>
              </Content>
            </Container>
          </Box>
          <Box>
            <Container>
              <Content>
                <Grid justifyContent={['center', 'center', 'center', 'flex-start']}>
                  <Col
                    alignItems="center"
                    justifyContent="center"
                    width={[1 / 1, 1 / 1, 1 / 1, 4 / 12]}
                    css="min-width: 24rem;"
                  />
                  <Col
                    width={[1 / 1, 1 / 1, 1 / 2, 8 / 12]}
                    py={['2rem', '2rem', '4rem']}
                    justifyContent={['center', 'center', 'center', 'flex-start']}
                  >
                    <Box css="max-width: 43rem;">
                      <FormRow
                        py={2}
                        id="language"
                        label={t('selectLanguage')}
                        field={
                          <Select
                            name="language"
                            onChange={e => {
                              this.updateLang(e.target.value);
                            }}
                            value={this.state.lang}
                            options={[{ id: 'fr', name: 'Fr' }, { id: 'en', name: 'En' }]}
                          />
                        }
                      />
                      {isTeacher ? (
                        <TeacherForm
                          user={user}
                          subjects={subjects}
                          onSubmit={data => {
                            onSubmit(data, updateUser);
                          }}
                        />
                      ) : (
                        <StudentForm
                          user={user}
                          degrees={degrees}
                          levels={levels}
                          onSubmit={data => {
                            onSubmit(data, updateUser);
                          }}
                        />
                      )}
                      <Box pt="8rem">
                        <Flex
                          as="button"
                          type="button"
                          alignItems="center"
                          p={0}
                          onClick={() => {
                            if (window.confirm(t('modal.deleteUserDescription'))) {
                              removeUser().then(() => {
                                window.location.href = '/logout';
                              });
                            }
                          }}
                          css={`
                            background-color: transparent;
                            border: none;
                            color: ${theme.colors.text};
                            cursor: pointer;
                            &:hover {
                              color: ${theme.colors.secondary};
                            }
                          `}
                        >
                          <TriangleIcon />
                          <Text ml=".6rem" fontSize="1.2rem">
                            {t('deleteData')}
                          </Text>
                        </Flex>
                        <Flex
                          as="a"
                          alignItems="center"
                          pt={1}
                          href={'download-user-data'}
                          css={`
                            color: ${theme.colors.text};
                            text-decoration: none;
                            &:hover {
                              color: ${theme.colors.secondary};
                            }
                          `}
                        >
                          <TriangleIcon />
                          <Text ml=".6rem" fontSize="1.2rem">
                            {t('downloadData')}
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                  </Col>
                </Grid>
              </Content>
            </Container>
          </Box>
        </Box>
      </Flex>
    );
  }
}

export default withNamespaces()(Settings);
