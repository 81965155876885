import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FieldError } from 'src/utils/form';
import { Button } from 'src/components/elements/Button';
import { Col, Grid } from 'src/components/utilities/Grids';
import FormRow from 'src/components/elements/FormRow';
import CustomSelect from 'src/components/elements/CustomSelect';

class TeacherForm extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    user: PropTypes.object,
    subjects: PropTypes.object,
    onSubmit: PropTypes.func,
  };

  render() {
    const { t, user, subjects, onSubmit } = this.props;
    const currentSchoolSubjects =
      user.schoolSubjects.length > 0 &&
      user.schoolSubjects.map(schoolSubject => {
        return { label: schoolSubject.name, value: schoolSubject.id };
      });

    return (
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize={true}
        initialValues={{
          schoolSubjects: currentSchoolSubjects,
        }}
      >
        {({ handleSubmit, submitting }) => (
          <Grid as="form" onSubmit={handleSubmit}>
            <Col width={1 / 1} pb={3}>
              <Field name="schoolSubjects" disabled={subjects.loading} required={true}>
                {({ input }) => {
                  const options =
                    (subjects.data &&
                      subjects.data.subjects &&
                      subjects.data.subjects.length > 0 &&
                      subjects.data.subjects.map(subjects => ({
                        label: subjects.name,
                        value: subjects.id,
                      }))) ||
                    [];

                  return (
                    <FormRow
                      id="schoolSubjects"
                      label={t('schoolSubjectTaught')}
                      description={t('schoolSubjectTaughtDescription')}
                      field={
                        <CustomSelect
                          {...input}
                          defaultValue={input.value}
                          isMulti={true}
                          isClearable={false}
                          isLoading={subjects.loading}
                          options={options}
                          placeholder={t('searchInTheList')}
                          truncateLabel={t('schoolSubjectLowercase', { count: input.value ? input.value.length : 0 })}
                        />
                      }
                      error={<FieldError name="schoolSubject" />}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col width={1 / 1} justifyContent={['center', 'center', 'center', 'flex-start']}>
              <Button type="submit" disabled={submitting} css="width: 100%; max-width: 22rem;">
                {t('validate')}
              </Button>
            </Col>
          </Grid>
        )}
      </Form>
    );
  }
}

export default withNamespaces()(TeacherForm);
