import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { UPDATE_USER, REMOVE_USER } from 'src/queries/users';
import { GET_SUBJECTS } from 'src/queries/subjects';
import { GET_AVATARS, REMOVE_AVATAR } from 'src/queries/avatars';
import { GET_AVATAR_LEVELS } from 'src/queries/avatarLevels';
import { GET_DEGREES } from 'src/queries/degrees';
import { GET_LEVELS } from 'src/queries/levels';
import { handleSubmissionErrors } from 'src/utils/form';
import Content from './Content';
import { hasTeacherRole } from 'src/utils/roles';

class SettingsContainer extends PureComponent {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    modalIsOpen: false,
  };

  static formatFormValuesvalues(values) {
    const formattedData = {};

    if (values.avatar) {
      formattedData.avatar = { id: values.avatar };
    }

    if (values.schoolSubjects) {
      formattedData.schoolSubjects = values.schoolSubjects.map((schoolSubject) => ({ id: schoolSubject.value }));
    }

    if (values.schoolDegree) {
      formattedData.schoolDegree = { id: values.schoolDegree };
    }

    return formattedData;
  }

  onSubmit = (data, updateUser) => {
    return updateUser({
      variables: {
        input: SettingsContainer.formatFormValuesvalues(data),
      },
    })
      .then(() => {
        // TODO : alert success
        this.closeModal();
      })
      .catch((errors) => {
        return handleSubmissionErrors(errors);
      });
  };

  openModal = () => {
    this.setState({
      modalIsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    });
  };

  render() {
    const { modalIsOpen } = this.state;
    const { user } = this.props;
    const isTeacher = hasTeacherRole(user);

    /*eslint-disable */
    const queries = {
      updateUser: ({ render }) => <Mutation mutation={UPDATE_USER}>{render}</Mutation>,
      removeUser: ({ render }) => <Mutation mutation={REMOVE_USER}>{render}</Mutation>,
    };

    if (isTeacher) {
      queries.subjects = ({ render }) => <Query query={GET_SUBJECTS}>{render}</Query>;
      queries.avatars = ({ render }) => (
        <Query query={GET_AVATARS} variables={{ type: 'TYPE_TEACHER' }}>
          {render}
        </Query>
      );
      queries.customAvatars = ({ render }) => (
        <Query query={GET_AVATARS} variables={{ type: 'TYPE_CUSTOM' }}>
          {render}
        </Query>
      );
      queries.removeAvatar = ({ render }) => (
        <Mutation
          mutation={REMOVE_AVATAR}
          update={(cache, { data: { removeAvatar } }) => {
            const { avatars } = cache.readQuery({ query: GET_AVATARS, variables: { type: 'TYPE_CUSTOM' } });
            cache.writeQuery({
              query: GET_AVATARS,
              variables: { type: 'TYPE_CUSTOM' },
              data: { avatars: avatars.filter((avatar) => avatar.id != removeAvatar.id) },
            });
          }}
        >
          {render}
        </Mutation>
      );
    } else {
      queries.degrees = ({ render }) => <Query query={GET_DEGREES}>{render}</Query>;
      queries.avatars = ({ render }) => (
        <Query query={GET_AVATAR_LEVELS} variables={{ type: 'TYPE_STUDENT' }}>
          {render}
        </Query>
      );
      queries.levels = ({ render }) => <Query query={GET_LEVELS}>{render}</Query>;
    }

    const Composed = adopt(queries);
    /*eslint-enable */

    return (
      <Composed>
        {({ subjects, degrees, levels, avatars, updateUser, removeUser, customAvatars, removeAvatar }) => {
          if (!this.isContentLoaded) {
            if (updateUser.loading || removeUser.loading || avatars.loading) return <div />;
            if (isTeacher) {
              if (subjects.loading || customAvatars.loading || removeAvatar.loading) return <div />;
            } else {
              if (degrees.loading || levels.loading) return <div />;
            }
          }
          this.isContentLoaded = true;

          return (
            <Content
              closeModal={this.closeModal}
              onSubmit={this.onSubmit}
              openModal={this.openModal}
              avatars={avatars}
              customAvatars={customAvatars}
              degrees={degrees}
              levels={levels}
              removeAvatar={removeAvatar}
              removeUser={removeUser}
              subjects={subjects}
              updateUser={updateUser}
              user={user}
              isTeacher={isTeacher}
              modalIsOpen={modalIsOpen}
            />
          );
        }}
      </Composed>
    );
  }
}

export default SettingsContainer;
