import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import backAnimationData from 'assets/json/brio-back.json';

class Background extends PureComponent {
  static propTypes = {
    startTransition: PropTypes.bool,
    endTransition: PropTypes.bool,
  };

  state = {
    isPaused: false,
  };

  componentDidMount() {
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.startTransition && this.props.startTransition) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ isPaused: true });
      }, 490);
    } else if (prevProps.startTransition && !this.props.startTransition) {
      this.setState({ isPaused: false });
    }
  }

  render() {
    const { isPaused } = this.state;

    return (
      <Lottie
        options={{
          render: 'svg',
          animationData: backAnimationData,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height="100%"
        width="100%"
        isStopped={false}
        isPaused={isPaused}
        isClickToPauseDisabled={true}
      />
    );
  }
}

export default Background;
