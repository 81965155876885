import styled from 'styled-components';
import { css } from 'styled-components';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import AccountSvg from 'assets/svg/account.svg';
import ActionsSvg from 'assets/svg/actions.svg';
import FavoriteSvg from 'assets/svg/favorites.svg';

export const avatarStyle = css`
  width: 4rem;
  height: 4rem;

  @media (min-width: ${breakpoints.md}) {
    width: 3rem;
    height: 3rem;
  }
`;

export const AccountIcon = styled(AccountSvg)`
  ${svgStyle};
  ${avatarStyle};
`;

export const ActionsIcon = styled(ActionsSvg)`
  ${svgStyle};
  width: 2.2rem;
  height: 1.8rem;
`;

export const FavoriteIcon = styled(FavoriteSvg)`
  ${svgStyle};
  height: 2rem;
  fill: ${theme.colors.tertiary};

  .favorite-icon-full {
    fill: transparent;
  }
`;

export const FavoriteIconActive = styled(FavoriteSvg)`
  ${svgStyle};
  height: 2rem;
  fill: ${theme.colors.tertiary};
`;
