import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Box } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import Card from 'src/components/blocks/Card';
import SubjectRow from './SubjectRow';
import { breakpoints } from 'src/styles/theme';

class OfficialCollections extends PureComponent {
  getRowTitle(row) {
    return row[0].schoolSubject.name;
  }

  render() {
    const { collections } = this.props;

    return (
      <Box>
        {Object.keys(collections).map((key, index) => (
          <SubjectRow
            key={`${index}-${index}`}
            id={key}
            title={this.getRowTitle(collections[key])}
            showLinkButton={collections[key].length === 3}
          >
            <Grid mt={2}>
              {collections[key].map((values, key) => {
                if (key === 1) {
                  return (
                    <MediaQuery key={key} minWidth={breakpoints.md}>
                      <Col width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
                        <Card.Collection {...values} />
                      </Col>
                    </MediaQuery>
                  );
                } else if (key === 2) {
                  return (
                    <MediaQuery key={key} minWidth={breakpoints.lg}>
                      <Col width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
                        <Card.Collection {...values} />
                      </Col>
                    </MediaQuery>
                  );
                }
                return (
                  <Col key={key} width={[1 / 1, 1 / 1, 1 / 2, 1 / 3]} pb={2}>
                    <Card.Collection {...values} />
                  </Col>
                );
              })}
            </Grid>
          </SubjectRow>
        ))}
      </Box>
    );
  }
}

OfficialCollections.propTypes = {
  collections: PropTypes.object,
};

export default OfficialCollections;
