import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import Cortex from './Cortex';
import Background from './Background';
import Back from './Back';
import Front from './Front';

const Container = styled(Box)`
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dcf6ff;
`;

class Animation extends PureComponent {
  static propTypes = {
    errors: PropTypes.number,
  };

  state = {
    startErrorTransition: false,
    endErrorTransition: false,
    isStopped: false,
    isPaused: false,
    blink: false,
  };

  componentDidMount() {
    this.timeout = null;
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (this.props.errors > prevProps.errors) {
      this.setState({ startErrorTransition: true });
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.setState({ endErrorTransition: true });
        this.timeout = setTimeout(() => {
          this.setState({ startErrorTransition: false });
          this.timeout = setTimeout(() => {
            this.setState({ endErrorTransition: false });
          }, 1000);
        }, 1000);
      }, 2000);
    }
  }

  render() {
    const { startErrorTransition, endErrorTransition } = this.state;

    return (
      <Container>
        <Box css="position: absolute; z-index: 1; top: 0; right: 0; bottom: 0; left: 0;">
          <Background startTransition={startErrorTransition} endTransition={endErrorTransition} />
        </Box>
        <Box css="position: absolute; z-index: 2; top: 0; right: 0; bottom: 0; left: 0;">
          <Back startTransition={startErrorTransition} endTransition={endErrorTransition} />
        </Box>
        <Box css="position: absolute; z-index: 3; top: 0; right: 0; bottom: 0; left: 0;">
          <Cortex startTransition={startErrorTransition} />
        </Box>
        <Box css="position: absolute; z-index: 4; top: 0; right: 0; bottom: 0; left: 0;">
          <Front startTransition={startErrorTransition} endTransition={endErrorTransition} />
        </Box>
      </Container>
    );
  }
}

export default Animation;
