import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import FolderCard from 'src/components/blocks/CardFolderLarge';
import ListLayout from 'src/layouts/List';
import Title from 'src/components/typography/Title';

class AuthorFolders extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    count: PropTypes.number.isRequired,
    folders: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, count, folders, user } = this.props;

    return (
      <ListLayout icon={<ListLayout.ChainIcon />} title={t('myChains')}>
        {count > 0 ? (
          <Box>
            {Object.keys(folders).map((key, index) => (
              <Box key={`${index}-${index}`} as="section" pt={3}>
                <Title sm medium>
                  {key}
                </Title>
                <Grid mt={2}>
                  {folders[key].map((values, key) => {
                    return (
                      <Col key={key} width={1 / 1} pb={2}>
                        <FolderCard {...values} editable={user.id === values.author.id} />
                      </Col>
                    );
                  })}
                </Grid>
              </Box>
            ))}
          </Box>
        ) : (
          <p>{t('noResult')}</p>
        )}
      </ListLayout>
    );
  }
}

export default withNamespaces()(AuthorFolders);
