import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import MediaQuery from 'react-responsive';
import { Flex } from 'rebass';
import Drawer from 'src/components/blocks/Drawer';
import Footer from 'src/components/blocks/Footer';
import Header from 'src/components/blocks/Header';
import { Button } from 'src/components/elements/Button';
import ConfirmModal from 'src/components/elements/ConfirmModal';
import { DrawerContext, LoadingContext, ModalContext } from 'src/context';
import { FOLDERS_LIMIT } from 'src/pages/StudentDashboard';
import { GET_FOLDERS } from 'src/queries/folders';
import { breakpoints, theme } from 'src/styles/theme';
import { hasTeacherRole } from 'src/utils/roles';
import styled from 'styled-components';
import { GET_USER_WAITING_DUELS } from './queries/users';

const LayoutStyled = styled.main`
  position: relative;
  z-index: 2;
  flex-grow: 1;
  display: flex;
  padding-left: 0;
  pointer-events: none;
  will-change: transform;
  ${props =>
    props.authenticated &&
    `
    transform: translateX(0) perspective(1px);
    transition: padding 225ms cubic-bezier(0.0, 0, 0.2, 1), transform 225ms ease;
    ${props.open && `transform: translateX(32rem);`};

    @media (min-width: ${breakpoints.xxl}) {
      padding-left: 8rem;
      transform: translateX(0) perspective(1px);
      ${props.open && `padding-left: 34rem;`};
    }
  `};
  ${props => props.css};
`;

const LayoutInnerStyled = styled.div.attrs({})`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-height: 0;
  background-color: ${props => props.backgroundColor || theme.colors.white};
  pointer-events: auto;
`;

class Layout extends Component {
  static contextType = LoadingContext;
  static MODAL_UNPUBLISH_FOLDER = 'MODAL_UNPUBLISH_FOLDER';
  static MODAL_UNPUBLISH_COLLECTION = 'MODAL_UNPUBLISH_COLLECTION';
  static MODAL_DELETE_FOLDER = 'MODAL_DELETE_FOLDER';
  static MODAL_DELETE_COLLECTION = 'MODAL_DELETE_COLLECTION';
  static MODAL_DELETE_CLASSROOM = 'MODAL_DELETE_CLASSROOM';

  static propTypes = {
    t: PropTypes.func,
    children: PropTypes.any,
    backgroundColor: PropTypes.string,
    theme: PropTypes.any,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      authenticated: true,
      drawerMobileOpen: false,
      drawerDesktopOpen: false,
      modalContext: {
        [Layout.MODAL_UNPUBLISH_FOLDER]: false,
        [Layout.MODAL_UNPUBLISH_COLLECTION]: false,
        [Layout.MODAL_DELETE_FOLDER]: false,
        [Layout.MODAL_DELETE_COLLECTION]: false,
        [Layout.MODAL_DELETE_CLASSROOM]: false,
        toggleModal: this.toggleModal,
        executeModalAction: this.executeModalAction,
      },
    };
  }

  toggleModal = (type, value) => {
    if (this.state.modalContext[type] !== undefined) {
      this.setState({
        modalContext: {
          ...this.state.modalContext,
          [type]: value,
        },
      });
    }
  };

  executeModalAction = type => {
    // Execute modal action and close
    if (typeof this.state.modalContext[type] === 'function') {
      this.state.modalContext[type]();
      this.toggleModal(type, false);
    }
  };

  handleDrawerToggle = () => {
    if (!this.state.drawerMobileOpen || !this.state.drawerDesktopOpen) {
      document.querySelector('html').classList.add('no-scroll');
    } else {
      document.querySelector('html').classList.remove('no-scroll');
    }

    this.setState({
      drawerMobileOpen: !this.state.drawerMobileOpen,
      drawerDesktopOpen: !this.state.drawerDesktopOpen,
    });
  };

  handleDrawerClose = () => {
    document.querySelector('html').classList.remove('no-scroll');

    this.setState({
      drawerMobileOpen: false,
      drawerDesktopOpen: false,
    });
  };

  render() {
    const { t, children, user, backgroundColor } = this.props;
    const { authenticated, drawerMobileOpen, drawerDesktopOpen } = this.state;
    const isTeacher = hasTeacherRole(user);

    return (
      <ModalContext.Provider value={this.state.modalContext}>
        <MediaQuery minWidth={breakpoints.xxl}>
          {matches => {
            return (
              <React.Fragment>
                {authenticated && (
                  <Drawer className="screen" open={matches ? drawerDesktopOpen : drawerMobileOpen}>
                    <Drawer.Link
                      to="/"
                      onClick={this.handleDrawerClose}
                      icon={<Drawer.HomeIcon />}
                      label={t('navigation.home')}
                    />
                    {isTeacher && (
                      <React.Fragment>
                        <Drawer.Link
                          to="/series/creer"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.AddCollectionIcon />}
                          label={t('navigation.createASeries')}
                        />
                        <Drawer.Link
                          to="/chaines/creer"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.AddFolderIcon />}
                          label={t('navigation.createAChain')}
                        />
                        <Drawer.Link
                          to="/classes/creer"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.AddClassroomIcon />}
                          label={t('navigation.createAClassroom')}
                        />
                        <Drawer.Separator open={matches ? drawerDesktopOpen : drawerMobileOpen} />
                        <Drawer.Link
                          to="/mes-series"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.CollectionIcon />}
                          label={t('navigation.mySeries')}
                        />
                        <Drawer.Link
                          to="/mes-chaines"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.ChainIcon />}
                          label={t('navigation.myChains')}
                        />
                        <Drawer.Link
                          to="/mes-classes"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.ClassroomIcon />}
                          label={t('navigation.myClassrooms')}
                        />
                        <Drawer.Link
                          to="/mes-favoris"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.FavoritesIcon />}
                          label={t('navigation.myFavorites')}
                        />
                        <Query
                          query={GET_USER_WAITING_DUELS}
                          pollInterval={3000}
                          variables={{ id: user.id }}
                          fetchPolicy={'network-only'}
                        >
                          {({ data }) => {
                            return (
                              <Drawer.Link
                                to="/mes-duels"
                                onClick={this.handleDrawerClose}
                                icon={<Drawer.DuelIcon />}
                                label={t('navigation.duels')}
                                notification={data && data.me ? data.me.countWaitingDuels : 0}
                                showNotificationOnReducedDrawer={matches ? !drawerDesktopOpen : false}
                              />
                            );
                          }}
                        </Query>
                        <Drawer.Link
                          to="/ressources-officielles"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.RecommendationIcon />}
                          label={t('navigation.officialResources')}
                        />
                        <Drawer.Separator open={matches ? drawerDesktopOpen : drawerMobileOpen} />
                      </React.Fragment>
                    )}
                    {!isTeacher && (
                      <React.Fragment>
                        {user.schoolDegree && (
                          <Drawer.Link
                            to="/mes-recommandations"
                            onClick={this.handleDrawerClose}
                            icon={<Drawer.RecommendationIcon />}
                            label={t('navigation.recommendations')}
                          />
                        )}
                        <Drawer.Separator open={matches ? drawerDesktopOpen : drawerMobileOpen} />
                        <Drawer.Link
                          to="/series/a-revoir"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.RetryIcon />}
                          label={t('navigation.toReview')}
                          notification={user.countCollectionsWithToReviewStatus}
                          showNotificationOnReducedDrawer={matches ? !drawerDesktopOpen : false}
                        />
                        <Drawer.Link
                          to="/mes-favoris"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.FavoritesIcon />}
                          label={t('navigation.myFavorites')}
                        />
                        <Query
                          query={GET_USER_WAITING_DUELS}
                          pollInterval={3000}
                          variables={{ id: user.id }}
                          fetchPolicy={'network-only'}
                        >
                          {({ data }) => {
                            return (
                              <Drawer.Link
                                to="/mes-duels"
                                onClick={this.handleDrawerClose}
                                icon={<Drawer.DuelIcon />}
                                label={t('navigation.duels')}
                                notification={data && data.me ? data.me.countWaitingDuels : 0}
                                showNotificationOnReducedDrawer={matches ? !drawerDesktopOpen : false}
                              />
                            );
                          }}
                        </Query>
                        <Drawer.Link
                          to="/ressources-officielles"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.RecommendationIcon />}
                          label={t('navigation.officialResources')}
                        />
                        <Drawer.Separator open={matches ? drawerDesktopOpen : drawerMobileOpen} />
                      </React.Fragment>
                    )}
                    <Query
                      query={GET_FOLDERS}
                      fetchPolicy="network-only"
                      variables={{
                        limit: FOLDERS_LIMIT,
                        filterBy: [{ join: 'linkUsers', property: 'user', where: user.id }],
                      }}
                    >
                      {({ data }) => {
                        if (!data || !data.folders || (data.folders && data.folders.results.length === 0)) {
                          return null;
                        }

                        return (
                          <React.Fragment>
                            {data.folders.results.slice(0, 3).map(folder => (
                              <Drawer.Link
                                key={folder.id}
                                to={`/chaines/${folder.id}`}
                                onClick={this.handleDrawerClose}
                                icon={<Drawer.ChainCheckIcon />}
                                label={folder.title}
                                notification={folder.countCollectionsUnseen}
                                showNotificationOnReducedDrawer={matches ? !drawerDesktopOpen : false}
                              />
                            ))}
                            {data.folders.results.length > 3 && (
                              <Drawer.Link
                                to="/chaines/abonnements"
                                icon={<Drawer.ChevronIcon />}
                                onClick={this.handleDrawerClose}
                                label={t('navigation.seeMore')}
                              />
                            )}
                            <Drawer.Separator open={matches ? drawerDesktopOpen : drawerMobileOpen} />
                          </React.Fragment>
                        );
                      }}
                    </Query>
                    <Drawer.Link
                      as="a"
                      target="_blank"
                      href="https://edu.ge.ch/qr/cortex-aide"
                      onClick={this.handleDrawerClose}
                      icon={<Drawer.HelpIcon />}
                      label={t('help')}
                    />
                    <MediaQuery query={`(max-width: ${breakpoints.mdPlus})`}>
                      <Drawer.Link
                        to="/mes-parametres"
                        onClick={this.handleDrawerClose}
                        icon={<Drawer.SettingsIcon />}
                        label={t('settings')}
                      />
                      {isTeacher ? (
                        <Drawer.Link
                          to="/mes-statistiques"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.BadgeIcon />}
                          label="Statistiques"
                        />
                      ) : (
                        <Drawer.Link
                          to="/mes-recompenses"
                          onClick={this.handleDrawerClose}
                          icon={<Drawer.TrophyIcon />}
                          label={t('awards')}
                        />
                      )}
                      <Drawer.Link
                        to="/mes-contacts"
                        onClick={this.handleDrawerClose}
                        icon={<Drawer.AddressBookIcon />}
                        label="Carnet d'adresses"
                      />
                      <Flex justifyContent="center" py={2}>
                        <Button
                          as="a"
                          href={`${process.env.BASENAME}logout`}
                          secondary
                          css="width: 100%; max-width: 19rem;"
                        >
                          {t('logout')}
                        </Button>
                      </Flex>
                    </MediaQuery>
                  </Drawer>
                )}
                <LayoutStyled authenticated={authenticated} open={matches ? drawerDesktopOpen : drawerMobileOpen} id="main-container">
                  <LayoutInnerStyled backgroundColor={backgroundColor}>
                    <Header
                      authenticated={authenticated}
                      user={user}
                      onMenuButtonClick={this.handleDrawerToggle}
                      open={matches ? drawerDesktopOpen : drawerMobileOpen}
                    />
                    <React.Fragment>
                      <DrawerContext.Provider value={matches ? drawerDesktopOpen : drawerMobileOpen}>
                        <Flex css="flex: 1 1 auto; width: 100%;">{children}</Flex>
                      </DrawerContext.Provider>
                      <Footer />
                    </React.Fragment>
                  </LayoutInnerStyled>
                </LayoutStyled>
              </React.Fragment>
            );
          }}
        </MediaQuery>
        <ConfirmModal
          modalContext={this.state.modalContext}
          modalName={Layout.MODAL_UNPUBLISH_COLLECTION}
          modalTitle={t('modal.unpublishSerieTitle')}
          modalDescription={t('modal.unpublishSerieDescription')}
          t={t}
        />
        <ConfirmModal
          modalContext={this.state.modalContext}
          modalName={Layout.MODAL_UNPUBLISH_FOLDER}
          modalTitle={t('modal.unpublishChainTitle')}
          modalDescription={t('modal.unpublishChainDescription')}
          t={t}
        />
        <ConfirmModal
          modalContext={this.state.modalContext}
          modalName={Layout.MODAL_DELETE_COLLECTION}
          modalTitle={t('modal.deleteSerieTitle')}
          modalDescription={t('modal.deleteSerieDescription')}
          t={t}
        />
        <ConfirmModal
          modalContext={this.state.modalContext}
          modalName={Layout.MODAL_DELETE_FOLDER}
          modalTitle={t('modal.deleteChainTitle')}
          modalDescription={t('modal.deleteChainDescription')}
          t={t}
        />
        <ConfirmModal
          modalContext={this.state.modalContext}
          modalName={Layout.MODAL_DELETE_CLASSROOM}
          modalTitle={t('modal.deleteClassroomTitle')}
          modalDescription={t('modal.deleteClassroomDescription')}
          t={t}
        />
      </ModalContext.Provider>
    );
  }
}

export default withNamespaces()(Layout);
