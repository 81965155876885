import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { breakpoints, theme } from 'src/styles/theme';

const ItemStyled = styled(Box)`
  flex: 1;
  padding: 0;
  border: none;
  border-bottom-style: solid;
  border-bottom-size: 1px;
  border-bottom-color: ${props => props.color};
  background-color: ${theme.colors.white};
  color: ${theme.colors.text};
  cursor: pointer;
  appearance: none;
  transition: color 225ms ease;
  outline: 0;

  &:hover {
    color: ${props => props.color};
  }

  @media (min-width: ${breakpoints.md}) {
    width: 11.6rem;
    height: 12rem;
  }
`;

const Inner = styled(Flex)`
  min-width: 5.8rem;
  height: 6.4rem;

  @media (min-width: ${breakpoints.md}) {
    width: 11.6rem;
    height: 12rem;
  }
`;

class Item extends PureComponent {
  static propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
    color: PropTypes.string,
  };

  render() {
    const { icon, label, color } = this.props;

    return (
      <ItemStyled as="button" type="button" color={color}>
        <Inner as="span" flexDirection="column" alignItems="center" justifyContent="center">
          {icon}
          <Text
            as="span"
            pt={['.6rem', '.6rem', '1rem']}
            fontFamily="heading"
            fontSize={['1.0rem', '1.2rem', '1.4rem']}
            fontWeight="medium"
          >
            {label}
          </Text>
        </Inner>
      </ItemStyled>
    );
  }
}

export default Item;
