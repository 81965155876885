import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Mutation } from '@apollo/client/react/components';
import { TOGGLE_FAVORITE_COLLECTION } from 'src/queries/collections';
import MediaQuery from 'react-responsive';
import { Box, Flex } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { breakpoints, theme } from 'src/styles/theme';
import { ActionsIcon } from './Icons';
import { FavoriteIcon, FavoriteIconActive } from 'src/components/blocks/Card/FavoriteButton';

const Button = styled(Box)`
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: ${theme.colors.white};
  color: ${theme.colors.text};
  cursor: pointer;
  appearance: none;
  transition: color 225ms ease;
  outline: 0;
`;

const Inner = styled(Flex)`
  width: 4rem;
  height: 4rem;
`;

const NavigationButton = ({ collection, isFavorite, onClick }) => (
  <MediaQuery query={`(max-width: ${breakpoints.smPlus})`}>
    <Grid ml="-.5rem">
      <Col pl=".5rem">
        <Button as="button" type="button" onClick={onClick}>
          <Inner as="span" alignItems="center" justifyContent="center">
            <ActionsIcon />
          </Inner>
        </Button>
      </Col>
      <Col pl=".5rem">
        <Mutation mutation={TOGGLE_FAVORITE_COLLECTION}>
          {(toggleFavoriteCollection) => (
            <Button
              as="button"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                toggleFavoriteCollection({ variables: { collection: collection } });
              }}
            >
              <Inner as="span" alignItems="center" justifyContent="center">
                {isFavorite ? <FavoriteIconActive /> : <FavoriteIcon />}
              </Inner>
            </Button>
          )}
        </Mutation>
      </Col>
    </Grid>
  </MediaQuery>
);

NavigationButton.propTypes = {
  onClick: PropTypes.func,
  collection: PropTypes.string,
  isFavorite: PropTypes.bool,
};

export default NavigationButton;
