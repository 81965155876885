import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withNamespaces } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { Box, Text } from 'rebass';
import { Button } from 'src/components/elements/Button';
import { Col, Grid } from 'src/components/utilities/Grids';
import AvatarList from 'src/components/blocks/AvatarList';

const Container = styled(Box)`
  overflow-y: auto;
  ${props =>
    !props.isTeacher &&
    `
  max-height: 60rem;
  `};
`;

class Modal extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    isTeacher: PropTypes.bool,
    userScore: PropTypes.number,
    avatars: PropTypes.object,
    customAvatars: PropTypes.object,
    onSubmit: PropTypes.func,
    removeAvatar: PropTypes.func,
  };

  getUserScore(score) {
    return score >= 0 ? score : 0;
  }

  render() {
    const { t, isTeacher, userScore, avatars, customAvatars, onSubmit, removeAvatar, ...others } = this.props;

    return (
      <Container py={4} px={[1, 2, 2]} isTeacher={isTeacher ? 'true' : false}>
        <Text as="h4" fontSize={['1.4rem', '1.4rem', '2rem']} fontWeight="semibold" textAlign="center">
          {t('chooseMyAvatar')}
        </Text>
        <Box pt={['1.2rem', '1.2rem', '2.2rem']} css="max-width: 50rem; margin: auto;">
          <Form onSubmit={onSubmit} {...others}>
            {({ handleSubmit, submitting }) => (
              <Grid as="form" pt={2} onSubmit={handleSubmit}>
                {isTeacher && (
                  <Col width={1 / 1} pb={3}>
                    <Box>
                      <Text pb={1} fontSize="1.6rem" fontWeight="semibold">
                        {t('yourAvatarPoliteForm')}
                      </Text>
                      <Field name="avatar" required={true}>
                        {({ input }) => (
                          <AvatarList
                            avatars={avatars.data.avatars}
                            onAvatarClick={avatar => input.onChange(avatar.id)}
                            selectedAvatar={input.value}
                          />
                        )}
                      </Field>
                    </Box>
                  </Col>
                )}
                {isTeacher && (
                  <Col width={1 / 1} pb={3}>
                    <Box>
                      <Text pb={1} fontSize="1.6rem" fontWeight="semibold">
                        {t('yourImages')}
                      </Text>
                      <Field name="avatar" required={true}>
                        {({ input }) => (
                          <AvatarList
                            avatars={customAvatars.data.avatars}
                            onAvatarClick={avatar => input.onChange(avatar.id)}
                            onRemoveAvatar={avatar => {
                              if (input.value === avatar.id) {
                                input.onChange(avatars.data.avatars[0].id);
                                handleSubmit();
                              }
                              removeAvatar({
                                variables: { avatarId: avatar.id },
                              });
                            }}
                            selectedAvatar={input.value}
                            canUploadAvatar={true}
                          />
                        )}
                      </Field>
                    </Box>
                  </Col>
                )}
                {!isTeacher &&
                  avatars.data.avatarLevels.map(avatarLevel => (
                    <Col
                      key={`avatarLevel${avatarLevel.id}`}
                      width={1 / 4}
                      pb="5rem"
                      pr="2rem"
                      css={avatarLevel.score > this.getUserScore(userScore) && 'pointer-events: none; opacity: .6;'}
                    >
                      <Box>
                        <Field name="avatar" required={true}>
                          {({ input }) => (
                            <AvatarList
                              avatars={avatarLevel.avatars}
                              onAvatarClick={avatar => input.onChange(avatar.id)}
                              selectedAvatar={input.value}
                            />
                          )}
                        </Field>
                        <Text pb={1} fontSize="1.6rem" fontWeight="semibold" css="text-align:center;">
                          {avatarLevel.name}
                        </Text>
                      </Box>
                    </Col>
                  ))}
                <Col justifyContent="center" css={!isTeacher && `position: absolute; right: 0; bottom: 6rem; left: 0;`}>
                  <Button type="submit" disabled={submitting} css="width: 100%; max-width: 16.5rem;">
                    {t('validate')}
                  </Button>
                </Col>
              </Grid>
            )}
          </Form>
        </Box>
      </Container>
    );
  }
}

export default withNamespaces()(Modal);
