import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'rebass';

class Section extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    label: PropTypes.string.isRequired,
  };

  render() {
    const { children, label, ...others } = this.props;

    return (
      <Box flex={['1 1 auto', '1 1 auto', '0 0 auto']} {...others}>
        <Text
          as="h3"
          pb={['.4rem', '.4rem', '1rem']}
          fontFamily="heading"
          fontSize={['1rem', '1rem', '1.6rem']}
          fontWeight={['normal', 'normal', 'semibold']}
        >
          {label}
        </Text>
        <Flex
          css={`
            overflow: hidden;
            padding-left: 0;
            margin-top: 0;
            border-radius: 4px 4px 0 0;
            box-shadow: 0 0 4px 2px rgba(227, 227, 227, 0.44);
            list-style: none;
          `}
        >
          {children}
        </Flex>
      </Box>
    );
  }
}

export default Section;
