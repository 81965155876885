import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { adopt } from 'react-adopt';
import { GET_CURRENT_USER } from 'src/queries/users';
import { GET_COLLECTION } from 'src/queries/collections';
import { ADD_DUEL } from 'src/queries/duels';
import { getRouteNameForGame } from 'src/utils/games';
import Content from './Content';

class DuelProvokeContainer extends PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    search: '',
    selectedUser: null,
  };

  selectFriend = (friendId) => () => {
    this.setState({
      selectedUser: friendId,
    });
  };

  provokeDuel = () => () => {
    const { selectedUser } = this.state;
    const { history, match, location } = this.props;
    const difficulty = location.state.difficulty;
    const game = location.state.game;
    const collectionId = match.params.id;

    if (selectedUser) {
      history.push(`/series/${collectionId}/${getRouteNameForGame(game)}?duel=true`, {
        difficulty,
        selectedUser,
        game,
      });
    }
  };

  onSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  render() {
    const { search, selectedUser } = this.state;
    const { match, location } = this.props;
    const difficulty = location.state.difficulty;
    const game = location.state.game;
    const collectionId = match.params.id;

    /*eslint-disable */
    const queries = {
      me: ({ render }) => (
        <Query query={GET_CURRENT_USER} variables={{ withFriends: true }}>
          {render}
        </Query>
      ),
      collection: ({ render }) => (
        <Query query={GET_COLLECTION} variables={{ id: collectionId }}>
          {render}
        </Query>
      ),
      addDuel: ({ render }) => <Mutation mutation={ADD_DUEL}>{render}</Mutation>,
    };

    if (!this.Composed) {
      this.Composed = adopt(queries);
    }
    /*eslint-enable */

    return (
      <this.Composed>
        {({ me, collection, addDuel }) => {
          if ((me.loading || collection.loading || addDuel.loading) && !this.isContentLoaded) return <div />;
          if ((me.error || collection.error || addDuel.error) && !this.isContentLoaded) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <Content
              onSearchChange={this.onSearchChange}
              selectFriend={this.selectFriend}
              provokeDuel={this.provokeDuel}
              addDuel={addDuel}
              collectionData={collection.data.collection}
              difficulty={difficulty}
              game={game}
              search={search}
              selectedUser={selectedUser}
              userData={me.data.me}
            />
          );
        }}
      </this.Composed>
    );
  }
}

export default DuelProvokeContainer;
