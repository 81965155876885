import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import Helmet from 'react-helmet';
import countby from 'lodash.countby';
import RouterLink from 'react-router-dom/Link';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Box } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import Header from 'src/components/games/Header';
import Navigation from 'src/components/games/Navigation';
import Panels from 'src/components/games/Panels';
import Print from './Print';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import { breakpoints, theme } from 'src/styles/theme';
import { graphql } from '@apollo/client/react/hoc';
import { SET_COLLECTION_STATUS } from 'src/queries/collections';
import { LEARNED_STATUS, TO_REVIEW_STATUS, NEUTRAL_STATUS } from 'src/components/elements/Status';
import Preview from 'src/components/blocks/Collection/Preview';
import { GET_CURRENT_USER } from 'src/queries/users';

const TabStyled = styled(Tab)`
  display: flex;
  flex: 1;
  ${(props) =>
    props.selected &&
    `
  button {
    background-color: rgba(95, 99, 187, .15);
    color: ${theme.colors.primary};
  }  
  `};
`;
TabStyled.TabsRole = 'Tab';

const Link = styled(RouterLink)`
  display: flex;
  flex: 1;
  text-decoration: none;
`;

const TabListStyled = styled(TabList)`
  display: flex;
  flex: 1;
  padding-left: 0;
  margin-top: 0;
  list-style: none;
`;
TabListStyled.TabsRole = 'TabList';

const withStatusMutation = graphql(SET_COLLECTION_STATUS);

@withStatusMutation
class Collection extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
    collection: PropTypes.object,
    user: PropTypes.object,
    mutate: PropTypes.func,
  };

  countCardsWithLearnedStatus = null;
  countCardsWithToReviewStatus = null;

  componentDidMount() {
    const { cards } = this.props.collection;
    this.context.removeLoading();
    this.countCardsWithLearnedStatus = countby(cards, (o) => {
      return o.userLinkCard && o.userLinkCard.status === LEARNED_STATUS;
    });
    this.countCardsWithToReviewStatus = countby(cards, (o) => {
      return o.userLinkCard && o.userLinkCard.status === TO_REVIEW_STATUS;
    });
  }

  componentDidUpdate() {
    const { mutate, collection } = this.props;
    const { id, cards } = collection;
    const newCountCardsWithLearnedStatus = countby(cards, (o) => {
      return o.userLinkCard && o.userLinkCard.status === LEARNED_STATUS;
    });
    const newCountCardsWithToReviewStatus = countby(cards, (o) => {
      return o.userLinkCard && o.userLinkCard.status === TO_REVIEW_STATUS;
    });

    if (newCountCardsWithToReviewStatus.true !== this.countCardsWithToReviewStatus.true) {
      const refetchQueries = [{ query: GET_CURRENT_USER }];

      if (newCountCardsWithLearnedStatus.true === cards.length) {
        mutate({
          variables: {
            collection: id,
            status: LEARNED_STATUS,
          },
          refetchQueries,
        });
      } else if (newCountCardsWithToReviewStatus.true > 0) {
        mutate({
          variables: {
            collection: id,
            status: TO_REVIEW_STATUS,
          },
          refetchQueries,
        });
      } else {
        mutate({
          variables: {
            collection: id,
            status: NEUTRAL_STATUS,
          },
          refetchQueries,
        });
      }

      this.countCardsWithLearnedStatus = newCountCardsWithLearnedStatus;
      this.countCardsWithToReviewStatus = newCountCardsWithToReviewStatus;
    }
  }

  render() {
    const { t, match, history, user, collection } = this.props;

    return (
      <Box width={1 / 1}>
        <Helmet>
          <title>
            {t('Liste')} {collection.title}
          </title>
        </Helmet>
        <Print collection={collection} />
        {!collection.isPublished && collection.author.id === user.id && <Preview collectionId={collection.id} />}
        <Tabs id="collection" className="screen">
          <Container>
            <MediaQuery query={`(min-width: ${breakpoints.md})`}>
              <Header collection={collection} history={history} />
            </MediaQuery>
          </Container>
          <Navigation>
            <Navigation.Section label={t('training')}>
              <TabListStyled>
                <TabStyled>
                  <Navigation.Item icon={<Navigation.BookIcon />} label={t('game.list')} color={theme.colors.primary} />
                </TabStyled>
                <TabStyled>
                  <Navigation.Item
                    icon={<Navigation.RectoVersoIcon />}
                    label={t('game.rectoverso')}
                    color={theme.colors.primary}
                  />
                </TabStyled>
              </TabListStyled>
            </Navigation.Section>
            <Navigation.Section label={t('gameLabel_plural')} pl="1.2rem">
              {collection.assoMode && (
                <Link to={{pathname:`/series/${match.params.id}/association`, state:history.location.state}}>
                  <Navigation.Item
                    icon={<Navigation.AssoIcon />}
                    label={t('game.asso')}
                    color={theme.colors.secondary}
                  />
                </Link>
              )}
              {collection.typoMode && (
                <Link to={{pathname:`/series/${match.params.id}/typographie`, state:history.location.state}}>
                  <Navigation.Item
                    icon={<Navigation.TypoIcon />}
                    label={t('game.typo')}
                    color={theme.colors.secondary}
                  />
                </Link>
              )}
              {collection.memoMode && (
                <Link to={{pathname:`/series/${match.params.id}/memorisation`, state:history.location.state}}>
                  <Navigation.Item
                    icon={<Navigation.MemoIcon />}
                    label={t('game.memo')}
                    color={theme.colors.secondary}
                  />
                </Link>
              )}
              {collection.brioMode && (
                <MediaQuery query={`(min-width: ${breakpoints.md})`}>
                  <Link to={{pathname:`/series/${match.params.id}/brio`, state:history.location.state}}>
                    <Navigation.Item
                      icon={<Navigation.BrainIcon />}
                      label={t('game.brio')}
                      color={theme.colors.secondary}
                    />
                  </Link>
                </MediaQuery>
              )}
              {collection.quizakoMode && (
                <Link to={{pathname:`/series/${match.params.id}/quizako`, state:history.location.state}}>
                  <Navigation.Item
                    icon={<Navigation.TypoIcon />} /* TODO: Quizako icon */
                    label={t('game.quizako')}
                    color={theme.colors.secondary}
                  />
                </Link>
              )}
            </Navigation.Section>
          </Navigation>
          <Container>
            <MediaQuery query={`(max-width: ${breakpoints.smPlus})`}>
              {collection && <Header collection={collection} />}
            </MediaQuery>
            <Content>
              <TabPanel>
                <Panels.List collection={collection} history={history}/>
              </TabPanel>
              <TabPanel>
                <Panels.TwoSides collection={collection} history={history} />
              </TabPanel>
            </Content>
          </Container>
        </Tabs>
      </Box>
    );
  }
}

export default withNamespaces()(Collection);
