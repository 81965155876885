import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import AuthorFolder from './AuthorFolder';
import UserFolder from './UserFolder';

class Folder extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    folder: PropTypes.object,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { folder, user } = this.props;

    if (folder.author.id === user.id) {
      return <AuthorFolder {...this.props} />;
    }

    return <UserFolder {...this.props} />;
  }
}

export default Folder;
