// fonts
export const fontSizeBase = 1.6;
export const modularScale = 1.25;
const fontFamilyHeadings = 'Montserrat, sans-serif';

// breakpoints
export const breakpoints = {
  xsm: '360px',
  sm: '480px',
  smPlus: '699px',
  md: '700px',
  mdPlus: '1023px',
  lg: '1024px',
  lgPlus: '1279px',
  xl: '1280px',
  xlPlus: '1479px',
  xxl: '1480px',
};

export const spacers = {
  n: 0,
  xs: '1rem',
  sm: '2rem',
  md: '3rem',
  lg: '4rem',
  xl: '5rem',
};

const colors = {
  primary: '#5F63BB',
  primaryDark: '#413C73',
  primaryLight: '#5357B3',
  secondary: '#16D8C5',
  secondaryDark: '#14B9A9',
  secondaryLight: '#D0F7F4',
  tertiary: '#FF6260',
  tertiaryLight: '#FFE8E7',
  border: '#F3F3F3',
  inputBorder: '#E1E1E1',
  error: 'red',
  success: 'green',
  retry: '#FFB560',
  gray100: '#F6F7FC',
  gray200: '#FAFAFA',
  text: '#313030',
  placeholder: '#BDBDBD',
  selectLabel: '#818181',
  white: '#fff',
  black: '#3B3B3B',
  arts: '#F47A44',
  artsLight: 'rgba(221, 153, 0, .13)',
  body: '#0083CA',
  bodyLight: 'rgba(0, 131, 202, .13)',
  general: '#6D4099',
  generalLight: 'rgba(221, 153, 0, .13',
  languages: '#DD9900',
  languagesLight: 'rgba(221, 153, 0, .13)',
  mathematics: '#DB3332',
  mathematicsLight: 'rgba(219, 51, 50, .13)',
  others: '#939393',
  othersLight: 'rgba(147, 147, 147, .13)',
  social: '#007046',
  socialLight: 'rgba(0, 112, 70, .13)',
  transversal: '#926E43',
  transversalLight: 'rgba(146, 110, 67, .13)',
};

export const theme = {
  breakpoints: [breakpoints.sm, breakpoints.md, breakpoints.lg, breakpoints.xl, breakpoints.xxl],
  fonts: {
    base: '"Open Sans", sans-serif',
    heading: fontFamilyHeadings,
    mono: '"Courier New", Courier, monospace',
  },
  heading: {
    fontFamily: fontFamilyHeadings,
  },
  fontSizes: ['1.4rem', '1.6rem'],
  fontWeights: {
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  lineHeightBase: 1.5,
  colors: {
    ...colors,
  },
  space: [spacers.n, spacers.xs, spacers.sm, spacers.md, spacers.lg, spacers.xl],
};
