import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import CollectionList from 'src/components/blocks/CollectionList';
import ListLayout from 'src/layouts/List';
import Pagination from 'src/components/blocks/Pagination';
import Select from 'src/components/elements/Select';

class Favorites extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleOrderChange: PropTypes.func,
    onPageChange: PropTypes.func,
    orderBy: PropTypes.string,
    page: PropTypes.number,
    collections: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, handleOrderChange, onPageChange, orderBy, page, collections, count } = this.props;

    return (
      <ListLayout
        icon={<ListLayout.FavoritesIcon />}
        title={t('myFavorites')}
        sort={
          <Select
            name="sort"
            onChange={handleOrderChange}
            value={orderBy}
            options={[
              { id: 'newest', name: t('filters.newest') },
              { id: 'oldest', name: t('filters.oldest') },
              { id: 'alphabetical', name: t('filters.alphabetical') },
              { id: 'reverseAlphabetical', name: t('filters.reverseAlphabetical') },
            ]}
          />
        }
      >
        <Box width={1 / 1}>
          {count > 0 ? <CollectionList data={collections} /> : <p>Pas de résultat</p>}
          {count > Pagination.limit && <Pagination total={count} page={page} onPageChange={onPageChange} />}
        </Box>
      </ListLayout>
    );
  }
}

export default withNamespaces()(Favorites);
