"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBrioGame = void 0;
var client_1 = require("@apollo/client");
var react_1 = require("react");
var cards_1 = require("src/queries/cards");
var collections_1 = require("src/queries/collections");
var duels_1 = require("src/queries/duels");
var duels_2 = require("src/utils/duels");
var games_1 = require("src/utils/games");
var useTimer_1 = require("./useTimer");
var ERROR_EVENT_DELAY = 1900;
var END_GAME_DELAY = 1900;
var ALLOWED_ERRORS = 3;
var useBrioGame = function (_a) {
    var collection = _a.collection, duel = _a.duel, difficulty = _a.difficulty, isDuelSession = _a.isDuelSession, selectedUser = _a.selectedUser, history = _a.history, initialCards = _a.initialCards, timeSetting = _a.timeSetting;
    var _b = (0, react_1.useState)({
        score: 0,
        errors: 0,
        answers: 0,
        goodAnswersInRow: 0,
        currentCardIndex: 0,
        isValidating: false
    }), gameState = _b[0], setGameState = _b[1];
    var contentRef = (0, react_1.useRef)(null);
    var timerRef = (0, react_1.useRef)(null);
    var cardTimerRef = (0, react_1.useRef)(null);
    (0, useTimer_1.useTimers)(contentRef, [timerRef, cardTimerRef], ['timer', 'cardTimer']);
    var timeout = (0, react_1.useRef)();
    var cards = (0, react_1.useRef)(initialCards);
    var setCardStatus = (0, client_1.useMutation)(cards_1.SET_CARD_STATUS)[0];
    var finishGame = (0, client_1.useMutation)(collections_1.FINISH_GAME)[0];
    var saveScore = (0, client_1.useMutation)(collections_1.SAVE_SCORE)[0];
    var saveDuelScore = (0, client_1.useMutation)(duels_1.SAVE_DUEL_SCORE)[0];
    var addDuel = (0, client_1.useMutation)(duels_1.ADD_DUEL)[0];
    var restartTimers = (0, react_1.useCallback)(function () {
        if (timeout.current)
            clearTimeout(timeout.current);
        timeout.current = setTimeout(function () {
            if (cardTimerRef.current && timerRef.current) {
                cardTimerRef.current.resetTime(timeSetting);
                cardTimerRef.current.startTime();
                timerRef.current.startTime();
            }
        }, ERROR_EVENT_DELAY);
    }, [timeSetting]);
    (0, react_1.useEffect)(function () {
        if (gameState.errors !== 0 && gameState.errors < ALLOWED_ERRORS) {
            restartTimers();
        }
        else if (gameState.errors >= ALLOWED_ERRORS) {
            handleEndOfGame();
        }
    }, [gameState.errors]);
    var handleEndOfGame = (0, react_1.useCallback)(function () {
        var args_1 = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args_1[_i] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (duelId) {
            var success, time, finalScore;
            var _a, _b, _c;
            if (duelId === void 0) { duelId = null; }
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        success = gameState.errors < ALLOWED_ERRORS;
                        time = (_c = (_b = (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.timer) === null || _b === void 0 ? void 0 : _b.time) !== null && _c !== void 0 ? _c : 0;
                        finalScore = (0, games_1.getFinalScore)(gameState.score, gameState.errors, gameState.goodAnswersInRow);
                        return [4 /*yield*/, finishGame({
                                variables: {
                                    collection: collection.id,
                                    game: games_1.GAME_BRIO,
                                    score: finalScore,
                                    time: time,
                                    won: success,
                                },
                            })];
                    case 1:
                        _d.sent();
                        history.push("/series/".concat(collection.id, "/resultats"), {
                            answers: gameState.answers,
                            difficulty: difficulty,
                            duel: duelId,
                            errors: gameState.errors,
                            mode: games_1.GAME_BRIO,
                            score: success ? finalScore : 0,
                            success: success,
                            time: time,
                        });
                        return [2 /*return*/];
                }
            });
        });
    }, [gameState, collection, difficulty, history]);
    var onError = (0, react_1.useCallback)(function (cardId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setCardStatus({
                        variables: {
                            card: cardId,
                            status: 'TO_REVIEW',
                        },
                    })];
                case 1:
                    _a.sent();
                    if (timerRef.current && cardTimerRef.current) {
                        timerRef.current.stopTime();
                        cardTimerRef.current.stopTime();
                    }
                    setGameState(function (prev) { return (__assign(__assign({}, prev), { errors: prev.errors + 1, goodAnswersInRow: 0 })); });
                    return [2 /*return*/];
            }
        });
    }); }, []);
    var onSuccess = function (cardId_1) {
        var args_1 = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args_1[_i - 1] = arguments[_i];
        }
        return __awaiter(void 0, __spreadArray([cardId_1], args_1, true), void 0, function (cardId, partial) {
            if (partial === void 0) { partial = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, setCardStatus({
                            variables: {
                                card: cardId,
                                status: 'LEARNED',
                            },
                        })];
                    case 1:
                        _a.sent();
                        setGameState(function (prev) { return (__assign(__assign({}, prev), { score: prev.score + 1, answers: partial ? prev.answers : prev.answers + 1, goodAnswersInRow: partial ? prev.goodAnswersInRow : prev.goodAnswersInRow + 1 })); });
                        if (cardTimerRef.current) {
                            cardTimerRef.current.resetTime(timeSetting);
                            cardTimerRef.current.startTime();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    var onTimeEnd = (0, react_1.useCallback)(function () {
        var _a, _b, _c, _d;
        var time = (_c = (_b = (_a = contentRef.current) === null || _a === void 0 ? void 0 : _a.timer) === null || _b === void 0 ? void 0 : _b.time) !== null && _c !== void 0 ? _c : 0;
        if (isDuelSession) {
            (0, duels_2.handleDuel)(addDuel, saveDuelScore, (_d = duel === null || duel === void 0 ? void 0 : duel.duel.id) !== null && _d !== void 0 ? _d : null, collection.id, selectedUser, games_1.GAME_BRIO, difficulty, gameState.answers, (0, games_1.getFinalScore)(gameState.score, gameState.errors, gameState.goodAnswersInRow), gameState.errors, time, cards.current, function (duelId) { return handleEndOfGame(duelId); });
        }
        else {
            saveScore({
                variables: {
                    collection: collection.id,
                    errors: gameState.errors,
                    game: games_1.GAME_BRIO,
                    score: (0, games_1.getFinalScore)(gameState.score, gameState.errors, gameState.goodAnswersInRow),
                    time: time,
                },
            }).then(function () { return handleEndOfGame(); });
        }
    }, [gameState, collection, difficulty, isDuelSession, selectedUser]);
    return {
        timerRef: timerRef,
        cardTimerRef: cardTimerRef,
        gameState: gameState,
        cards: cards.current,
        actions: {
            onError: onError,
            onSuccess: onSuccess,
            onTimeEnd: onTimeEnd,
            setGameState: setGameState
        }
    };
};
exports.useBrioGame = useBrioGame;
