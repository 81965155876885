import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import DuelSvg from 'assets/svg/duel.svg';

const Container = styled(Box)`
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px 1px rgba(148, 140, 140, 0.2);

  @media (min-width: ${breakpoints.md}) {
    border-radius: 7.4rem;
  }
`;

const AvatarContainer = styled(Box)`
  width: 3.5rem;
  height: 3.5rem;

  @media (min-width: ${breakpoints.md}) {
    order: 1;
    width: 6rem;
    height: 6rem;
  }
`;

const DuelIcon = styled(DuelSvg)`
  ${svgStyle};
  width: 4.2rem;
  height: 3.8rem;
`;

class DuelResults extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
  };

  render() {
    const { data } = this.props;
    const opponent = data.duel.opponent;
    const challenger = data.duel.challenger;

    return (
      <Container p="1.5rem" bg="white">
        <Grid alignItems={['flex-start', 'flex-start', 'center']}>
          <Col flex="1">
            <Flex width={1 / 1} alignItems="center" flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}>
              <AvatarContainer>
                {challenger.avatar && (
                  <img
                    src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${challenger.avatar.image}`}
                  />
                )}
              </AvatarContainer>
              <Text
                fontFamily="heading"
                fontSize={['1.8rem', '1.8rem', '1.8rem', '3.2rem']}
                fontWeight="semibold"
                textAlign="right"
                css={`
                  flex: 1 1 auto;
                  @media (min-width: ${breakpoints.md}) {
                    order: 3;
                  }
                `}
              >
                {data.duel.scoreChallenger !== null ? data.duel.scoreChallenger : '?'}
              </Text>
              <Box
                pl={1}
                css={`
                  flex: 0 0 100%;
                  @media (min-width: ${breakpoints.md}) {
                    order: 2;
                    flex: 1 1 auto;
                  }
                `}
              >
                <Text fontFamily="heading" fontSize={['1.4rem', '1.4rem', '1.4rem', '2.6rem']} fontWeight="semibold">
                  {challenger.firstname}
                </Text>
              </Box>
            </Flex>
          </Col>
          <Col flex="0 0 auto">
            <Text color="primary">
              <DuelIcon />
            </Text>
          </Col>
          <Col flex="1">
            <Flex
              flexDirection="row-reverse"
              alignItems="center"
              flexWrap={['wrap', 'wrap', 'wrap', 'nowrap']}
              width={1 / 1}
            >
              <AvatarContainer>
                {opponent.avatar && (
                  <img
                    src={`${process.env.ASSETS_BASE_URL}${process.env.AVATARS_UPLOAD_DIR}/${opponent.avatar.image}`}
                  />
                )}
              </AvatarContainer>
              <Text
                fontFamily="heading"
                fontSize={['1.8rem', '1.8rem', '1.8rem', '3.2rem']}
                fontWeight="semibold"
                textAlign="left"
                css={`
                  flex: 1 1 auto;
                  @media (min-width: ${breakpoints.md}) {
                    order: 3;
                  }
                `}
              >
                {data.duel.scoreOpponent !== null ? data.duel.scoreOpponent : '?'}
              </Text>
              <Box
                pr={1}
                css={`
                  flex: 0 0 100%;
                  @media (min-width: ${breakpoints.md}) {
                    order: 2;
                    flex: 1 1 auto;
                  }
                `}
              >
                <Text
                  fontFamily="heading"
                  fontSize={['1.4rem', '1.4rem', '1.4rem', '2.6rem']}
                  fontWeight="semibold"
                  textAlign="right"
                >
                  {opponent.firstname}
                </Text>
              </Box>
            </Flex>
          </Col>
        </Grid>
      </Container>
    );
  }
}

export default DuelResults;
