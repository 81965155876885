import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import Audio from 'src/components/blocks/Card/Audio';
import { Container } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { LoadingContext } from 'src/context';
import { CardType } from 'src/gql/graphql';
import { breakpoints } from 'src/styles/theme';
import styled from 'styled-components';

const ImageContainer = styled(Box)`
  width: 100%;
  max-width: 22rem;
  max-height: 14.5rem;
  img {
    max-height: 14.5rem;
  }

  @media (min-width: ${breakpoints.md}) {
    max-width: 28.2rem;
    max-height: 18.8rem;
    img {
      max-height: 18.8rem;
    }
  }
`;

class Collection extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    collection: PropTypes.object,
  };

  renderCard(card, key) {
    const { label, audio, image } = card;

    return (
      <Col key={key} width={1 / 2} pb={2}>
        <Flex width={1 / 1} alignItems="center" justifyContent="center" flexWrap="wrap" bg="gray200">
          <Col width={1 / 1} justifyContent="center" py="2rem">
            <Text fontFamily="heading" fontSize="2.2rem" fontWeight="semibold">
              {label}
            </Text>
          </Col>
          {(image || audio) && (
            <Col width={1 / 1} justifyContent="center" pb={[0, 0, 2]}>
              <Flex width={1 / 1} justifyContent="center">
                {image && (
                  <Col flex="0 0 auto" alignItems="center" justifyContent="center">
                    <ImageContainer>
                      <img src={`${process.env.ASSETS_BASE_URL}${process.env.CARDS_UPLOAD_DIR}/${image}`} />
                    </ImageContainer>
                  </Col>
                )}
                {audio && (
                  <Col flex={['0 0 6rem', '0 0 6rem', '0 0 20rem']} alignItems="center" justifyContent="center">
                    <Audio audio={audio} />
                  </Col>
                )}
              </Flex>
            </Col>
          )}
        </Flex>
      </Col>
    );
  }

  render() {
    const { t, collection } = this.props;

    return (
      <Container className="print">
        <Text textAlign="center">
          <Text pt="2rem" pb=".8rem" fontSize="1.8rem">
            <Text as="span" css="text-transform: uppercase;">
              {collection.serialNumber}
            </Text>{' '}
            - {t('byAuthor', { val: `${collection.author.firstname} ${collection.author.lastname}` })}
          </Text>
          <Text as="h2" mt="0" pb=".8rem" fontFamily="heading" fontSize="2rem" fontWeight="medium">
            {collection.title}
          </Text>
          <Text as="p" mt="0" pb="1rem">
            {collection.description}
          </Text>
        </Text>
        <Box as="section" pt={2}>
          <Grid>
            {collection.cards.map(card => {
              const result = [];
              let back = {};
              if (card.type === CardType.SingleResponse) {
                back = { label: card.backFace?.text, audio: card.backFace?.audio, image: card.backFace?.image };
              } else if (card.type === CardType.MultipleResponse) {
                back = { label: card.backFaces.filter(backFace => backFace.isCorrect).map(backFace => backFace.text).join('/') };
              } else if (card.type === CardType.TrueFalse) {
                back = { label: card.frontFace.isCorrect ? 'Vrai' : 'Faux' };
              }

              [
                { label: card.frontFace.text, audio: card.frontFace.audio, image: card.frontFace.image },
                back,
              ].forEach((element, key) => {
                result.push(this.renderCard(element, key));
              });

              return result;
            })}
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default withNamespaces()(Collection);
