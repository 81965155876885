"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sameCards = exports.pickCard = exports.copyToClipboard = void 0;
var copyToClipboard = function (text) {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
};
exports.copyToClipboard = copyToClipboard;
var pickCard = function (card) {
    return {
        type: card.type,
        frontFace: {
            text: card.frontFace.text,
            description: card.frontFace.description,
            image: card.frontFace.image,
            audio: card.frontFace.audio,
            isCorrect: card.frontFace.isCorrect,
        },
        backFace: card.backFace ? {
            text: card.backFace.text,
            description: card.backFace.description,
            image: card.backFace.image,
            audio: card.backFace.audio,
            isCorrect: card.backFace.isCorrect,
        } : null,
        backFaces: card.backFaces.map(function (backFace) { return ({
            text: backFace.text,
            description: backFace.description,
            image: backFace.image,
            audio: backFace.audio,
            isCorrect: backFace.isCorrect,
        }); }),
    };
};
exports.pickCard = pickCard;
var sameCards = function (a, b) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return a.frontFace.text === b.frontFace.text
        && a.frontFace.description === b.frontFace.description
        && a.frontFace.image === b.frontFace.image
        && a.frontFace.audio === b.frontFace.audio
        && a.frontFace.isCorrect === b.frontFace.isCorrect
        && ((_a = a.backFace) === null || _a === void 0 ? void 0 : _a.text) === ((_b = b.backFace) === null || _b === void 0 ? void 0 : _b.text)
        && ((_c = a.backFace) === null || _c === void 0 ? void 0 : _c.description) === ((_d = b.backFace) === null || _d === void 0 ? void 0 : _d.description)
        && ((_e = a.backFace) === null || _e === void 0 ? void 0 : _e.image) === ((_f = b.backFace) === null || _f === void 0 ? void 0 : _f.image)
        && ((_g = a.backFace) === null || _g === void 0 ? void 0 : _g.audio) === ((_h = b.backFace) === null || _h === void 0 ? void 0 : _h.audio)
        && ((_j = a.backFace) === null || _j === void 0 ? void 0 : _j.isCorrect) === ((_k = b.backFace) === null || _k === void 0 ? void 0 : _k.isCorrect)
        && a.backFaces.every(function (backFace, index) { return backFace.text === b.backFaces[index].text
            && backFace.description === b.backFaces[index].description
            && backFace.image === b.backFaces[index].image
            && backFace.audio === b.backFaces[index].audio
            && backFace.isCorrect === b.backFaces[index].isCorrect; });
};
exports.sameCards = sameCards;
