import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'rebass';
import { LinkButton } from 'src/components/elements/Button';
import Title from 'src/components/typography/Title';

class SubjectRow extends PureComponent {
  render() {
    const { children, id, title, showLinkButton } = this.props;

    return (
      <Box as="section" pt={3}>
        <Title sm medium>
          {title}
        </Title>
        {children}
        {showLinkButton && (
          <Text pt={1} textAlign="center">
            <LinkButton to={`/chaines/recommandees/${id}`} css="width: 100%; max-width: 22rem;">
              Voir plus de chaînes
            </LinkButton>
          </Text>
        )}
      </Box>
    );
  }
}

SubjectRow.propTypes = {
  children: PropTypes.any,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  showLinkButton: PropTypes.bool,
};

export default SubjectRow;
