/*global require*/
let bugsnagClient = null;
if (process.env.APP_ENV != "dev") {
  const bugsnag = require("bugsnag-js");
  bugsnagClient = bugsnag(process.env.BUGSNAG_API_KEY_FRONT);
}

import { ApolloClient, ApolloProvider, InMemoryCache, defaultDataIdFromObject } from "@apollo/client";
import loadable from "@loadable/component";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import createPlugin from "bugsnag-react";
import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { GlobalStyle } from "src/styles/global";
import { theme } from "src/styles/theme";

import Errors from "src/pages/Errors";
import LoggedInit from "./LoggedInit";

Modal.setAppElement("#root");

// Apollo
const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "createUserPayload": {
        object.__typename = "User";
      }
    }
    return defaultDataIdFromObject(object);
  },
  typePolicies: {
    Card: {
      fields: {
        backFaces: {
          merge(existing, incoming) {
            return incoming; // Replace existing data with incoming data
          },
        },
      },
    },
  },
});

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink({
    uri: process.env.API_URL,
    credentials: "include",
  }),
  new BatchHttpLink({
    uri: process.env.BATCH_API_URL,
    credentials: "include",
  })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors);
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    httpLink,
  ]),
  cache,
});

const { matchesUA } = require("browserslist-useragent");

const browserslist = require("browserslist");

const Landing = loadable(() =>
  import(/* webpackChunkName: 'landing' */ "./pages/Landing")
);

const UnsupportedBrowser = loadable(() => import("./pages/UnsupportedBrowser"));

const App = ({ isLogged }) => {
  const browsers = browserslist([
    "> 0%",
    "not dead",
    "not ie <= 11",
    "not chrome < 51",
    "not firefox < 54",
    "not safari < 10",
    "not edge < 15",
    "not opera < 38",
  ]);
  const isMatching = matchesUA(window.navigator.userAgent, {
    path: ".",
    browsers,
    allowHigherVersions: true,
  });

  return (
    <BrowserRouter basename={process.env.BASENAME}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <React.Fragment>
            <GlobalStyle />
            {isMatching ? (
              <>
                {!isLogged && <Landing />}
                {isLogged && <LoggedInit />}
              </>
            ) : (
              <UnsupportedBrowser />
            )}
          </React.Fragment>
        </ApolloProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

App.displayName = "App";
App.propTypes = {
  isLogged: PropTypes.bool.isRequired,
};

let AppExported = null;
if (process.env.APP_ENV != "dev") {
  const ErrorBoundary = bugsnagClient.use(createPlugin(React));
  // eslint-disable-next-line react/display-name
  AppExported = (props) => (
    <ErrorBoundary FallbackComponent={Errors}>
      <App {...props} />
    </ErrorBoundary>
  );
} else {
  AppExported = App;
}

export default AppExported;
