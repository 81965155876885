import React, { Component } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash.groupby';
import { Query } from '@apollo/client/react/components';
import { GET_FOLDERS } from 'src/queries/folders';
import Content from './Content';

class AuthorFoldersContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;

  render() {
    const { user } = this.props;

    return (
      <Query
        query={GET_FOLDERS}
        fetchPolicy="network-only"
        variables={{
          filterBy: [{ join: 'author', property: 'id', where: user.id }],
          withCollections: true,
        }}
      >
        {({ data, error, loading }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const folders = data.folders ? groupBy(data.folders.results, folder => folder.schoolSubject.name) : {};

          return <Content count={data.folders.count} folders={folders} user={user} />;
        }}
      </Query>
    );
  }
}

export default AuthorFoldersContainer;
