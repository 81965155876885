import SearchIcon from 'assets/svg/search.svg';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { Box, Flex, Text } from 'rebass';
import CollectionList from 'src/components/blocks/CollectionList';
import Pagination from 'src/components/blocks/Pagination';
import Tabs from 'src/components/blocks/Tabs';
import { Button } from 'src/components/elements/Button';
import Input from 'src/components/elements/InputSearchbar';
import Select from 'src/components/elements/Select';
import { Container, Content } from 'src/components/utilities/Containers';
import { Col, Grid } from 'src/components/utilities/Grids';
import { LoadingContext } from 'src/context';
import { breakpoints } from 'src/styles/theme';
import styled, { css } from 'styled-components';

const SearchIconStyled = styled(SearchIcon)`
  display: inline-block;
  width: auto;
  height: 1.5em;
  vertical-align: top;
  fill: currentColor;
  stroke-width: 0;
  stroke: currentColor;
`;

const tabStyle = css`
  flex: 1;
  @media (min-width: ${breakpoints.md}) {
    flex: 0 0 22.4rem;
  }
`;

class FolderContent extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleChangeDegree: PropTypes.func,
    handleChangeOrder: PropTypes.func,
    handleChangeSubject: PropTypes.func,
    handleSearchChange: PropTypes.func,
    onAuthorCollectionsPageChange: PropTypes.func,
    onCollectionsPageChange: PropTypes.func,
    onOfficialCollectionsPageChange: PropTypes.func,
    setCollectionOfFolders: PropTypes.func,
    toggleCollectionInFolder: PropTypes.func,
    authorCollectionsData: PropTypes.object,
    authorCollectionsPage: PropTypes.number,
    collectionsData: PropTypes.object,
    collectionsPage: PropTypes.number,
    collectionsOfFolders: PropTypes.array,
    collectionsOrderBy: PropTypes.string,
    degrees: PropTypes.object,
    degree: PropTypes.string,
    history: PropTypes.object,
    officialCollectionsData: PropTypes.object,
    officialCollectionsPage: PropTypes.number,
    search: PropTypes.string,
    subjects: PropTypes.object,
    subject: PropTypes.string,
    folderId: PropTypes.string,
  };

  state = {
    searchValue: this.props.search ?? ''
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const {
      t,
      handleChangeDegree,
      handleChangeOrder,
      handleChangeSubject,
      handleSearchChange,
      onAuthorCollectionsPageChange,
      onCollectionsPageChange,
      onOfficialCollectionsPageChange,
      setCollectionOfFolders,
      toggleCollectionInFolder,
      authorCollectionsData,
      authorCollectionsPage,
      collectionsData,
      collectionsPage,
      collectionsOfFolders,
      collectionsOrderBy,
      degrees,
      degree,
      folderId,
      history,
      officialCollectionsData,
      officialCollectionsPage,
      search,
      subjects,
      subject,
      tabsIndex,
      onChangeTabsIndex
    } = this.props;

    const { searchValue } = this.state;

    return (
      <Container>
        <Content mt={3}>
          <Tabs selectedIndex={tabsIndex} onSelect={onChangeTabsIndex}>
            <Grid justifyContent="space-between">
              <Col width={1 / 1} alignItems="center" justifyContent="flex-end">
                <Grid justifyContent="flex-end">
                  <Col flex={['0 0 50%', '0 0 50%', '1 1 auto']} pb={2}>
                    <Select
                      name="sort"
                      onChange={handleChangeOrder}
                      value={collectionsOrderBy}
                      options={[
                        { id: 'newest', name: t('filters.newest') },
                        { id: 'oldest', name: t('filters.oldest') },
                        { id: 'alphabetical', name: t('filters.alphabetical') },
                        { id: 'reverseAlphabetical', name: t('filters.reverseAlphabetical') },
                      ]}
                    />
                  </Col>
                  <Col flex={['0 0 50%', '0 0 50%', '1 1 auto']} pb={2}>
                    <Select
                      name="subjects"
                      onChange={handleChangeSubject}
                      defaultValue={t('allSchoolSubjects')}
                      options={subjects.data.subjects}
                      value={subject}
                    />
                  </Col>
                  <Col flex={['0 0 100%', '0 0 100%', '1 1 auto']} pb={[3, 3, 2]}>
                    <Select
                      name="degrees"
                      onChange={handleChangeDegree}
                      defaultValue={t('allSchoolDegrees')}
                      options={degrees.data.degrees}
                      value={degree}
                    />
                  </Col>
                </Grid>
              </Col>
              <Col width={1 / 1}>
                <Tabs.TabList css="width: 100%;">
                  <Tabs.Tab css={tabStyle}>{t('mySeries')}</Tabs.Tab>
                  <Tabs.Tab css={tabStyle}>{t('officialSerie_plural')}</Tabs.Tab>
                  <Tabs.Tab css={tabStyle}>{t('otherSerie_plural')}</Tabs.Tab>
                </Tabs.TabList>
              </Col>
              <Col width={1 / 1} flexDirection="column" pt={3}>
                <Text as="label" pb={1} fontSize="1.2rem" htmlFor="search">
                  {t('searchASerie')}
                </Text>
                <Flex alignItems="center" css="width: 100%; max-width: 43rem;">
                  <Input
                    sm
                    css="flex: 1 1 100%; margin-right: 1rem"
                    placeholder={t('searchIdOrKeyword')}
                    id="search"
                    type="search"
                    name="search"
                    value={searchValue}
                    onKeyPress={(e) => e.key === 'Enter' && handleSearchChange(searchValue.trim())}
                    onChange={(e) => this.setState({searchValue: e.target.value})}
                  />
                  <Button secondary smIcon css="flex: 0 0 auto;" type="button" onClick={e => handleSearchChange(searchValue.trim())}>
                    <SearchIconStyled />
                  </Button>
                </Flex>
              </Col>
            </Grid>
            <Tabs.TabPanel>
              <Box as="section" pt={1}>
                {authorCollectionsData && (
                  <React.Fragment>
                    <CollectionList
                      data={authorCollectionsData.results}
                      toggleCollectionInFolder={toggleCollectionInFolder}
                      collectionsOfFolders={collectionsOfFolders}
                    />
                    {authorCollectionsData?.count > 0 && (
                      <Pagination
                        total={authorCollectionsData.count}
                        page={authorCollectionsPage}
                        onPageChange={onAuthorCollectionsPageChange}
                      />
                    )}
                  </React.Fragment>
                )}
              </Box>
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <Box as="section" pt={1}>
                {officialCollectionsData && (
                  <React.Fragment>
                    <CollectionList
                      data={officialCollectionsData.results}
                      toggleCollectionInFolder={toggleCollectionInFolder}
                      collectionsOfFolders={collectionsOfFolders}
                    />
                    {officialCollectionsData?.count > 0 && (
                      <Pagination
                        total={officialCollectionsData.count}
                        page={officialCollectionsPage}
                        onPageChange={onOfficialCollectionsPageChange}
                      />
                    )}
                  </React.Fragment>
                )}
              </Box>
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <Box as="section" pt={1}>
                {collectionsData && (
                  <React.Fragment>
                    <CollectionList
                      data={collectionsData.results}
                      toggleCollectionInFolder={toggleCollectionInFolder}
                      collectionsOfFolders={collectionsOfFolders}
                    />
                    {collectionsData?.count > 0 && (
                      <Pagination
                        total={collectionsData.count}
                        page={collectionsPage}
                        onPageChange={onCollectionsPageChange}
                      />
                    )}
                  </React.Fragment>
                )}
              </Box>
            </Tabs.TabPanel>
          </Tabs>
          <Text pt={3} color="primary" fontFamily="heading" fontWeight="semibold" textAlign="center">
            {t('selectedSerieWithCount', { count: (collectionsOfFolders && collectionsOfFolders.length) || 0 })}
          </Text>
          <Text pt={3} pb="5rem" textAlign="center">
            <Button
              onClick={() => {
                setCollectionOfFolders({
                  variables: {
                    folder: folderId,
                    collections: collectionsOfFolders,
                  },
                }).then(() => history.push(`/chaines/${folderId}`));
              }}
              css="width: 100%; max-width: 18rem; margin: auto;"
            >
              {t('validate')}
            </Button>
          </Text>
        </Content>
      </Container>
    );
  }
}

export default withNamespaces()(FolderContent);
