import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import { theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import ChevronSvg from 'assets/svg/chevron.svg';
import ChevronPrevSvg from 'assets/svg/chevron-prev.svg';

const PREV_DIRECTION = 'prev';
const NEXT_DIRECTION = 'next';

const chevronStyle = css`
  ${svgStyle};
  width: 0.8rem;
  height: 1.5rem;
`;

const ChevronIcon = styled(ChevronSvg)`
  ${chevronStyle};
`;

const ChevronPrevIcon = styled(ChevronPrevSvg)`
  ${chevronStyle};
`;

const Button = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(195, 193, 193, 0.5);
  color: ${theme.colors.primary};
  outline: none;
`;

const SwiperButton = ({ onClick, direction }) => (
  <Button as="button" type="button" onClick={onClick}>
    {direction === PREV_DIRECTION ? <ChevronPrevIcon /> : <ChevronIcon />}
  </Button>
);

SwiperButton.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.oneOf([PREV_DIRECTION, NEXT_DIRECTION]),
};

SwiperButton.prevDirection = PREV_DIRECTION;
SwiperButton.nextDirection = NEXT_DIRECTION;

export default SwiperButton;
