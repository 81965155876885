import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { AccountIcon, BadgeIcon, BrainIcon, CollectionIcon, DuelIcon, TrophyIcon } from './Icons';

const Container = styled(Flex)`
  background-position: center;
  background-repeat: no-repeat;
  ${props => props.icon};
`;

class Statistic extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    icon: PropTypes.array,
  };

  render() {
    const { label, number, icon } = this.props;

    return (
      <Container width={1 / 1} icon={icon} alignItems="center" justifyContent="center">
        <Text fontFamily="heading" fontWeight="bold" textAlign="center">
          <Text color="secondary" fontSize={['4rem', '4rem', '7.4rem']} css="line-height: 1.4;">
            {number}
          </Text>
          <Text
            color="primary"
            fontSize={['2rem', '2rem', '3rem']}
            css="line-height: 1.4;"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        </Text>
      </Container>
    );
  }
}

Statistic.AccountIcon = AccountIcon;
Statistic.BadgeIcon = BadgeIcon;
Statistic.BrainIcon = BrainIcon;
Statistic.CollectionIcon = CollectionIcon;
Statistic.DuelIcon = DuelIcon;
Statistic.TrophyIcon = TrophyIcon;

export default Statistic;
