import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import { Box } from 'rebass';
import CollectionList from 'src/components/blocks/CollectionList';
import ListLayout from 'src/layouts/List';
import Select from 'src/components/elements/Select';
import Title from 'src/components/typography/Title';

class AuthorCollections extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleFilterChange: PropTypes.func,
    handleOrderChange: PropTypes.func,
    filterBy: PropTypes.string,
    orderBy: PropTypes.string,
    collections: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    schoolSubjects: PropTypes.array,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoading !== prevProps.isLoading) {
      this.context.toggleLoading();
    }
  }

  render() {
    const {
      t,
      handleFilterChange,
      handleOrderChange,
      filterBy,
      orderBy,
      count,
      collections,
      schoolSubjects,
      user,
    } = this.props;

    return (
      <ListLayout
        icon={<ListLayout.CollectionIcon />}
        title={t('mySeries')}
        sort={
          <Select
            name="sort"
            onChange={handleOrderChange}
            value={orderBy}
            options={[
              { id: 'newest', name: t('filters.newest') },
              { id: 'oldest', name: t('filters.oldest') },
              { id: 'alphabetical', name: t('filters.alphabetical') },
              { id: 'reverseAlphabetical', name: t('filters.reverseAlphabetical') },
            ]}
          />
        }
        filter={
          <Select
            name="filter"
            onChange={handleFilterChange}
            defaultValue="Toutes"
            options={schoolSubjects}
            value={filterBy}
          />
        }
      >
        {count > 0 ? (
          <Box width={1 / 1}>
            {Object.keys(collections)
              .sort((a, b) => {
                return orderBy === 'reverseAlphabetical' ? (a < b ? 1 : -1) : a < b ? -1 : 1;
              })
              .map((key, index) => (
                <Box key={`${index}-${index}`} as="section" pt={3}>
                  <Title sm medium>
                    {key}
                  </Title>
                  <CollectionList data={collections[key]} editableCards={true} author={user.id} />
                </Box>
              ))}
          </Box>
        ) : (
          <p>{t('noResult')}</p>
        )}
      </ListLayout>
    );
  }
}

export default withNamespaces()(AuthorCollections);
