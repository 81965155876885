import styled from 'styled-components';
import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import AssociationSvg from 'assets/svg/asso.svg';
import BrioSvg from 'assets/svg/brain.svg';
import MemorizingSvg from 'assets/svg/memo.svg';
import RectoVersoSvg from 'assets/svg/recto-verso.svg';
import TypographySvg from 'assets/svg/typo.svg';

export const AssociationIcon = styled(AssociationSvg)`
  ${svgStyle};
  width: 3.5rem;
  height: 3.2rem;

  @media (min-width: ${breakpoints.lg}) {
    width: 5.4rem;
    height: 4.8rem;
  }
  ${props => props.css};
`;

export const BrioIcon = styled(BrioSvg)`
  ${svgStyle};
  width: 3.5rem;
  height: 3.2rem;

  @media (min-width: ${breakpoints.lg}) {
    width: 5.4rem;
    height: 4.8rem;
  }
  ${props => props.css};
`;

export const MemorizingIcon = styled(MemorizingSvg)`
  ${svgStyle};
  width: 3.5rem;
  height: 3.2rem;

  @media (min-width: ${breakpoints.lg}) {
    width: 5.4rem;
    height: 4.8rem;
  }
  ${props => props.css};
`;

export const RectoVersoIcon = styled(RectoVersoSvg)`
  ${svgStyle};
  width: 3.5rem;
  height: 3.2rem;

  @media (min-width: ${breakpoints.lg}) {
    width: 5.4rem;
    height: 4.8rem;
  }
  ${props => props.css};
`;

export const TypographyIcon = styled(TypographySvg)`
  ${svgStyle};
  width: 3.5rem;
  height: 3.2rem;

  @media (min-width: ${breakpoints.lg}) {
    width: 5.4rem;
    height: 4.8rem;
  }
  ${props => props.css};
`;
