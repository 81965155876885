import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { GET_COLLECTIONS, COLLECTIONS_ORDER_BY } from 'src/queries/collections';
import groupBy from 'lodash.groupby';
import moment from 'moment';
import Content from './Content';

class CollectionsToReviewContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    orderBy: 'newest',
  };

  handleChange = (event) => {
    this.setState({
      orderBy: event.target.value,
    });
  };

  render() {
    const { user } = this.props;
    const { orderBy } = this.state;

    return (
      <Query
        query={GET_COLLECTIONS}
        variables={{
          filterBy: [
            { join: 'linkUsers', property: 'user', where: user.id },
            { join: 'linkUsers', property: 'status', where: "'TO_REVIEW'" },
          ],
          orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
        }}
      >
        {({ data, error, loading }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          const collections = data.collections
            ? groupBy(data.collections.results, (collection) =>
                moment(collection.updated)
                  .startOf('month')
                  .format('MMMM YYYY'),
              )
            : null;

          return (
            <Content
              handleChange={this.handleChange}
              collections={collections}
              count={data.collections.count}
              orderBy={orderBy}
            />
          );
        }}
      </Query>
    );
  }
}

export default CollectionsToReviewContainer;
