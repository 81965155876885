import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'rebass';
import { theme } from 'src/styles/theme';
import { visuallyHidden } from 'src/styles/helpers';

const Input = styled.input`
  ${visuallyHidden};
`;

const Button = styled(Flex)`
  position: relative;
  width: 100%;
  border: 3px solid ${theme.colors.secondary};
  border-radius: 5px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.secondary};
  cursor: pointer;
  transition: background-color 225ms ease, color 225ms ease;

  ${Input}:checked + & {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
  }
`;

class Mode extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.element,
    label: PropTypes.string.isRequired,
  };

  render() {
    const { name, value, checked, onChange, icon, label } = this.props;
    return (
      <React.Fragment>
        <Input
          css={visuallyHidden}
          type="radio"
          id={value}
          name={name}
          value={value}
          onChange={onChange}
          defaultChecked={checked}
        />
        <Button
          as="label"
          htmlFor={value}
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-end"
          py={['1.8rem']}
          isChecked={checked}
        >
          {icon}
          <Text
            pt={['1.8rem', '1.8rem', '2.2rem']}
            fontSize={['1.8rem', '1.8rem', '2.4rem']}
            fontFamily="heading"
            fontWeight="medium"
            textAlign="center"
            isChecked={checked}
          >
            {label}
          </Text>
        </Button>
      </React.Fragment>
    );
  }
}

export default Mode;
