"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var Recorder = function (_a) {
    var command = _a.command, onStop = _a.onStop, mediaOpts = _a.mediaOpts;
    var mediaRecorderRef = (0, react_1.useRef)();
    var chunksRef = (0, react_1.useRef)([]);
    var streamRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        var constraints = { audio: true, video: false };
        chunksRef.current = [];
        navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
            streamRef.current = stream;
            // Try different MIME types in order of preference
            var mimeTypes = [
                'audio/webm;codecs=opus',
                'audio/webm',
                'audio/ogg;codecs=opus',
                'audio/mp4'
            ];
            var recorderOptions = __assign({}, mediaOpts);
            // Find the first supported MIME type
            for (var _i = 0, mimeTypes_1 = mimeTypes; _i < mimeTypes_1.length; _i++) {
                var mimeType = mimeTypes_1[_i];
                if (MediaRecorder.isTypeSupported(mimeType)) {
                    recorderOptions.mimeType = mimeType;
                    break;
                }
            }
            try {
                mediaRecorderRef.current = new MediaRecorder(stream, recorderOptions);
            }
            catch (e) {
                mediaRecorderRef.current = new MediaRecorder(stream, mediaOpts || {});
            }
            mediaRecorderRef.current.ondataavailable = function (e) {
                chunksRef.current.push(e.data);
            };
            mediaRecorderRef.current.onstop = function () {
                var _a;
                var blob = new Blob(chunksRef.current, {
                    type: ((_a = mediaRecorderRef.current) === null || _a === void 0 ? void 0 : _a.mimeType) || 'audio/webm'
                });
                chunksRef.current = [];
                onStop(blob);
            };
        })
            .catch(function (err) {
            console.warn(err);
            window.alert("Your browser doesn't support native microphone recording. For best results, we recommend using latest version of Google Chrome or Mozilla Firefox to use this site.");
        });
        return function () {
            var _a, _b, _c;
            if (((_a = mediaRecorderRef.current) === null || _a === void 0 ? void 0 : _a.state) !== 'inactive') {
                (_b = mediaRecorderRef.current) === null || _b === void 0 ? void 0 : _b.stop();
            }
            (_c = streamRef.current) === null || _c === void 0 ? void 0 : _c.getTracks().forEach(function (track) { return track.stop(); });
        };
    }, []);
    (0, react_1.useEffect)(function () {
        var recorder = mediaRecorderRef.current;
        if (!recorder)
            return;
        if (command === 'start') {
            if (recorder.state !== 'inactive') {
                recorder.stop();
                // Wait for the recorder to actually stop before starting again
                recorder.addEventListener('stop', function () {
                    if (recorder.state === 'inactive') {
                        recorder.start();
                    }
                }, { once: true });
            }
            else {
                recorder.start();
            }
        }
        else if (command === 'stop' && recorder.state !== 'inactive') {
            recorder.stop();
        }
        return function () {
            if (recorder.state !== 'inactive') {
                recorder.stop();
            }
        };
    }, [command]);
    return null;
};
exports.default = Recorder;
