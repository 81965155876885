import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import MediaQuery from 'react-responsive';
import RouterLink from 'react-router-dom/Link';
import { Box, Flex, Link, Text } from 'rebass';
import Dropdown from 'src/components/elements/Dropdown';
import Hamburger from 'src/components/elements/Hamburger';
import { Container } from 'src/components/utilities/Containers';
import { breakpoints, theme } from 'src/styles/theme';
import { hasTeacherRole } from 'src/utils/roles';
import styled from 'styled-components';
import { AccountIcon, LogoIcon, SearchIcon, TrophyIcon } from './Icons';
import Searchbar from './Searchbar';
import SearchbarMobile from './SearchbarMobile';

const linkStyle = {
  fontSize: '1.2rem',
  color: 'gray500',
  '&:hover:': {
    color: 'secondary',
  },
};

const menuLinkStyle = {
  color: 'text',
  fontSize: '1.4rem',
  fontWeight: 'semibold',
  css: {
    position: 'relative',
    textDecoration: 'none',
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: '-5px',
      left: 0,
      display: 'block',
      height: '2px',
      backgroundColor: theme.colors.secondary,
    },
  },
};

const HeaderStyled = styled(Box)`
  position: relative;
  height: 4rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(234, 234, 234, 0.5);

  @media (min-width: ${breakpoints.md}) {
    height: 7rem;
  }
`;

class Header extends Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    user: PropTypes.object,
    onMenuButtonClick: PropTypes.func,
    open: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  state = {
    searchMobileIsOpen: false,
  };

  toggleSearchbarMobile = () => {
    this.setState({ searchMobileIsOpen: !this.state.searchMobileIsOpen });
  };

  render() {
    const { searchMobileIsOpen } = this.state;
    const { t, authenticated, user, onMenuButtonClick, open } = this.props;
    const isTeacher = hasTeacherRole(user);

    return (
      <Box as="header" color="black" lineHeight="1" className="screen" id="banner">
        {authenticated ? (
          <HeaderStyled>
            {searchMobileIsOpen && (
              <MediaQuery query={`(max-width: ${breakpoints.mdPlus})`}>
                <SearchbarMobile toggleSearchbarMobile={this.toggleSearchbarMobile} />
              </MediaQuery>
            )}
            <Container css="height: 100%; padding-top: 2rem;">
              <Flex mx={-2} alignItems="center" justifyContent="space-between" css="height: 100%;">
                <Flex px={2} alignItems="center" css="flex: 0 0 auto;">
                  <Hamburger onClick={onMenuButtonClick} open={open} />
                  <RouterLink to="/">
                    <LogoIcon />
                  </RouterLink>
                </Flex>
                <MediaQuery query={`(min-width: ${breakpoints.lg})`}>
                  <Flex px={2} justifyContent="center" css="flex: 1 1 auto;">
                    <Searchbar />
                  </Flex>
                </MediaQuery>
                <Flex pl={2} justifyContent="flex-end" css="flex: 0 0 auto;">
                  <Flex>
                    {!isTeacher &&
                      user.score !== null &&
                      user.score !== 0 && (
                        <Flex
                          flexDirection={['row', 'row', 'column']}
                          alignItems="center"
                          justifyContent="center"
                          pt={[0, 0, '.3rem']}
                          px={[2, 2, 3]}
                          color="primary"
                        >
                          <TrophyIcon />
                          <Text
                            as="span"
                            mt={[0, 0, '.3rem']}
                            ml={['.3rem', '.5rem', 0]}
                            fontSize="1.4rem"
                            fontWeight="bold"
                          >
                            {user.score}
                          </Text>
                        </Flex>
                      )}
                    <MediaQuery query={`(min-width: ${breakpoints.lg})`}>
                      <Box px={[1, 2, 3]} css="flex: 0 0 auto;">
                        <Dropdown
                          label={isTeacher ? t('myAccount') : user.firstname}
                          avatar={user.avatar}
                          icon={<AccountIcon />}
                        >
                          <Dropdown.Item to="/mes-parametres" label={t('settings')} icon={<Dropdown.SettingsIcon />} />
                          {isTeacher ? (
                            <Dropdown.Item
                              to="/mes-statistiques"
                              css="padding-top: .4rem;"
                              label={t('statistics')}
                              icon={<Dropdown.BadgeIcon />}
                            />
                          ) : (
                            <Dropdown.Item
                              to="/mes-recompenses"
                              css="padding-top: .4rem;"
                              label={t('awards')}
                              icon={<Dropdown.TrophyIcon />}
                            />
                          )}
                          <Dropdown.Item
                            to="/mes-contacts"
                            css="padding-top: .4rem;"
                            label={t('addressBook')}
                            icon={<Dropdown.AddressBookIcon />}
                          />
                          <MediaQuery maxWidth={breakpoints.lgPlus}>
                            <Dropdown.Item
                              css="padding-top: .4rem;"
                              href={`${process.env.BASENAME}logout`}
                              label={t('logout')}
                              icon={<Dropdown.LogoutIcon />}
                            />
                          </MediaQuery>
                        </Dropdown>
                      </Box>
                    </MediaQuery>
                    <MediaQuery minWidth={breakpoints.xl}>
                      <Flex alignItems="center" px={[2, 2, 3]}>
                        <Text as="a" {...menuLinkStyle} href={`${process.env.BASENAME}logout`}>
                          {t('logout')}
                        </Text>
                      </Flex>
                    </MediaQuery>
                    <MediaQuery query={`(max-width: ${breakpoints.mdPlus})`}>
                      <Text color="text" pl={[0, 1, 3]} pr={[2, 2, 3]}>
                        <Flex
                          as="button"
                          type="button"
                          onClick={this.toggleSearchbarMobile}
                          alignItems="center"
                          justifyContent="center"
                          width="4.4rem"
                          p={0}
                          css="height: 4.4rem; border: none; background-color: transparent; outline: 0;"
                        >
                          <SearchIcon />
                        </Flex>
                      </Text>
                    </MediaQuery>
                  </Flex>
                </Flex>
              </Flex>
            </Container>
          </HeaderStyled>
        ) : (
          <React.Fragment>
            <Box bg="gray100">
              <Container css="height: 100%;">
                <Flex mx={-2} py=".5rem" alignItems="center" justifyContent="space-between" bg="gray100">
                  <Text px={2} fontSize="1.2rem" as="span">
                    {t('header.baseline')}
                  </Text>
                  <Box px={2}>
                    <Link {...linkStyle}>{t('registration')}</Link>|<Link {...linkStyle}>{t('login')}</Link>
                  </Box>
                </Flex>
              </Container>
            </Box>
            <HeaderStyled>
              <Container css="height: 100%;">
                <Flex alignItems="center" justifyContent="center" css="height: 100%;">
                  <LogoIcon />
                </Flex>
              </Container>
            </HeaderStyled>
          </React.Fragment>
        )}
      </Box>
    );
  }
}

export default withNamespaces()(Header);
