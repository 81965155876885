import React from 'react';
import FolderSvg from 'assets/svg/chain.svg';
import DownloadSvg from 'assets/svg/download.svg';
import DuelSvg from 'assets/svg/duel.svg';
import DuplicateSvg from 'assets/svg/duplicate.svg';
import FavoriteSvg from 'assets/svg/favorites.svg';
import MergeSvg from 'assets/svg/merge.svg';
import PrintSvg from 'assets/svg/print.svg';
import ShareSvg from 'assets/svg/share.svg';
import SplitSvg from 'assets/svg/split.svg';
import StatisticSvg from 'assets/svg/statistic.svg';
import GamepadSvg from 'assets/svg/gamepad.svg';
import EditSvg from 'assets/svg/update.svg';
import { svgStyle } from 'src/styles/helpers';
import styled, { css } from 'styled-components';


const ActionIcon = ({ name }) => {

  const icons = {
    duplicate: {
      svg: DuplicateSvg,
      extraCss: ``
    },
    download: {
      svg: DownloadSvg,
      extraCss: ``
    },
    edit: {
      svg: EditSvg,
      extraCss: ``
    },
    statistic: {
      svg: StatisticSvg,
      extraCss: `
        fill: none;
        stroke-width: 1px;
      `
    },
    favorite: {
      svg: FavoriteSvg,
      extraCss: `
        .favorite-icon-full {
          fill: transparent;
        }
      `
    },
    duel: {
      svg: DuelSvg,
      extraCss: ``
    },
    folder: {
      svg: FolderSvg,
      extraCss: ``
    },
    print: {
      svg: PrintSvg,
      extraCss: ``
    },
    split: {
      svg: SplitSvg,
      extraCss: ``
    },
    merge: {
      svg: MergeSvg,
      extraCss: ``
    },
    share: {
      svg: ShareSvg,
      extraCss: ``
    },
    gamepad: {
      svg: GamepadSvg,
      extraCss: `
        width: fit-content;
      `
    }
  };

  if (!icons[name])
    return null;

  //Gamepad is wierd, he doesn't inherit from svgStyle for now
  const style = css`
    ${name !== 'gamepad' ? svgStyle : ""}
    width: 2rem;
    height: 2rem;
    ${icons[name].extraCss}
  `;

  const Icon = styled(icons[name].svg)`
    ${style}
  `

  return (
    <Icon />
  )
}

export default ActionIcon;