import styled from 'styled-components';
import { TabPanel as ReactTabPanel } from 'react-tabs';

const TabPanel = styled(ReactTabPanel)`
  display: ${props => (props.selected ? 'initial' : 'none')};
`;

TabPanel.TabsRole = 'TabPanel';

export default TabPanel;
