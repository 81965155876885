import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import { Box, Text } from 'rebass';
import { Content } from 'src/components/utilities/Containers';
import { Grid, Col } from 'src/components/utilities/Grids';
import NavigationButton from 'src/components/elements/NavigationButton';

const CREATE_FOLDER = 'create-folder';
const ADD_SERIES = 'add-series';

const ButtonLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;

class Navigation extends PureComponent {
  static propTypes = {
    partial: PropTypes.bool,
    folderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentStep: PropTypes.oneOf([CREATE_FOLDER, ADD_SERIES]),
  };

  static defaultProps = {
    partial: false,
    currentStep: CREATE_FOLDER,
  };

  render() {
    const { partial, folderId, currentStep } = this.props;

    return (
      <Box bg="gray100" pt={[2, 2, 4]}>
        <Content css="text-align: center;">
          <Text fontFamily="heading" fontSize={['1.6rem', '1.6rem', '2.6rem']}>
            {folderId && history ? 'Éditer une chaîne' : 'Créer une chaîne'}
          </Text>
          {!partial && (
            <Grid justifyContent="center" pt={[2, 2, 3]}>
              <Col width={[1 / 1, 1 / 1, 1 / 2]} justifyContent={['center', 'center', 'flex-end']} pb={[2, 2, 3]}>
                {folderId ? (
                  <ButtonLink to={`/chaines/${folderId}/editer`}>
                    <NavigationButton
                      current={currentStep === CREATE_FOLDER}
                      valid={currentStep === ADD_SERIES}
                      number="1"
                      label={folderId && history ? 'Éditer la chaîne' : 'Créer une chaîne'}
                    />
                  </ButtonLink>
                ) : (
                  <NavigationButton
                    current={currentStep === CREATE_FOLDER}
                    valid={currentStep === ADD_SERIES}
                    number="1"
                    label={folderId && history ? 'Éditer la chaîne' : 'Créer une chaîne'}
                  />
                )}
              </Col>
              <Col width={[1 / 1, 1 / 1, 1 / 2]} justifyContent={['center', 'center', 'flex-start']} pb={[2, 2, 3]}>
                {folderId ? (
                  <ButtonLink to={`/chaines/${folderId}/editer-series`}>
                    <NavigationButton current={currentStep === ADD_SERIES} number="2" label="Ajouter des séries" />
                  </ButtonLink>
                ) : (
                  <NavigationButton current={currentStep === ADD_SERIES} number="2" label="Ajouter des séries" />
                )}
              </Col>
            </Grid>
          )}
        </Content>
      </Box>
    );
  }
}

Navigation.CREATE_FOLDER = CREATE_FOLDER;
Navigation.ADD_SERIES = ADD_SERIES;

export default Navigation;
