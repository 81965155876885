import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import FolderList from 'src/components/blocks/FolderList';
import ListLayout from 'src/layouts/List';
import Select from 'src/components/elements/Select';

class FolderRecommendations extends Component {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    handleChange: PropTypes.func,
    folders: PropTypes.object,
    match: PropTypes.object,
    orderBy: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, handleChange, folders, match, orderBy, user } = this.props;

    return (
      <ListLayout
        icon={<ListLayout.CollectionIcon />}
        title={`${t('chainRecommendations')} ${match.params.schoolSubject} - ${user.schoolDegree.name}`}
        sort={
          <Select
            name="sort"
            onChange={handleChange}
            value={orderBy}
            options={[
              { id: 'newest', name: 'Plus récents' },
              { id: 'oldest', name: 'Plus anciens' },
              { id: 'alphabetical', name: 'De A à Z' },
              { id: 'reverseAlphabetical', name: 'De Z à A' },
            ]}
          />
        }
      >
        {folders && folders.results.length > 0 ? <FolderList data={folders.results} /> : <p>{t('noResult')}</p>}
      </ListLayout>
    );
  }
}

export default withNamespaces()(FolderRecommendations);
