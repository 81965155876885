import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Pagination from 'src/components/blocks/Pagination';
import { COLLECTIONS_ORDER_BY, GET_COLLECTIONS } from 'src/queries/collections';
import Content from './Content';

class FavoritesContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    user: PropTypes.object,
  };

  isContentLoaded = false;
  state = {
    orderBy: 'newest',
    page: 1,
  };

  handleOrderChange = event => {
    this.setState({
      orderBy: event.target.value,
    });
  };

  onPageChange = page => {
    this.setState({
      page,
    });
  };

  render() {
    const { user } = this.props;
    const { page, orderBy } = this.state;

    return (
      <Query
        query={GET_COLLECTIONS}
        fetchPolicy={'network-only'}
        variables={{
          page,
          limit: parseInt(Pagination.limit),
          filterBy: [
            { join: 'linkUsers', property: 'user', where: user.id },
            { join: 'linkUsers', property: 'isFavorite', where: 'true' },
          ],
          orderBy: [COLLECTIONS_ORDER_BY[orderBy]],
        }}
      >
        {({ data, error, loading }) => {
          if ((loading && !this.isContentLoaded) || !data) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          return (
            <Content
              handleOrderChange={this.handleOrderChange}
              onPageChange={this.onPageChange}
              orderBy={orderBy}
              page={page}
              collections={data.collections.results}
              count={data.collections.count}
            />
          );
        }}
      </Query>
    );
  }
}

export default FavoritesContainer;
