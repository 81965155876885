import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { LoadingContext } from 'src/context';
import { withNamespaces } from 'react-i18next';
import groupBy from 'lodash.groupby';

import { Box } from 'rebass';
import { Container, Content } from 'src/components/utilities/Containers';
import OfficialCollections from 'src/components/blocks/OfficialCollections';
import OfficialFolders from 'src/components/blocks/OfficialFolders';
import Tabs from 'src/components/blocks/Tabs';
import Title from 'src/components/typography/Title';

import { breakpoints } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import RecommendationSvg from 'assets/svg/recommandations.svg';

const RecommendationIcon = styled(RecommendationSvg)`
  ${svgStyle};
  height: 2rem;

  @media (min-width: ${breakpoints.md}) {
    min-height: 2.6rem;
  }
`;

const tabStyle = css`
  flex: 1;
  @media (min-width: ${breakpoints.md}) {
    min-width: 21.4rem;
  }
`;

class Recommendations extends PureComponent {
  static contextType = LoadingContext;
  static propTypes = {
    t: PropTypes.func,
    dataCollections: PropTypes.object,
    dataFolders: PropTypes.object,
  };

  componentDidMount() {
    this.context.removeLoading();
  }

  render() {
    const { t, dataCollections, dataFolders } = this.props;

    return (
      <Container pb={3}>
        <Content mt={3}>
          <Title as="h1" icon={<RecommendationIcon />} medium>
            {t('myRecommendations')}
          </Title>
          <Tabs forceRenderTabPanel={true}>
            <Box pt={2}>
              <Tabs.TabList>
                <Tabs.Tab css={tabStyle}>Séries</Tabs.Tab>
                <Tabs.Tab css={tabStyle}>Chaînes</Tabs.Tab>
              </Tabs.TabList>
            </Box>
            <Tabs.TabPanel>
              {dataCollections.officialCollections && dataCollections.officialCollections.length > 0 ? (
                <OfficialCollections
                  collections={groupBy(dataCollections.officialCollections, obj => obj.schoolSubject.id)}
                />
              ) : (
                <p>{t('noResult')}</p>
              )}
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              {dataFolders.officialFolders && dataFolders.officialFolders.length > 0 ? (
                <OfficialFolders folders={groupBy(dataFolders.officialFolders, obj => obj.schoolSubject.id)} />
              ) : (
                <p>{t('noResult')}</p>
              )}
            </Tabs.TabPanel>
          </Tabs>
        </Content>
      </Container>
    );
  }
}

export default withNamespaces()(Recommendations);
