"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useSyncedState(initialValue) {
    var _a = (0, react_1.useState)(initialValue), state = _a[0], setState = _a[1];
    var stateRef = (0, react_1.useRef)(initialValue);
    var setSyncedState = (0, react_1.useCallback)(function (newValue) {
        if (typeof newValue === 'function') {
            var updatedValue = newValue(stateRef.current);
            stateRef.current = updatedValue;
            setState(updatedValue);
        }
        else {
            stateRef.current = newValue;
            setState(newValue);
        }
    }, []);
    return [state, setSyncedState, stateRef];
}
exports.default = useSyncedState;
