import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import LinkRouter from 'react-router-dom/Link';
import { Box, Text } from 'rebass';
import { Grid, Col } from 'src/components/utilities/Grids';
import { breakpoints, theme } from 'src/styles/theme';
import { svgStyle } from 'src/styles/helpers';
import TriangleSvg from 'assets/svg/triangle.svg';

const TriangleIcon = styled(TriangleSvg)`
  ${svgStyle};
  width: 1rem;
  height: 0.8rem;
  margin-top: 0.1rem;
  margin-right: 0.8rem;
  transform: rotate(180deg);
`;

const Link = styled(LinkRouter)`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 1.8rem;
  padding-left: 1.8rem;
  color: ${theme.colors.white};
  font-size: 1.4rem;
  text-decoration: none;
  transition: background-color 225ms ease;

  &:hover {
    background-color: ${theme.colors.secondaryDark};
  }
`;

class Preview extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    collectionId: PropTypes.string.isRequired,
  };

  render() {
    const { t, collectionId } = this.props;

    return (
      <Box bg="secondary" color="white" className="screen">
        <Grid width={1 / 1} css="min-height: 52px;">
          <Col flex="1" alignItems="center">
            <Link to={`/series/${collectionId}/editer`}>
              <TriangleIcon />
              <MediaQuery minWidth={breakpoints.md}>{t('backToEdit')}</MediaQuery>
            </Link>
          </Col>
          <Col width={[1 / 3]} alignItems="center" justifyContent="center">
            <Text as="span" fontFamily="heading" fontSize={['1.6rem', '1.6rem', '2.2rem']} fontWeight="semibold">
              {t('preview')}
            </Text>
          </Col>
          <Col flex="1" />
        </Grid>
      </Box>
    );
  }
}

export default withNamespaces()(Preview);
