import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Redirect } from 'react-router-dom';
import { GET_COLLECTION, SET_COLLECTION_SEEN } from 'src/queries/collections';
import Content from './Content';

const withSeenMutation = graphql(SET_COLLECTION_SEEN, {
  options: ({ match }) => ({
    variables: {
      collection: match.params.id,
    },
  }),
});

@withSeenMutation
class CollectionContainer extends PureComponent {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    mutate: PropTypes.func,
    user: PropTypes.object,
  };

  isContentLoaded = false;

  componentDidMount() {
    this.props.mutate();
  }

  render() {
    const { match, history, user } = this.props;

    return (
      <Query
        query={GET_COLLECTION}
        variables={{ id: match.params.id, withUserLinkCard: true }}
        returnPartialData={true}
      >
        {({ data, error, loading }) => {
          if (loading && !this.isContentLoaded) return <div />;
          if (error) return <p>Error :(</p>;
          this.isContentLoaded = true;

          if (!data.collection) {
            return <Redirect to="/" />; // TODO Add a 404 page
          }

          return <Content match={match} history={history} collection={data.collection} user={user} />;
        }}
      </Query>
    );
  }
}

export default CollectionContainer;
